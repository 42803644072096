import { ChangeEvent } from "react";

import { Field, Fields, Input, Select, VALIDATION_MESSAGES } from "@m/ui";
import { toDollars } from "@m/utils";

import {
  ENGAGEMENTS_BUDGET_STATUS,
  ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME,
  REGEX_PATTERNS,
} from "../constants";
import { IBudgetStatus } from "../types";
import { getBudgetRemaining, getTotalBudget } from "../utils";

interface BudgetStatusFormProps {
  budgetStatus: IBudgetStatus | undefined;
  onUpdate: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const BudgetStatusForm = ({
  budgetStatus,
  onUpdate,
}: BudgetStatusFormProps) => {
  const {
    status,
    originalSof,
    changeOrders,
    totalCreditsApplied,
    budgetSpent,
    estimateAtCompletion,
  } = budgetStatus;

  const totalBudget = getTotalBudget(budgetStatus);
  const budgetRemaining = getBudgetRemaining(totalBudget, budgetStatus);

  return (
    <Fields>
      <Field label="Status" htmlFor="budgetStatus" row="1/3">
        <Select
          id="budgetStatus"
          name="status"
          value={status}
          onChange={onUpdate}
          options={Object.values(ENGAGEMENTS_BUDGET_STATUS).map((option) => {
            return (
              <option key={option} value={option}>
                {ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME[option]}
              </option>
            );
          })}
        />
      </Field>
      <Field label="Original Sof" htmlFor="originalSof" row="1/3">
        <Input
          className="text-right"
          leftAddon={<>&#36;</>}
          id="originalSof"
          name="originalSof"
          onChange={onUpdate}
          pattern={REGEX_PATTERNS.CURRENCY}
          required={true}
          type="number"
          title={VALIDATION_MESSAGES.INVALID_CURRENCY_FORMAT}
          value={originalSof}
        />
      </Field>
      <Field label="Change Orders" htmlFor="changeOrders" row="1/3">
        <Input
          className="text-right"
          leftAddon={<>&#36;</>}
          id="changeOrders"
          name="changeOrders"
          onChange={onUpdate}
          pattern={REGEX_PATTERNS.CURRENCY}
          required={true}
          type="number"
          title={VALIDATION_MESSAGES.INVALID_CURRENCY_FORMAT}
          value={changeOrders}
        />
      </Field>
      <div className="flex flex-row justify-between">
        <div className="text-sm font-semibold">Total Budget</div>
        <div className="p-1">{toDollars(totalBudget)}</div>
      </div>
      <Field
        label="Total Credits Applied"
        htmlFor="totalCreditsApplied"
        row="1/3"
      >
        <Input
          className="text-right"
          leftAddon={<>&#36;</>}
          id="totalCreditsApplied"
          name="totalCreditsApplied"
          onChange={onUpdate}
          pattern={REGEX_PATTERNS.CURRENCY}
          required={true}
          type="number"
          title={VALIDATION_MESSAGES.INVALID_CURRENCY_FORMAT}
          value={totalCreditsApplied}
        />
      </Field>
      <Field label="Budget Spent" htmlFor="budgetSpent" row="1/3">
        <Input
          className="text-right"
          leftAddon={<>&#36;</>}
          id="budgetSpent"
          name="budgetSpent"
          onChange={onUpdate}
          pattern={REGEX_PATTERNS.CURRENCY}
          required={true}
          type="number"
          title={VALIDATION_MESSAGES.INVALID_CURRENCY_FORMAT}
          value={budgetSpent}
        />
      </Field>
      <div className="flex flex-row justify-between">
        <div className="text-sm font-semibold">Budget Remaining</div>
        <div className="p-1">{toDollars(budgetRemaining)}</div>
      </div>
      <Field
        label="Estimate At Completion"
        htmlFor="estimateAtCompletion"
        row="1/3"
      >
        <Input
          className="text-right"
          leftAddon={<>&#36;</>}
          id="estimateAtCompletion"
          name="estimateAtCompletion"
          onChange={onUpdate}
          pattern={REGEX_PATTERNS.CURRENCY}
          required={true}
          type="number"
          title={VALIDATION_MESSAGES.INVALID_CURRENCY_FORMAT}
          value={estimateAtCompletion}
        />
      </Field>
    </Fields>
  );
};
