import { RouteObject } from "react-router-dom";

import {
  ConnectAwsPayerAccountPage,
  IdentifyAwsPayerAccountPage,
  OnboardingWelcomePage,
} from "@mc/features/Onboarding";

import { ConnectAwsPayerOutlet, IdentifyAwsPayerOutlet } from "./outlets";
import { CloudGatewayOnboardingPathRedirect } from "./redirects";

export const onboardingCloudGatewayRoutes: RouteObject[] = [
  {
    path: "start",
    element: <OnboardingWelcomePage />,
  },
  {
    path: "identify-payer",
    element: <IdentifyAwsPayerOutlet />,
    children: [{ path: "*", element: <IdentifyAwsPayerAccountPage /> }],
  },
  {
    path: "connect-payer",
    element: <ConnectAwsPayerOutlet />,
    children: [{ path: "*", element: <ConnectAwsPayerAccountPage /> }],
  },
  {
    path: "*",
    element: <CloudGatewayOnboardingPathRedirect />,
  },
];
