import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import {
  Badge,
  Button,
  CommentCount,
  Link,
  Search,
  SortDropdown,
  SpinnerScreen,
  Table,
  Timestamp,
  toast,
} from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { CaseListError as ListError } from "@mc/features/CustomerCases/components";
import { CASE_LIST_STATUS as REQUEST_STATE } from "@mc/features/CustomerCases/constants";
import { CaseSubject as RequestSubject } from "@mc/features/CustomerCases/pages";
import { useUserPreference } from "@mc/hooks";
import { useNavigate } from "@mc/router";

import { useDevOpsDeskList } from "../api";
import { DevOpsDeskEmpty } from "../components";

export const DevOpsDeskListPage = () => {
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    sort: StringParam,
  });

  const {
    data: { requests },
    error,
    loading,
    pagination,
  } = useDevOpsDeskList(query);

  if (error) {
    return <ListError />;
  }

  if (loading) {
    return <SpinnerScreen fitToParent={true} />;
  }

  const shouldShowEmptyState =
    // Empty state should not be shown if search query returns 0 results
    requests.length === 0 && !query.search;

  return (
    <Page
      title={
        <PageTitle
          actions={
            <Button
              kind="primary"
              size="small"
              to={PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE}
            >
              New Request
            </Button>
          }
          title="Engineer Assist"
          padding="small"
        />
      }
      width="large"
    >
      {shouldShowEmptyState ? (
        <DevOpsDeskEmpty />
      ) : (
        <DevOpsDeskList
          requests={requests}
          query={query}
          loading={loading}
          pagination={pagination}
          setQuery={setQuery}
        />
      )}
    </Page>
  );
};

const DevOpsDeskList = ({ requests, query, loading, pagination, setQuery }) => {
  const [searchTerm, setSearchTerm] = useState(query.search);

  const navigate = useNavigate();

  const onSortChange = (sort) => {
    pagination.setCurrentPage(1);
    setQuery({
      sort,
    });
  };

  const handleSearchInputChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value === "") setQuery({ search: undefined });

    setSearchTerm(value);
  };

  const handleSubmitSearch = (selectedSearch) => {
    if (selectedSearch.length < 3 && selectedSearch != "") {
      toast.error("Search must be at least 3 characters in length.");
      return;
    }

    setQuery({
      search: selectedSearch ?? undefined,
    });

    setSearchTerm(selectedSearch);
  };

  const devOpsDeskListHeaders = {
    SUBJECT: { label: "Subject", sort: "NUMBER", width: "90%" },
    REPLIES: { label: "Replies", sort: "REPLIES" },
    STATUS: { label: "Status", sort: "status" },
    OPENED: {
      label: "Opened",
      sort: "OPENED_AT",
      align: "right",
    },
  };

  const tableHeaders = Object.keys(devOpsDeskListHeaders).map((accessor) => {
    const { label, sort, width, align } = devOpsDeskListHeaders[accessor];

    return {
      label,
      accessor,
      sort,
      align,
      width,
    };
  });
  const rows = useMemo(
    () =>
      requests.map((requestDetails) => {
        const {
          state,
          source,
          sysId,
          shortDescription,
          openedAt,
          number,
          openedBy,
          totalReplies,
        } = requestDetails;

        const requestDetailsPath = generatePath(
          PATHS.ENGAGEMENTS_ENGINEER_ASSIST_DETAILS,
          {
            sysId,
          }
        );

        const handleRowClick = () =>
          navigate(requestDetailsPath, { replace: true });

        return {
          SUBJECT: (
            <RequestSubject
              state={state}
              shortDescription={shortDescription}
              number={number}
              openedBy={openedBy}
              source={source}
            />
          ),
          REPLIES: <CommentCount commentCount={totalReplies || 0} />,
          STATUS: <RequestStatusBadge state={state} />,
          OPENED: (
            <Timestamp isoTime={openedAt} longFormat={true} titleCase={true} />
          ),
          onClick: handleRowClick,
        };
      }),
    [requests, navigate]
  );

  return (
    <div id="engineer-assist-list">
      <DevOpsDeskDetailsBanner />
      <div className="mb-2 flex items-center justify-between p-1">
        <div className="flex gap-4">
          <Search
            searchTerm={searchTerm}
            handleSearchInputChange={handleSearchInputChange}
            handleSubmitSearch={handleSubmitSearch}
          />
        </div>
        <SortDropdown sort={query?.sort} onSortChange={onSortChange} />
      </div>

      <Table
        onSortChange={onSortChange}
        ariaLabel={"Engineer Assist Request Table"}
        defaultSort={query?.sort || "OPENED_AT_DESC"}
        headers={tableHeaders}
        showHeader={false}
        loading={loading}
        rows={rows}
        wrapRowText={true}
        {...pagination}
      />
    </div>
  );
};

const DevOpsDeskDetailsBanner = () => {
  const [showEngineerAssistBanner, setShowEngineerAssistBanner] =
    useUserPreference<boolean>("showEngineerAssistBanner", true);

  const handleOnClose = () => {
    setShowEngineerAssistBanner(false);
  };

  if (!showEngineerAssistBanner) return null;

  return (
    <div className="mb-3 flex w-full flex-col gap-0.5 rounded-md bg-gray-50 p-3">
      <div className="flex items-center justify-between">
        <span className="font-semibold">Engineer Assist</span>
        <button
          onClick={handleOnClose}
          className="text-subdued"
          aria-label="Close Engineer Assist Details Banner"
        >
          <XMarkIcon className="h-2.5 w-2.5" />
        </button>
      </div>
      <span className="text-lg font-semibold">
        Your on-demand team for common everyday tasks in your AWS environment
      </span>
      <p className="py-1 text-subdued">
        Simply describe what you need help with and we’ll coordinate, estimate,
        and handle it with our AWS-certified engineers. Reach out to your
        Customer Success Manager or Account Executive for pricing.
      </p>
      <Link
        href={"https://www.missioncloud.com/engineer-assist"}
        target={"_blank"}
        rightIcon={ArrowRightIcon}
      >
        More details
      </Link>
    </div>
  );
};

const RequestStatusBadge = ({ state }: { state: string }) => {
  let statusText;

  switch (state) {
    case REQUEST_STATE.OPEN:
    case REQUEST_STATE.NEW:
    case REQUEST_STATE.IN_PROGRESS:
    case REQUEST_STATE.AWAITING_INFO:
      statusText = "Open";
      break;
    case REQUEST_STATE.RESOLVED:
    case REQUEST_STATE.CLOSED:
    case REQUEST_STATE.CANCELLED:
      statusText = "Closed";
      break;
    default:
      break;
  }

  return (
    <Badge
      label={statusText}
      status={statusText === "Open" ? "active" : "default"}
    />
  );
};
