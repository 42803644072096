import PT from "prop-types";

import { useScorecardTrendWeekly } from "../api";
import {
  CLOUD_SCORE_MESSAGES,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { useLoadScoreChange } from "../hooks";
import { getWeeklyTrendActivities, getWeeklyTrendLines } from "../utils";

import { ScorecardTrendGraphBase } from "./ScorecardTrendGraphBase";

const { fullText, intervalCount } = SCORECARD_TREND_TIME_RANGES.THREE_MONTHS;

export const ScorecardTrendGraph3Months = ({ pillarId }) => {
  const {
    data: { averageWeeklyScoreResults },
    loading,
  } = useScorecardTrendWeekly({ weeks: intervalCount });

  useLoadScoreChange({
    scoreResults: averageWeeklyScoreResults,
    pillarId,
    scoreKey: "averageScore",
  });

  const { activeTrendLine, allTrendLines } = getWeeklyTrendLines(
    averageWeeklyScoreResults,
    pillarId
  );

  const activities = getWeeklyTrendActivities(
    averageWeeklyScoreResults,
    pillarId
  );

  return (
    <ScorecardTrendGraphBase
      activeTrendLine={activeTrendLine}
      activities={activities}
      allTrendLines={allTrendLines}
      ariaLabel={`${fullText} Cloud Score Trend Graph`}
      axisBottom={{
        format: "%b %d",
        tickValues: "every 2 weeks",
      }}
      emptyMessage={CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_WEEKLY_EMPTY}
      loading={loading}
      xScale={{
        type: "time",
        format: "%Y-W%V-%d",
        useUTC: false,
      }}
    />
  );
};

ScorecardTrendGraph3Months.propTypes = {
  pillarId: PT.string.isRequired,
};
