import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SpinnerScreen } from "@m/ui";

import {
  MARKETPLACE_SIGNUP_ERRORS,
  MarketplaceSignupInput,
  useMarketplaceSignup,
  useValidateProductInvitation,
  useValidateRegistrationToken,
} from "../api";
import {
  AccountCreationError,
  CompanyAlreadyExistsError,
  CreateAccountForm,
  CreateAccountFormElements,
  CreateTeamForm,
  CreateTeamFormElements,
  PreparingAccount,
  SubscriptionDoesNotExistError,
} from "../components";

export const MarketplaceSignupPage = () => {
  const { token } = useParams<{ token: string }>();

  const [userAccountDetails, setUserAccountDetails] =
    useState<UserAccountDetails>(undefined);

  const {
    data: tokenValidationResponse,
    loading: tokenValidationLoading,
    error: tokenValidationError,
  } = useValidateRegistrationToken(token);

  const [
    validateProductInvitation,
    {
      data: invitationValidationResponse,
      loading: invitationValidationLoading,
      error: invitationValidationError,
    },
  ] = useValidateProductInvitation(token);

  const [
    signup,
    {
      data: signupResponse,
      loading: signupLoading,
      error: signupError,
      called: signupCalled,
    },
  ] = useMarketplaceSignup(token);

  const signupExistingCompany = useCallback(
    (invitationId: string) => signup({ ...userAccountDetails, invitationId }),
    [signup, userAccountDetails]
  );

  const signupNewCompany = useCallback(
    (companyName: string) => signup({ ...userAccountDetails, companyName }),
    [signup, userAccountDetails]
  );

  const handleClickCreateAccount = (elements: CreateAccountFormElements) => {
    const { firstNameInput, lastNameInput, workEmailInput } = elements;
    const emailAddress = workEmailInput.value;

    validateProductInvitation({
      emailAddress,
    });
    setUserAccountDetails({
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
      emailAddress,
    });
  };

  const handleClickCreateTeam = (elements: CreateTeamFormElements) => {
    const companyName = elements.companyNameInput.value;
    signupNewCompany(companyName);
  };

  const redirectToAuth0 = () => {
    const auth0Url = signupResponse?.auth0Url;
    if (auth0Url) window.open(auth0Url);
  };

  const handleCompletedSignup = () => {
    redirectToAuth0();
  };

  useEffect(
    function handleValidatedProductInvitation() {
      const invitationId = invitationValidationResponse?.invitation?.id;
      if (invitationId) signupExistingCompany(invitationId);
    },
    [invitationValidationResponse, signupExistingCompany]
  );

  const domainInUseError =
    signupError === MARKETPLACE_SIGNUP_ERRORS.DOMAIN_IN_USE;
  const accountCreationError =
    invitationValidationError ||
    signupError === MARKETPLACE_SIGNUP_ERRORS.SIGNUP_ERROR;
  const error =
    domainInUseError || accountCreationError || tokenValidationError;

  const isTokenValid = tokenValidationResponse?.success;
  const isInvitationValidationComplete = invitationValidationResponse?.success;
  const isInvitationValid = invitationValidationResponse?.valid;

  const showCreateAccountForm =
    isTokenValid && !isInvitationValidationComplete && !error;
  const showCreateTeamForm =
    isInvitationValidationComplete &&
    !isInvitationValid &&
    !signupCalled &&
    !error;
  const showSignupLoading = signupCalled && !error;

  return (
    <div data-testid="signup-page">
      {tokenValidationLoading && <SpinnerScreen />}

      {tokenValidationError && <SubscriptionDoesNotExistError />}
      {domainInUseError && <CompanyAlreadyExistsError />}
      {accountCreationError && <AccountCreationError />}

      {showCreateAccountForm && (
        <CreateAccountForm
          onSubmit={handleClickCreateAccount}
          loading={invitationValidationLoading}
        />
      )}
      {showCreateTeamForm && (
        <CreateTeamForm onSubmit={handleClickCreateTeam} />
      )}
      {showSignupLoading && (
        <PreparingAccount
          loading={signupLoading}
          onCompleted={handleCompletedSignup}
        />
      )}
    </div>
  );
};

type UserAccountDetails = Pick<
  MarketplaceSignupInput,
  "firstName" | "lastName" | "emailAddress"
>;
