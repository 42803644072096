import { ErrorPage } from "@mc/pages";

export const SubscriptionDoesNotExistError = () => (
  <ErrorPage
    title="Subscription doesn't exist"
    content={
      <div>
        <div>Your product registration cannot be completed.</div>
        <div>
          Please check your AWS Marketplace to reconfirm your information.
        </div>
      </div>
    }
  />
);
