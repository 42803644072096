import { useAuth } from "@m/login";
import { Button, Tooltip } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";

export const InviteButton = ({ onClick, disabled }) => {
  const { user: currentUser } = useAuth();
  const isSSO = currentUser?.company?.sso === true;

  const button = (
    <Button
      kind="primary"
      aria-label="Send Email Invite"
      className="h-min"
      onClick={onClick}
      disabled={disabled || isSSO}
    >
      Invite
    </Button>
  );

  return (
    <AccessRequired mutation="sendEmailInvite">
      {isSSO ? (
        <Tooltip
          content="Please invite new users through your SAML-based single sign-on provider."
          side="left"
        >
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </AccessRequired>
  );
};
