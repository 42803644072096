import { SKUS } from "@mc/constants";

import { useMarketplaceRegistrations } from "../providers";
import { findOnboarding } from "../utils";

/** Returns the first pending Cloud Engagements onboarding found. */
export const usePendingCloudEngagementsOnboarding = () => {
  const { pendingOnboardings } = useMarketplaceRegistrations();
  return findOnboarding(pendingOnboardings, SKUS.CLOUD_ENGAGEMENTS);
};
