import { EngagementDetailStatus } from ".";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  EngagementChecklistItem,
  RiskLogItem,
  RiskLogItemOwner,
} from "@m/api/public/types";
import { useAuth } from "@m/login";
import { UserSession } from "@m/types";
import { Avatar, Link, MissionLogo, Table, Tooltip } from "@m/ui";
import { dt, formatFullDate, toHyphenLowercase, toProperCase } from "@m/utils";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, PATHS, ROLES } from "@mc/constants";

import {
  useEngagementMilestoneItems,
  useEngagementsChecklistItems,
  useEngagementsDecisionItems,
  useRiskLogItems,
} from "../api";
import {
  DEFAULT_SORT_ENGAGEMENTS_DECISIONS,
  DEFAULT_SORT_ENGAGEMENTS_MILESTONES,
  DEFAULT_SORT_RISK_LOG,
  DETAIL_ITEM_STATUS,
  DETAIL_ITEM_TYPE,
  TABLE_HEADERS_ENGAGEMENT_CHECKLIST,
  TABLE_HEADERS_ENGAGEMENT_DECISIONS,
  TABLE_HEADERS_ENGAGEMENT_MILESTONES,
  TABLE_HEADERS_RISK_LOG,
} from "../constants";
import {
  EngagementDecisionType,
  EngagementDetailItemType,
  EngagementDetailOwnerType,
  IEngagementMilestoneItem,
} from "../types";
import { getRelativeDate } from "../utils";

import { EngagementDetailLevelIndicator } from "./EngagementDetailLevelIndicator";

export interface EngagementDetailsListsProps {
  engagementId: string;
  shouldShowAllItems: boolean;
}

export const EngagementDetailsLists = ({
  engagementId,
  shouldShowAllItems,
}: EngagementDetailsListsProps) => {
  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });

  const allowDecisions = useAccessRequired({
    feature: FEATURE_FLAGS.ENGAGEMENTS_ALLOW_DECISIONS,
  });

  const {
    data: { milestoneItems },
    loading: milestoneItemsLoading,
  } = useEngagementMilestoneItems(engagementId);

  const {
    data: { checklistItems },
    loading: checklistItemsLoading,
  } = useEngagementsChecklistItems(engagementId);

  const {
    data: { riskLogItems },
    loading: riskLogItemsLoading,
  } = useRiskLogItems(engagementId);

  const {
    data: { decisionItems },
    loading: decisionItemsLoading,
  } = useEngagementsDecisionItems(engagementId);

  return (
    <div className="flex flex-col gap-5">
      <DetailList
        engagementId={engagementId}
        isEngagementManager={isEngagementManager}
        shouldShowAllItems={shouldShowAllItems}
        loading={milestoneItemsLoading}
        detailType={DETAIL_ITEM_TYPE.MILESTONE}
        listItems={milestoneItems}
      />
      <DetailList
        engagementId={engagementId}
        isEngagementManager={isEngagementManager}
        shouldShowAllItems={shouldShowAllItems}
        loading={checklistItemsLoading}
        detailType={DETAIL_ITEM_TYPE.ACTION_ITEM}
        listItems={checklistItems}
      />
      <DetailList
        engagementId={engagementId}
        isEngagementManager={isEngagementManager}
        shouldShowAllItems={shouldShowAllItems}
        loading={riskLogItemsLoading}
        detailType={DETAIL_ITEM_TYPE.RISK}
        listItems={riskLogItems}
      />

      {allowDecisions && (
        <DetailList
          engagementId={engagementId}
          isEngagementManager={isEngagementManager}
          shouldShowAllItems={shouldShowAllItems}
          loading={decisionItemsLoading}
          detailType={DETAIL_ITEM_TYPE.DECISION}
          listItems={decisionItems}
        />
      )}
    </div>
  );
};

const DetailList = ({
  engagementId,
  isEngagementManager,
  shouldShowAllItems,
  loading,
  detailType,
  listItems,
}: {
  engagementId: string;
  isEngagementManager: boolean;
  shouldShowAllItems: boolean;
  loading: boolean;
  detailType: DETAIL_ITEM_TYPE;
  listItems: EngagementDetailItemType[];
}) => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const visibleItems = useMemo(() => {
    return listItems.filter((item: EngagementDetailItemType) => {
      if ("status" in item) return item.status === "OPEN";
      if ("open" in item) return item.open;
      return false;
    });
  }, [listItems]);

  const hiddenItems = useMemo(() => {
    return listItems.filter((item: EngagementDetailItemType) => {
      if ("status" in item) return shouldShowAllItems && item.status !== "OPEN";
      if ("open" in item) return shouldShowAllItems && !item.open;
      return false;
    });
  }, [listItems, shouldShowAllItems]);

  const rows = useMemo(() => {
    const getElementsForRows = ({
      item,
    }: {
      item: EngagementDetailItemType;
    }) => {
      const { title, owner } = item;
      const baseComponents = {
        title: <EngagementDetailTitle title={title} />,
        owner: <EngagementDetailOwner owner={owner} user={user} />,
        details: (
          <ChevronRightIcon
            aria-label={`${toProperCase(detailType)} Actions`}
            className="h-3 w-3"
          />
        ),
      };

      switch (detailType) {
        case DETAIL_ITEM_TYPE.MILESTONE: {
          const {
            id: milestoneId,
            percentageComplete,
            dueDate,
            status,
          } = item as IEngagementMilestoneItem;
          const milestoneDetailsPath = generatePath(
            PATHS._ENGAGEMENT_MILESTONE_DETAILS,
            {
              engagementId,
              milestoneId,
            }
          );

          return {
            ...baseComponents,
            status: (
              <EngagementDetailStatus
                percentageComplete={percentageComplete}
                status={status}
              />
            ),
            dueDate: <EngagementDetailDueDate dueDate={dueDate} />,
            onClick: () => navigate(milestoneDetailsPath),
          };
        }
        case DETAIL_ITEM_TYPE.ACTION_ITEM: {
          const {
            id: actionItemId,
            dueDate,
            open,
          } = item as EngagementChecklistItem;
          const checklistItemDetailsPath = generatePath(
            PATHS._ENGAGEMENT_CHECKLIST_DETAILS,
            {
              engagementId,
              actionItemId,
            }
          );

          return {
            ...baseComponents,
            open: (
              <EngagementDetailStatus
                status={
                  open ? DETAIL_ITEM_STATUS.OPEN : DETAIL_ITEM_STATUS.RESOLVED
                }
              />
            ),
            dueDate: <EngagementDetailDueDate dueDate={dueDate} />,
            onClick: () => navigate(checklistItemDetailsPath),
          };
        }
        case DETAIL_ITEM_TYPE.RISK: {
          const {
            id: riskId,
            status,
            impact,
            probability,
            dateNeeded,
            mitigationStrategy,
          } = item as RiskLogItem;
          const riskDetailsPath = generatePath(
            PATHS._ENGAGEMENT_RISK_LOG_DETAILS,
            {
              engagementId,
              riskId,
            }
          );

          return {
            ...baseComponents,
            status: <EngagementDetailStatus status={status} />,
            riskSummary: (
              <RiskSummary
                title={title}
                mitigationStrategy={mitigationStrategy}
              />
            ),
            probability: (
              <EngagementDetailLevelIndicator
                itemLevelType="Probability"
                level={probability}
              />
            ),
            impact: (
              <EngagementDetailLevelIndicator
                itemLevelType="Impact"
                level={impact}
              />
            ),
            dateNeeded: <EngagementDetailDueDate dueDate={dateNeeded} />,
            onClick: () => navigate(riskDetailsPath),
          };
        }
        case DETAIL_ITEM_TYPE.DECISION: {
          const {
            id: decisionId,
            dueDate,
            status,
            impact,
          } = item as EngagementDecisionType;
          const decisionDetailsPath = generatePath(
            PATHS._ENGAGEMENT_DECISION_DETAILS,
            {
              engagementId,
              decisionId,
            }
          );

          return {
            ...baseComponents,
            status: <EngagementDetailStatus status={status} />,
            impact: (
              <EngagementDetailLevelIndicator
                itemLevelType="Impact"
                level={impact}
              />
            ),
            dueDate: <EngagementDetailDueDate dueDate={dueDate} />,
            onClick: () => navigate(decisionDetailsPath),
          };
        }
        default:
          return {};
      }
    };

    return [...visibleItems, ...hiddenItems].map(
      (item: EngagementDetailItemType) => getElementsForRows({ item })
    );
  }, [visibleItems, hiddenItems, detailType, engagementId, navigate, user]);

  let headers;
  let defaultSort;
  let addButtonPath = "";
  switch (detailType) {
    case DETAIL_ITEM_TYPE.MILESTONE:
      headers = TABLE_HEADERS_ENGAGEMENT_MILESTONES;
      defaultSort = DEFAULT_SORT_ENGAGEMENTS_MILESTONES;
      addButtonPath = PATHS._ENGAGEMENT_MILESTONE_ADD;
      break;
    case DETAIL_ITEM_TYPE.ACTION_ITEM:
      headers = TABLE_HEADERS_ENGAGEMENT_CHECKLIST;
      defaultSort = DEFAULT_SORT_ENGAGEMENTS_MILESTONES;
      addButtonPath = PATHS._ENGAGEMENT_CHECKLIST_ADD;
      break;
    case DETAIL_ITEM_TYPE.RISK:
      headers = TABLE_HEADERS_RISK_LOG;
      defaultSort = DEFAULT_SORT_RISK_LOG;
      addButtonPath = PATHS._ENGAGEMENT_RISK_LOG_ADD;
      break;
    case DETAIL_ITEM_TYPE.DECISION:
      headers = TABLE_HEADERS_ENGAGEMENT_DECISIONS;
      defaultSort = DEFAULT_SORT_ENGAGEMENTS_DECISIONS;
      addButtonPath = PATHS._ENGAGEMENT_DECISION_ADD;
      break;
    default:
      break;
  }

  return (
    <div data-testid={`${toHyphenLowercase(detailType)}-list`}>
      <div className="mb-1 flex items-center justify-between px-2">
        <EngagementSectionHeader title={`${toProperCase(detailType)}s`} />
        {isEngagementManager && (
          <AddNewButton
            path={generatePath(addButtonPath, { engagementId })}
            detailType={detailType}
          />
        )}
      </div>

      {!loading && rows.length === 0 ? (
        <EngagementsEmptyDetailsList detailType={detailType} />
      ) : (
        <div className="border-b">
          <Table
            defaultSort={defaultSort}
            headers={headers}
            loading={loading}
            rows={rows}
            showHeader={false}
            wrapRowText={true}
          />
        </div>
      )}
    </div>
  );
};

const AddNewButton = ({
  path,
  detailType,
}: {
  path: string;
  detailType: DETAIL_ITEM_TYPE;
}) => (
  <Link to={path}>
    <div className="flex flex-row items-center text-sm">
      Add {toProperCase(detailType)}
    </div>
  </Link>
);

const EngagementsEmptyDetailsList = ({
  detailType,
}: {
  detailType: DETAIL_ITEM_TYPE;
}) => {
  return (
    <div className="rounded-md border border-dashed bg-gray-50 px-3 py-2">
      <div className="font-semibold text-default">No {detailType}s</div>
      <div className="text-sm text-subdued">
        Active {detailType}s will appear here when created
      </div>
    </div>
  );
};

const EngagementDetailTitle = ({ title }: { title: string }) => {
  return <div className="text-sm font-semibold text-default">{title}</div>;
};

const EngagementDetailDueDate = ({ dueDate }: { dueDate: string }) => {
  if (!dueDate) {
    return null;
  }

  const formattedDate = formatFullDate(dueDate, "LLL d, yyyy");
  const relativeDueDate = getRelativeDate(dueDate);

  const currentDate = dt.now().startOf("day").toISO();
  const isDueToday = dt.fromISO(dueDate).startOf("day").toISO() === currentDate;
  const isOverdue = !isDueToday && currentDate > dueDate;

  return (
    <Tooltip content={formattedDate} asChild>
      <span
        data-testid="engagement-detail-due-date"
        className={clsx("whitespace-nowrap", { "text-red-500": isOverdue })}
      >
        Due{isOverdue || isDueToday ? " " : " in "}
        <span
          className={clsx("cursor-help border-b-[1px] border-dashed", {
            "border-red-400": isOverdue,
            "border-slate-400": !isOverdue,
          })}
        >
          {relativeDueDate}
        </span>
      </span>
    </Tooltip>
  );
};

export const EngagementDetailOwner = ({
  owner,
  user,
  includeIcon = true,
  includeName,
  className,
}: {
  owner: EngagementDetailOwnerType;
  user: UserSession;
  includeIcon?: boolean;
  includeName?: boolean;
  className?: string;
}): JSX.Element => {
  let display = null;

  if (owner === RiskLogItemOwner.Mission)
    display = (
      <div className="inline-flex items-center gap-1">
        {includeIcon && (
          <MissionLogo className="h-3" color="default" logo="bluemark" />
        )}
        {includeName && <span>Mission Cloud</span>}
      </div>
    );

  if (owner === RiskLogItemOwner.Customer) {
    display = includeIcon ? (
      <Avatar account={user} includeName={includeName} />
    ) : (
      <span>{user.company.name}</span>
    );
  }

  return (
    <div
      data-testid={"engagement-detail-owner"}
      className={clsx("inline-flex flex-row items-center gap-1", className)}
    >
      {display}
    </div>
  );
};

export const EngagementSectionHeader = ({
  title,
  actions,
}: {
  title: string;
  actions?: ReactNode;
}) => {
  return (
    <div className="flex items-center">
      <div className="my-auto flex-shrink-0 text-base font-semibold text-default">
        {title}
      </div>
      <div className="ml-2 flex w-full items-center">{actions}</div>
    </div>
  );
};

const RiskSummary = ({
  title,
  mitigationStrategy,
}: {
  title: string;
  mitigationStrategy: string;
}) => {
  return (
    <div
      data-testid={"engagements-risk-summary"}
      className="text-sm text-default"
    >
      <div className="font-semibold">{title}</div>
      <div className="text-xs font-normal text-subdued">
        {mitigationStrategy}
      </div>
    </div>
  );
};
