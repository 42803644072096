import { Settings } from "luxon";

export * from "@testing-library/react";

export const generateRandomNumber = (min = 0, max = 100000000, decimal = 0) => {
  return parseFloat((Math.random() * (max - min) + min).toFixed(decimal));
};

export const sampleRandomValue = (values) => {
  return values[Math.floor(Math.random() * values.length)];
};

export const mockLocalStorage = (storage) => {
  Object.defineProperty(window, "localStorage", {
    value: {
      getItem: (key) => {
        return storage[key];
      },
      setItem: (key, value) => {
        storage[key] = value;
      },
      removeItem: (key) => {
        storage[key] = null;
      },
    },
  });
};

export const mockNow = (expectedNow) => {
  /*
   *  override the DateTime.Now() function by assigning a new function
   *  Settings.now that returns our DateTime instead of the current time
   */
  Settings.now = () => expectedNow.toMillis();
};
