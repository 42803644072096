import { useNavigate } from "react-router-dom";

import { Drawer } from "@m/ui";

import DateRangePicker from "@mc/components/DateRangePicker";
import { EXAMPLE_ENGAGEMENT_ACTIVITY_STREAM } from "@mc/features/EngagementsActivityFeed/examples";

import { ENGAGEMENT_ACTIVITY_FEED_GROUPS } from "../constants";
import { EngagementActivityStreamNode } from "../types";

import { EngagementActivityFeedGroup } from "./EngagementActivityFeedGroup";

export const EngagementActivityFeedDrawer = () => {
  const navigate = useNavigate();

  const onClose = () => navigate("..");

  const activities = EXAMPLE_ENGAGEMENT_ACTIVITY_STREAM; // todo: wire-up

  return (
    <Drawer onClose={onClose} open={true} title="Activity">
      <div className="mx-3 mt-2 flex flex-col">
        <div className="self-stretch">
          <DateRangePicker before={""} after={""}></DateRangePicker>
        </div>
        <EngagementsActivityFeed activities={activities} />
      </div>
    </Drawer>
  );
};

const EngagementsActivityFeed = ({
  activities,
}: {
  activities: Array<EngagementActivityStreamNode>;
}) => {
  return (
    <>
      {Object.values(ENGAGEMENT_ACTIVITY_FEED_GROUPS).map(({ id, title }) => {
        return (
          <EngagementActivityFeedGroup
            key={id}
            group={id}
            title={title}
            activities={activities}
          />
        );
      })}
    </>
  );
};
