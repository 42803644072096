import { useEffect, useState } from "react";

import { Avatar, Button, Card, Field, Select, Spinner } from "@m/ui";

import { Page } from "@mc/components/Page";
import { MissionAdminPageTitle } from "@mc/features/MissionAdmin";

import { useMembershipOptions, useTeamMembers } from "../api";
import { CompanyTeamSaveButton } from "../components";

const TEAM_MEMBER_GET_FAILURE =
  "Sorry, we encountered a problem fetching the team members for this account.";

export function MyTeamPage() {
  const [team, setTeam] = useState([]);

  const {
    data: { teamMembers },
    loading: teamLoading,
    error: teamError,
  } = useTeamMembers();

  const {
    data: { users, roles },
    loading: v4Loading,
    error: v4Error,
  } = useMembershipOptions();

  useEffect(() => {
    if (teamMembers.length > 0) {
      setTeam(teamMembers);
    }
  }, [teamMembers]);

  const loading = teamLoading || v4Loading;
  const error = teamError || v4Error;

  const existing_ids = teamMembers.map(({ account }) => account.databaseId);
  const potentialMembers = users.filter(
    (account) => !existing_ids.includes(account.databaseId)
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const elements = e.currentTarget.elements;
    if (!elements.employee.value || !elements.role.value) return;

    const account = potentialMembers[elements.employee.value];
    const role = roles[elements.role.value];
    let newTeam = [...team];
    newTeam.push({
      jobRole: {
        name: role,
      },
      account: account,
    });
    setTeam(newTeam);
  };

  const removeMember = (member) => {
    let newTeam = team.filter((item) => item !== member);
    setTeam(newTeam);
  };

  return (
    <Page data-testid="my-team-page" title={<MissionAdminPageTitle />}>
      <Card className="p-3">
        {loading && (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}

        {error && (
          <div aria-label="Team Member List">
            <p>{TEAM_MEMBER_GET_FAILURE}</p>
          </div>
        )}

        {!loading && !error && (
          <>
            <div
              className="grid w-full gap-4 py-4"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              }}
            >
              {!team.length && (
                <div className="col-span-4 m-4 space-y-2 rounded-lg border-2 border-dashed border-gray-200 p-4 pt-5 text-center">
                  This company has no team members.
                </div>
              )}
              {team.map((member, i) => (
                <div key={`company-team-${i}`} className="text-center">
                  <Avatar account={member.account} size="large" />
                  <p className="pt-2 text-lg">{member.account.name}</p>
                  <p>
                    {member.jobRole.name}
                    <br />
                    <br />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        removeMember(member);
                      }}
                    >
                      Remove
                    </Button>
                  </p>
                </div>
              ))}
            </div>
            <form className="mt-2" onSubmit={onSubmit}>
              <Field label="Add a new team member" htmlFor="employee">
                <div className="flex gap-1">
                  <Select
                    name="employee"
                    aria-label="Employee"
                    placeholder="Choose an Employee..."
                    options={potentialMembers.map((account, i) => (
                      <option
                        key={`team-mapping-account-${account.databaseId}`}
                        value={i}
                      >
                        {account.name}
                      </option>
                    ))}
                  />
                  <Select
                    name="role"
                    aria-label="Role"
                    placeholder="Choose a Role..."
                    options={roles.map((role, i) => (
                      <option key={`team-mapping-role-${role}`} value={i}>
                        {role}
                      </option>
                    ))}
                  />
                  <Button type="submit">Add</Button>
                </div>
              </Field>
            </form>
            <CompanyTeamSaveButton team={team} />
          </>
        )}
      </Card>
    </Page>
  );
}
