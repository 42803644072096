import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { GlobalProviders } from "@mc/providers";
import { routes } from "@mc/routes";

export const MissionControlApp = () => {
  const router = createBrowserRouter([
    { element: <GlobalProviders />, children: routes },
  ]);
  return <RouterProvider router={router} />;
};
