import { ArrowUpRightIcon } from "@heroicons/react/20/solid";

import { Link } from "@m/ui";

import { AWS_CONSOLE_SIGN_IN_URL } from "@mc/constants";

interface Props {
  awsAccountId: string;
}

export const AccountConsole = ({ awsAccountId }: Props) => (
  <Link
    className="flex flex-row items-center gap-0.5"
    rel="noopener noreferrer"
    href={AWS_CONSOLE_SIGN_IN_URL(awsAccountId)}
    rightIcon={ArrowUpRightIcon}
    target="_blank"
  >
    AWS Console
  </Link>
);
