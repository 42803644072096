import { EngagementType } from "@m/api/public/types";

class ExampleEngagementType {
  id: EngagementType;
  description: string;
  display: string;

  constructor({ id, description, display }) {
    this.id = id;
    this.description = description;
    this.display = display;
  }
}

const EXAMPLE_ENGAGEMENT_TYPE_ASSESS = new ExampleEngagementType({
  id: EngagementType.Assess,
  description:
    "The migration readiness assessment helps you identify gaps along the six dimensions of the AWS Cloud Adoption Framework.",
  display: "Assess",
});

const EXAMPLE_ENGAGEMENT_TYPE_MOBILIZE = new ExampleEngagementType({
  id: EngagementType.Mobilize,
  description:
    "The mobilize phase helps you build and operational foundation for your migration, with the goal of fixing the capability gaps identified in the assessment phase.",
  display: "Mobilize",
});

const EXAMPLE_ENGAGEMENT_TYPE_OTHER = new ExampleEngagementType({
  id: EngagementType.Other,
  description:
    "You will be able to build your own template that will fit the needs of the project.",
  display: "Other",
});

export const EXAMPLE_ENGAGEMENT_TYPES = [
  EXAMPLE_ENGAGEMENT_TYPE_ASSESS,
  EXAMPLE_ENGAGEMENT_TYPE_MOBILIZE,
  EXAMPLE_ENGAGEMENT_TYPE_OTHER,
];
