import { SpinnerScreen } from "@m/ui";

import { useAuth } from "../contexts";

export const Logout = () => {
  const { logout } = useAuth();

  logout();

  return <SpinnerScreen data-testid="logout" />;
};
