import { EngagementSectionHeader } from "../components/EngagementDetailsLists";

export const EngagementsPoolHoursWidget = () => {
  return (
    <div data-testid="pool-hours-widget" className="w-4/12">
      <EngagementSectionHeader title="Pool Hours" />

      <div className="flex flex-col gap-0.5 rounded-md bg-gray-50 p-2">
        <div className="text-xs font-semibold tracking-wide text-subdued">
          Monthly Hours
        </div>
        {/* TODO(jamesmoody): Wire-up with actual value */}
        <div className="font-semibold text-default">60</div>
      </div>
    </div>
  );
};
