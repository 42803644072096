import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";

import { useAuth } from "@m/login";
import { Button, Tooltip } from "@m/ui";
import { shutdownIntercom, startIntercom } from "@m/utils/intercom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS } from "@mc/constants";

import { MESSAGES } from "../constants";
import { isChatDisabled } from "../utils";

export const LiveChatButton = () => {
  const chatDisabled = isChatDisabled();
  const { user } = useAuth();
  const handleClick = () => {
    if (user?.intercomHash) {
      startIntercom({
        user_id: user.id,
        name: user.name,
        email: user.email,
        user_hash: user.intercomHash,
        company: {
          company_id: user.company.id,
          name: user.company.name,
        },
      });
    } else {
      shutdownIntercom();
    }
  };

  return (
    <AccessRequired feature={FEATURE_FLAGS.MISSION_CONTROL_LIVE_CHAT}>
      <Tooltip
        asChild
        content={MESSAGES.LIVE_CHAT_DISABLED}
        disabled={!chatDisabled}
        side="top"
      >
        <Button
          id="open-live-chat"
          className="h-full whitespace-nowrap"
          kind="primary"
          fill="subdued"
          leftIcon={ChatBubbleLeftRightIcon}
          onClick={handleClick}
          disabled={chatDisabled}
        >
          Live Chat
        </Button>
      </Tooltip>
    </AccessRequired>
  );
};
