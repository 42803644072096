import {
  EngagementDecisionImpact,
  EngagementDecisionOwner,
  EngagementDecisionStatus,
} from "@m/api/public/types";
import { dt } from "@m/utils";

export const EXAMPLE_DECISION_ITEM_OPEN = {
  id: "12345",
  title: "Rate change for Derek",
  description:
    "Derek’s rate will be changed to Senior CE on the project as he’s primarily doing CE work.",
  status: EngagementDecisionStatus.Open,
  owner: EngagementDecisionOwner.Mission,
  impact: EngagementDecisionImpact.Medium,
  dueDate: dt.now().startOf("day").plus({ days: 1 }).toISO(),
  noteList: [],
  latestCompletionNote: null,
  completionNotes: [],
  createdBy: {
    id: "1",
    email: "tom@tom.com",
    name: "Tom Tom",
    firstName: "Tom",
    lastName: "Tom",
  },
  createTime: dt.utc().startOf("day").minus({ day: 2 }).toISO(),
  completedTime: null,
  updateTime: dt.utc().startOf("day").minus({ day: 4 }).toISO(),
};

export const EXAMPLE_DECISION_ITEM_ARCHIVED = {
  id: "12346",
  title: "Archived Example Decision",
  description: "This is the description for an archived example decision.",
  status: EngagementDecisionStatus.Archived,
  owner: EngagementDecisionOwner.Customer,
  impact: EngagementDecisionImpact.Low,
  dueDate: dt.now().startOf("day").plus({ weeks: 1 }).toISO(),
  noteList: [],
  latestCompletionNote: null,
  completionNotes: [],
  createdBy: {
    id: "1",
    email: "tom@tom.com",
    name: "Tom Tom",
    firstName: "Tom",
    lastName: "Tom",
  },
  createTime: dt.utc().startOf("day").minus({ day: 2 }).toISO(),
  completedTime: null,
  updateTime: dt.utc().startOf("day").minus({ day: 4 }).toISO(),
};

export const EXAMPLE_DECISION_ITEM_RESOLVED = {
  id: "12347",
  title: "Completed Example Decision",
  description: "This is the description for a completed example decision.",
  status: EngagementDecisionStatus.Completed,
  owner: EngagementDecisionOwner.Customer,
  impact: EngagementDecisionImpact.High,
  dueDate: dt.utc().startOf("day").minus({ weeks: 2 }).toISO(),
  closedAt: dt.now().minus({ minutes: 2 }).toISO(),
  noteList: [],
  latestCompletionNote: {
    id: "1",
    content: "Shared servers will be in a subnet in new environment",
  },
  completionNotes: [
    {
      id: "1",
      content: "Shared servers will be in a subnet in new environment",
    },
  ],
  createdBy: {
    id: "1",
    email: "tom@tom.com",
    name: "Tom Tom",
    firstName: "Tom",
    lastName: "Tom",
  },
  createTime: dt.utc().startOf("day").minus({ day: 2 }).toISO(),
  completedTime: dt.utc().startOf("day").minus({ day: 4 }).toISO(),
  updateTime: dt.utc().startOf("day").minus({ day: 4 }).toISO(),
};

export const EXAMPLE_ENGAGEMENTS_DECISION_ITEMS = [
  EXAMPLE_DECISION_ITEM_OPEN,
  EXAMPLE_DECISION_ITEM_ARCHIVED,
  EXAMPLE_DECISION_ITEM_RESOLVED,
];
