import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import {
  Account,
  CaseCategory,
  CaseCreatedByEnum,
  CasePriority,
  CaseWaitingOn,
} from "@m/api/public/types";
import {
  AccountAvatar,
  Avatar,
  Badge,
  ButtonRadioGroup,
  CommentCount,
  Search,
  SortDropdown,
  TBadgeStatus,
  Table,
  Timestamp,
  toast,
} from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";

import { useCaseList } from "../api";
import {
  CaseListError,
  CasePriorityBadge,
  CaseWaitingOnBadge,
  NewCaseButton,
} from "../components";
import {
  CASE_LIST_HEADERS,
  CASE_LIST_SOURCE,
  CASE_LIST_SOURCE_NAMES,
  CASE_LIST_STATUS_FILTERS,
  CASE_LIST_STATUS_OPTIONS,
  CASE_LIST_TYPE_FILTERS,
  CASE_LIST_TYPE_OPTIONS,
  CASE_STATUS,
  MESSAGES,
} from "../constants";
import { getCaseType } from "../utils";

type CaseDetailsType = {
  state?: string;
  source?: CaseCreatedByEnum;
  sysId: string;
  shortDescription?: string;
  priority?: CasePriority;
  assignedTo?: Partial<Account>;
  lastActivity?: string;
  number: string;
  openedBy?: Partial<Account>;
  openedAt: string;
  totalReplies?: number;
  category?: CaseCategory;
  waitingOn?: CaseWaitingOn;
};

export const CaseListPage = () => {
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    status: withDefault(StringParam, CASE_LIST_STATUS_FILTERS.open.value),
    type: withDefault(StringParam, CASE_LIST_TYPE_FILTERS.all.value),
    sort: StringParam,
  });

  const [searchTerm, setSearchTerm] = useState(query.search);

  const navigate = useNavigate();

  const {
    data: { cases },
    error,
    loading,
    pagination,
  } = useCaseList(query);

  const onSortChange = (sort) => {
    pagination.setCurrentPage(1);
    setQuery({
      sort,
    });
  };

  const handleSearchInputChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value === "") setQuery({ search: undefined });

    setSearchTerm(value);
  };

  const handleSubmitSearch = (selectedSearch) => {
    if (selectedSearch.length < 3 && selectedSearch != "") {
      toast.error(MESSAGES.INVALID_SEARCH_QUERY_LENGTH);
      return;
    }

    setQuery({
      search: selectedSearch ?? undefined,
    });

    setSearchTerm(selectedSearch);
  };

  const noCasesMessage = "Create a case to get help with your AWS environment";

  const tableHeaders = Object.keys(CASE_LIST_HEADERS).map((accessor) => {
    const { label, sort, width } = CASE_LIST_HEADERS[accessor];

    return {
      label,
      accessor,
      sort,
      width,
    };
  });

  const rows = useMemo(
    () =>
      cases.map((caseDetails: CaseDetailsType) => {
        const {
          state,
          source,
          sysId,
          shortDescription,
          priority,
          assignedTo,
          openedAt,
          number,
          openedBy,
          totalReplies,
          category,
          waitingOn,
        } = caseDetails;

        const caseDetailsPath = generatePath(PATHS.SUPPORT_CASE_DETAILS, {
          sysId,
        });

        const handleRowClick = () =>
          navigate(caseDetailsPath, { replace: true });

        return {
          SUBJECT: (
            <CaseSubject
              state={state}
              shortDescription={shortDescription}
              number={number}
              openedBy={openedBy}
              source={source}
              waitingOn={waitingOn}
            />
          ),
          CATEGORY: <CaseType category={category} />,
          REPLIES: <CommentCount commentCount={totalReplies || 0} />,
          ASSIGNEE: <CaseAssignee assignedTo={assignedTo} />,
          PRIORITY: <CasePriorityBadge priority={priority} size="small" />,
          OPENED: (
            <Timestamp isoTime={openedAt} longFormat={true} titleCase={true} />
          ),
          onClick: handleRowClick,
        };
      }),
    [cases, navigate]
  );

  const statusFilterOptions = CASE_LIST_STATUS_OPTIONS.filter(
    (status) => status.value !== CASE_LIST_STATUS_FILTERS.needResponse.value
  );

  return error ? (
    <CaseListError />
  ) : (
    <Page
      data-testid="case-list-page"
      width="full"
      title={
        <PageTitle
          title="Cases"
          padding="small"
          actions={<NewCaseButton className="!px-1 !py-0.5" />}
        />
      }
    >
      <div id="case-list">
        <div className="mb-2 flex items-center justify-between p-1">
          <div className="flex gap-4">
            <Search
              searchTerm={searchTerm}
              handleSearchInputChange={handleSearchInputChange}
              handleSubmitSearch={handleSubmitSearch}
            />
            <ButtonRadioGroup
              aria-label="Status Filter"
              className="whitespace-nowrap"
              name="cases-status-filter"
              onChange={(value: string) => setQuery({ status: value })}
              options={statusFilterOptions}
              value={query?.status}
              width="fill"
            />

            <ButtonRadioGroup
              aria-label="Type Filter"
              className="whitespace-nowrap"
              name="cases-type-filter"
              onChange={(value: string) => setQuery({ type: value })}
              options={CASE_LIST_TYPE_OPTIONS}
              value={query?.type}
              width="fill"
            />
          </div>
          <SortDropdown sort={query?.sort} onSortChange={onSortChange} />
        </div>

        <Table
          onSortChange={onSortChange}
          ariaLabel={"Cases Table"}
          defaultSort={query?.sort || "OPENED_AT_DESC"}
          emptyMessage={noCasesMessage}
          headers={tableHeaders}
          showHeader={false}
          loading={loading}
          rows={rows}
          wrapRowText={true}
          {...pagination}
        />
      </div>
    </Page>
  );
};

export const CaseSubject = ({
  state,
  shortDescription,
  number,
  openedBy,
  source,
  waitingOn,
}: {
  state: string;
  shortDescription: string;
  number: string;
  openedBy: AccountAvatar;
  source: string;
  waitingOn?: CaseWaitingOn;
}) => {
  const openedByText =
    source !== CASE_LIST_SOURCE.MISSION && source !== CASE_LIST_SOURCE.CUSTOMER
      ? CASE_LIST_SOURCE_NAMES[source]
      : openedBy?.name;

  return (
    <div data-testid="case-subject" className="block gap-2">
      <div className="mb-0.5 flex gap-2">
        <p className="font-semibold text-default">{shortDescription}</p>
        {state === CASE_STATUS.AWAITING_INFO && (
          <CaseWaitingOnBadge waitingOn={waitingOn} />
        )}
      </div>
      <div className="flex gap-2">
        <p className="font-mono">{number}</p>
        <p>{openedByText}</p>
      </div>
    </div>
  );
};

const CaseType = ({ category }: { category: CaseCategory }) => {
  const badgeText = getCaseType(category);
  let badgeStatus: TBadgeStatus;

  switch (badgeText) {
    case "Alert":
      badgeStatus = "warning";
      break;
    case "Engineer Assist":
      badgeStatus = "active";
      break;
    default:
      badgeStatus = "positive";
      break;
  }

  return (
    <div className="grid justify-items-stretch">
      <Badge
        className="justify-self-center"
        data-testid="case-type"
        label={badgeText}
        status={badgeStatus}
        size="small"
      />
    </div>
  );
};

const CaseAssignee = ({ assignedTo }: { assignedTo?: Partial<Account> }) => {
  return (
    <div className="w-[24px] justify-items-stretch">
      <Avatar account={assignedTo} includeName={false} />
    </div>
  );
};
