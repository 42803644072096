import { useAuth } from "@m/login";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { FEATURE_FLAGS } from "@mc/constants";

import {
  CaseMetricsWidget,
  CloudScoreWidget,
  CostSummaryWidget,
  DashboardActivityFeed,
  Greeting,
  MyTeamWidget,
  ProjectSummaryWidget,
} from "../components";

export const DashboardPage = () => {
  const { user } = useAuth();

  return (
    <Page data-testid="dashboard" width="large">
      <Greeting firstName={user.firstName} />
      <div className="flex h-full flex-col gap-3">
        <AccessRequired
          feature={FEATURE_FLAGS.DASHBOARD_COST_SUMMARY_WIDGET_SHOW}
        >
          <CostSummaryWidget />
        </AccessRequired>
        <div className="grid grid-cols-1 space-y-3 lg:grid-cols-3 lg:gap-3 lg:space-y-0">
          <div className="flex flex-col gap-3">
            <AccessRequired feature={FEATURE_FLAGS.SCORECARDS}>
              <CloudScoreWidget />
            </AccessRequired>
            <AccessRequired feature={FEATURE_FLAGS.CASES}>
              <CaseMetricsWidget />
            </AccessRequired>
            <AccessRequired feature={FEATURE_FLAGS.ENGAGEMENTS}>
              <ProjectSummaryWidget />
            </AccessRequired>
            <MyTeamWidget />
          </div>
          <div className="col-span-2">
            <DashboardActivityFeed />
          </div>
        </div>
      </div>
    </Page>
  );
};
