import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

/**
 * Renders child routes, if the user is not logged in,
 * otherwise redirects to root.
 */
export const UnauthenticatedOutlet = () => {
  const { user } = useAuth();
  return user ? <Navigate replace to={PATHS.ROOT} /> : <Outlet />;
};
