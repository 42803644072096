import { FormEventHandler, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
  CaseCategory,
  CasePriority,
  CaseSubcategory,
} from "@m/api/public/types";
import {
  Button,
  Buttons,
  DynamicSelect,
  Field,
  Fields,
  Input,
  Textarea,
} from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { MUTATIONS, PATHS } from "@mc/constants";
import { useAwsAccounts } from "@mc/features/Accounts/api";
import { AccessDenied } from "@mc/pages";

import { useCreateCase } from "../api";
import {
  CaseTypeRadioGroup,
  CategorySelector,
  PriorityRadioGroup,
} from "../components";
import {
  CASE_PRIORITY_DEFINITIONS,
  NEW_CASE_FORM_AWS_REGIONS,
} from "../constants";
import { CaseType } from "../types";
import { createCaseDescription } from "../utils";

export const CreateCasePage = () => {
  const navigate = useNavigate();
  const {
    loading: isAccountsLoading,
    data: { accounts },
  } = useAwsAccounts();

  const accountOptions = useMemo(() => {
    return accounts.map((account) => {
      const { databaseId, name } = account;
      return { value: databaseId, label: name, subLabel: databaseId };
    });
  }, [accounts]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const [selectedRegions, setSelectedRegions] = useState([]);
  const regionOptions = NEW_CASE_FORM_AWS_REGIONS.map((region) => {
    return { value: region, label: region };
  });

  const { caseType = CaseType.Account } = useParams<{ caseType?: CaseType }>();

  const [createCase, { loading }] = useCreateCase();

  const handleSubmit: FormEventHandler<CreateCaseForm> = (e) => {
    e.preventDefault();
    const { elements } = e.currentTarget;
    submitCaseRequest(elements);
  };

  const submitCaseRequest = (elements: CreateCaseFormElements) => {
    const {
      titleInput,
      descriptionInput,
      categorySelect,
      subcategorySelect,
      priorityRadio,
      resourcesInput,
    } = elements;

    const categoryValues = categorySelect?.value?.split(":");

    if (categoryValues) {
      const category = categoryValues[0];
      let subcategory = categoryValues[1];

      if (!subcategory && subcategorySelect?.value) {
        subcategory = subcategorySelect.value;
      }

      const descriptionText = descriptionInput.value;
      const categoryText =
        categorySelect.options?.[categorySelect.selectedIndex].text;
      const subcategoryText =
        subcategorySelect?.options?.[subcategorySelect?.selectedIndex]?.text;
      const priorityText = CASE_PRIORITY_DEFINITIONS[caseType].find(
        ({ value }) => value === priorityRadio.value
      ).name;
      const accountIds = selectedAccounts
        .map((account) => account.value)
        .join(", ");
      const regions = selectedRegions.map((region) => region.value).join(", ");
      const resourcesText = resourcesInput.value;

      const description = createCaseDescription({
        description: descriptionText,
        category: categoryText,
        subcategory: subcategoryText,
        priority: priorityText,
        accountIds,
        regions,
        resourcesText,
        caseType,
      });

      createCase({
        description,
        shortDescription: titleInput.value,
        priority: priorityRadio.value as CasePriority,
        category: category as CaseCategory,
        ...(subcategory && { subcategory: subcategory as CaseSubcategory }),
      });
    }
  };

  const handleChangeCaseType = (value: CaseType) => {
    navigate(
      generatePath(PATHS.SUPPORT_CASES_CREATE_CASETYPE, { caseType: value })
    );
  };

  const handleClickCancel = () => {
    navigate(PATHS.SUPPORT_CASES);
  };

  return (
    <AccessRequired
      mutation={MUTATIONS.CREATE_CASE}
      fallback={<AccessDenied />}
    >
      <Page
        data-testid="create-case-page"
        title={<PageTitle title="New Case" parentTitle="Cases" />}
        width="small"
        className="min-w-[576px]"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-[40px]">
          <Fields>
            <Textarea
              aria-label="Title"
              autoFocus={true}
              autogrow
              containerClasses="text-2xl"
              id="title"
              name="titleInput"
              noStyles
              nextFieldOnReturn={true}
              placeholder="Case title"
              required={true}
              rows={1}
            />
            <Textarea
              aria-label="Description"
              autogrow
              id="description"
              name="descriptionInput"
              noStyles
              placeholder="Add case description including any technologies and AWS services that will be used"
              required={true}
              rows={3}
            />
          </Fields>

          <hr />

          <CaseTypeRadioGroup
            caseType={caseType}
            onChange={handleChangeCaseType}
          />

          <CategorySelector caseType={caseType} />
          <PriorityRadioGroup caseType={caseType} />

          <hr />
          <Field
            htmlFor="accounts"
            label="Accounts"
            row="2/3"
            rowAlign="top"
            className="w-full"
          >
            <DynamicSelect
              id="accounts"
              name="accountsSelect"
              aria-label="Accounts Select"
              placeholder="Add accounts"
              initialOptions={accountOptions}
              selectedOptions={selectedAccounts}
              isMulti={true}
              isLoading={isAccountsLoading}
              onSelectedOptionsChange={setSelectedAccounts}
            />
          </Field>
          <Field label="Regions" row="2/3" rowAlign="top" className="w-full">
            <DynamicSelect
              id="regions"
              name="regionsSelect"
              aria-label="Regions Select"
              placeholder="Add regions"
              isMulti={true}
              isLoading={isAccountsLoading}
              initialOptions={regionOptions}
              selectedOptions={selectedRegions}
              onSelectedOptionsChange={setSelectedRegions}
            />
          </Field>
          <Field
            label="Resources"
            htmlFor="resources"
            row="2/3"
            rowAlign="top"
            className="w-full"
          >
            <Input
              aria-label="Resources"
              className="border-none"
              id="resources"
              name="resourcesInput"
              placeholder="Add resource IDs"
              type="text"
            />
          </Field>

          <Buttons>
            <Button kind="primary" loading={loading} type="submit">
              Create case
            </Button>
            <Button kind="primary" fill="none" onClick={handleClickCancel}>
              Cancel
            </Button>
          </Buttons>
        </form>
      </Page>
    </AccessRequired>
  );
};

export interface CreateCaseFormElements extends HTMLFormControlsCollection {
  titleInput: HTMLInputElement;
  descriptionInput: HTMLInputElement;
  categorySelect?: HTMLSelectElement;
  subcategorySelect?: HTMLSelectElement;
  priorityRadio?: HTMLInputElement;
  resourcesInput: HTMLInputElement;
}

export interface CreateCaseForm extends HTMLFormElement {
  readonly elements: CreateCaseFormElements;
}
