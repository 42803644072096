import { dt } from "@m/utils";

import { generateMockId } from "@mc/testing";

import {
  ENGAGEMENTS_BUDGET_STATUS,
  ENGAGEMENTS_WORK_STATUS,
} from "../constants";
import { IBudgetStatus, IWorkStatus } from "../types";

class ExampleEngagementWorkStatus {
  dueDate: string;
  engagementId: number;
  id: string;
  percentageComplete: number;
  startDate: string;
  status: ENGAGEMENTS_WORK_STATUS;
  completedTime: string;
  updateTime: string;

  constructor({
    dueDate,
    engagementId,
    id,
    percentageComplete,
    startDate,
    status,
    completedTime,
    updateTime,
  }: Partial<IWorkStatus>) {
    const mockTime = dt.utc();

    this.dueDate = dueDate || mockTime.plus({ weeks: 1 }).toISO();
    this.engagementId = engagementId || 0;
    this.id = id || generateMockId();
    this.percentageComplete = percentageComplete || 50;
    this.startDate = startDate || mockTime.minus({ day: 1 }).toISO();
    this.status = status || ENGAGEMENTS_WORK_STATUS.ON_SCHEDULE;
    this.completedTime = completedTime || null;
    this.updateTime = updateTime || dt.utc().toISO();
  }
}

class ExampleEngagementBudget {
  budgetSpent: string;
  changeOrders: string;
  engagementId: number;
  estimateAtCompletion: string;
  id: string;
  originalSof: string;
  remainingBudget: string;
  status: ENGAGEMENTS_BUDGET_STATUS;
  totalBudget: string;
  totalCreditsApplied: string;
  updateTime: string;
  constructor({
    id,
    engagementId,
    totalBudget,
    budgetSpent,
    remainingBudget,
    originalSof,
    changeOrders,
    status,
    estimateAtCompletion,
    totalCreditsApplied,
    updateTime,
  }: Partial<IBudgetStatus>) {
    this.budgetSpent = budgetSpent || "5000";
    this.changeOrders = changeOrders || "2500.0";
    this.engagementId = engagementId || 0;
    this.estimateAtCompletion = estimateAtCompletion || "0.0";
    this.id = id || generateMockId();
    this.originalSof = originalSof || "10000.0";
    this.remainingBudget = remainingBudget || "7500";
    this.status = status || ENGAGEMENTS_BUDGET_STATUS.ON_PLANNED_COST;
    this.totalBudget = totalBudget || "12500";
    this.totalCreditsApplied = totalCreditsApplied || "0.0";
    this.updateTime = updateTime || dt.utc().toISO();
  }
}

export const EXAMPLE_ENGAGEMENT_WORK_STATUS = new ExampleEngagementWorkStatus(
  {}
);

export const EXAMPLE_ENGAGEMENT_WORK_STATUS_BEHIND_SCHEDULE =
  new ExampleEngagementWorkStatus({
    percentageComplete: 20,
    status: ENGAGEMENTS_WORK_STATUS.BEHIND_SCHEDULE,
    dueDate: dt.utc().plus({ day: 2 }).toISO(),
    startDate: dt.utc().minus({ day: 2 }).toISO(),
    updateTime: dt.utc().toISO(),
  });

export const EXAMPLE_ENGAGEMENT_WORK_STATUS_AHEAD_OF_SCHEDULE =
  new ExampleEngagementWorkStatus({
    percentageComplete: 80,
    status: ENGAGEMENTS_WORK_STATUS.AHEAD_OF_SCHEDULE,
    dueDate: dt.utc().plus({ day: 3 }).toISO(),
    startDate: dt.utc().minus({ day: 1 }).toISO(),
    updateTime: dt.utc().toISO(),
  });

export const EXAMPLE_ENGAGEMENT_WORK_STATUS_COMPLETED =
  new ExampleEngagementWorkStatus({
    percentageComplete: 100,
    dueDate: dt.utc().minus({ day: 2 }).toISO(),
    startDate: dt.utc().minus({ month: 1, week: 2, day: 1 }).toISO(),
    completedTime: dt.utc().toISO(),
    updateTime: dt.utc().toISO(),
  });

export const EXAMPLE_ENGAGEMENT_BUDGET_STATUS = new ExampleEngagementBudget({});

export const EXAMPLE_ENGAGEMENT_BUDGET_OVER_COST = new ExampleEngagementBudget({
  status: ENGAGEMENTS_BUDGET_STATUS.OVER_PLANNED_COST,
  totalBudget: "1000",
  budgetSpent: "1200",
  remainingBudget: "0",
});

export const EXAMPLE_ENGAGEMENT_BUDGET_UNDER_COST = new ExampleEngagementBudget(
  {
    status: ENGAGEMENTS_BUDGET_STATUS.UNDER_PLANNED_COST,
  }
);
