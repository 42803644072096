import PT from "prop-types";

import { Modal, ModalDescription, ModalTitle } from "@m/ui";

import { ROLE_PERMISSIONS } from "../constants";

import { FlexItem } from "./FlexItem";

export const RolesOverviewModal = ({ isOpen, onClose }) => (
  <Modal open={isOpen} size="2xl" onClose={onClose}>
    <ModalTitle className="pb-1 text-xl">
      Understanding Roles in Mission Control
    </ModalTitle>
    <ModalDescription className="mb-3 text-gray-500">
      Only admins can change roles for other users
    </ModalDescription>
    <div className="flex space-x-4">
      <FlexItem title="Read Only" items={ROLE_PERMISSIONS.slice(0, 1)} />
      <FlexItem title="Member" items={ROLE_PERMISSIONS.slice(0, 2)} />
      <FlexItem title="Admin" items={ROLE_PERMISSIONS.slice(0, 3)} />
    </div>
  </Modal>
);

RolesOverviewModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
};
