import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import PT from "prop-types";
import { useMemo, useRef, useState } from "react";

import { EmptyMessage, Spinner } from "@m/ui";

import { ActivityLayer, ActivityPopover, CrosshairLayer } from "./components";
import {
  LINE_GRAPH_DEFAULT_LAYERS,
  LINE_GRAPH_DEFAULT_MARGIN,
  LINE_GRAPH_EMPTY_MESSAGE,
} from "./constants";
import { ActivityContext } from "./contexts";
import { ActivityPropTypes, SeriesPropTypes } from "./propTypes";

export const LineGraph = ({
  activities = [],
  ActivityPanelComponent,
  ariaLabel,
  className,
  curve = "monotoneX",
  data = [],
  emptyMessage = LINE_GRAPH_EMPTY_MESSAGE,
  enableGridX = false,
  enablePoints = false,
  enableSlices = "x",
  height = 250,
  loading = false,
  margin = LINE_GRAPH_DEFAULT_MARGIN,
  title,
  ...props
}) => {
  const popoverButtonRef = useRef();

  const [activity, setActivity] = useState();
  const [activityLineElement, setActivityLineElement] = useState();
  const [pinnedActivityIndex, setPinnedActivityIndex] = useState();

  const activityContext = useMemo(
    () => ({
      activities,
      setActivity,
      setActivityLineElement,
      pinnedActivityIndex,
      setPinnedActivityIndex,
    }),
    [activities, pinnedActivityIndex]
  );

  const gradientDefs = useMemo(
    () => [
      linearGradientDef("fadeOut", [
        { offset: 0, color: "inherit" },
        { offset: 100, color: "inherit", opacity: 0 },
      ]),
    ],
    []
  );

  const layers = useMemo(
    () => [...LINE_GRAPH_DEFAULT_LAYERS, ActivityLayer, CrosshairLayer],
    []
  );

  let isEmpty = false;
  if (data.length < 1) {
    // at least 1 line is required to plot a line graph
    isEmpty = true;
  } else {
    // at least two data points are required to plot a line on the graph
    const firstLine = data[0].data;
    if (firstLine.length < 2) isEmpty = true;
  }

  return (
    <div aria-label={ariaLabel} className={className} style={{ height }}>
      {title && <div className="font-semibold text-default">{title}</div>}

      {loading && (
        <div className="mt-2 flex h-5/6 items-center justify-center rounded-md border-2 border-dashed">
          <Spinner />
        </div>
      )}

      {!loading && isEmpty && (
        <EmptyMessage
          message={emptyMessage}
          className="!mx-0 flex h-5/6 flex-col justify-center text-subdued"
        />
      )}

      {!loading && !isEmpty && (
        <ActivityContext.Provider value={activityContext}>
          <ResponsiveLine
            curve={curve}
            data={data}
            defs={gradientDefs}
            enableGridX={enableGridX}
            enablePoints={enablePoints}
            enableSlices={enableSlices}
            layers={layers}
            margin={margin}
            {...props}
          />

          {ActivityPanelComponent && (
            <ActivityPopover
              activity={activity}
              activityLineElement={activityLineElement}
              ActivityPanelComponent={ActivityPanelComponent}
              popoverButtonRef={popoverButtonRef}
            />
          )}
        </ActivityContext.Provider>
      )}
    </div>
  );
};

LineGraph.propTypes = {
  activities: PT.arrayOf(ActivityPropTypes),
  ActivityPanelComponent: PT.elementType,
  ariaLabel: PT.string,
  className: PT.string,
  curve: PT.string,
  data: PT.arrayOf(SeriesPropTypes),
  emptyMessage: PT.string,
  enableGridX: PT.bool,
  enablePoints: PT.bool,
  enableSlices: PT.string,
  height: PT.number,
  loading: PT.bool,
  margin: PT.shape({
    top: PT.number,
    right: PT.number,
    bottom: PT.number,
    left: PT.number,
  }),
  title: PT.string,
};
