import { ChangeIndicator } from "@mc/components/ChangeIndicator";

import { SCORECARD_TREND_TIME_RANGES } from "../constants";

export interface ScorecardTrendChangeIndicatorProps {
  scoreChange: number;
  selectedRange: keyof typeof SCORECARD_TREND_TIME_RANGES;
}

export const ScorecardTrendChangeIndicator = ({
  scoreChange,
  selectedRange,
}) => {
  const timeRange = SCORECARD_TREND_TIME_RANGES[selectedRange];
  const fullText = timeRange?.fullText;

  if (scoreChange === undefined || !fullText) return null;

  return (
    <div className="flex items-center whitespace-nowrap text-sm text-subdued">
      {fullText} Change:
      <div className="font-medium">
        <ChangeIndicator
          change={scoreChange}
          showIcon={false}
          showZero={true}
          size="small"
        />
      </div>
    </div>
  );
};
