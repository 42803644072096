import { PATHS } from "@mc/constants";
import { useScorecardScore } from "@mc/features/CloudScore/api";

import { SidebarLink } from "./SidebarLink";

export const ScorecardLink = () => {
  const {
    data: { score },
    loading: scoreLoading,
  } = useScorecardScore();

  return (
    <SidebarLink
      ariaLabel="Go to Cloud Score"
      count={score > 0 ? score : null}
      loading={scoreLoading}
      title="Cloud Score"
      to={PATHS.CLOUD_SCORE}
    />
  );
};
