interface Props {
  className?: string;
  height: string | number;
  width: string | number;
}

export const AccessLockArtboard = ({ className, height, width }: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      style={{ height, width }}
      viewBox="0 0 198 198"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="027-website-data-security 1" clipPath="url(#clip0_2300_1337)">
        <path
          id="Vector"
          d="M194.906 18.5625H3.09375V185.625H194.906V18.5625Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M185.625 24.75H12.375V185.625H185.625V24.75Z"
          fill="#E2DDDF"
        />
        <path id="Vector_3" d="M198 0H0V18.5625H198V0Z" fill="#736B6D" />
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.812 185.625V18.5625H198V185.625H191.812Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.1875 18.5625V185.625H0V18.5625H6.1875Z"
          fill="#524A4D"
        />
        <path
          id="Vector_4"
          d="M151.594 105.188H46.4062V198H151.594V105.188Z"
          fill="#736B6D"
        />
        <path
          id="Vector_5"
          d="M151.594 117.562H46.4062V185.625H151.594V117.562Z"
          fill="#F37920"
        />
        <path
          id="Vector_6"
          d="M117.562 105.188V83.5312C117.562 78.6082 115.607 73.8867 112.126 70.4056C108.645 66.9244 103.923 64.9688 99 64.9688C94.0769 64.9688 89.3555 66.9244 85.8743 70.4056C82.3932 73.8867 80.4375 78.6082 80.4375 83.5312V105.188H68.0625V83.5312C68.0625 75.3261 71.322 67.457 77.1239 61.6551C82.9258 55.8532 90.7949 52.5938 99 52.5938C107.205 52.5938 115.074 55.8532 120.876 61.6551C126.678 67.457 129.938 75.3261 129.938 83.5312V105.188H117.562Z"
          fill="white"
        />
        <path
          id="Vector (Stroke)_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 55.6875C91.6154 55.6875 84.5332 58.621 79.3115 63.8427C74.0898 69.0645 71.1562 76.1466 71.1562 83.5312V105.188H64.9688V83.5312C64.9688 74.5056 68.5542 65.8496 74.9363 59.4675C81.3184 53.0854 89.9744 49.5 99 49.5C108.026 49.5 116.682 53.0854 123.064 59.4675C129.446 65.8496 133.031 74.5056 133.031 83.5312V105.188H126.844V83.5312C126.844 76.1466 123.91 69.0645 118.689 63.8427C113.467 58.621 106.385 55.6875 99 55.6875Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 68.0625C94.8974 68.0625 90.9629 69.6922 88.0619 72.5932C85.161 75.4941 83.5312 79.4287 83.5312 83.5312V105.188H77.3438V83.5312C77.3438 77.7877 79.6254 72.2793 83.6867 68.218C87.7481 64.1566 93.2564 61.875 99 61.875C104.744 61.875 110.252 64.1566 114.313 68.218C118.375 72.2793 120.656 77.7877 120.656 83.5312V105.188H114.469V83.5312C114.469 79.4287 112.839 75.4941 109.938 72.5932C107.037 69.6922 103.103 68.0625 99 68.0625Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.031 148.5H64.9688V142.312H133.031V148.5Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.031 136.125H64.9688V129.938H133.031V136.125Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.469 145.406V133.031H120.656V145.406H114.469Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.094 145.406V133.031H108.281V145.406H102.094Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.7188 145.406V133.031H95.9062V145.406H89.7188Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_10"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.3438 145.406V133.031H83.5312V145.406H77.3438Z"
          fill="#524A4D"
        />
        <path
          id="Vector (Stroke)_11"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0312 136.125V129.938H40.2188V136.125H34.0312Z"
          fill="#736B6D"
        />
        <path
          id="Vector (Stroke)_12"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0312 148.5V142.312H40.2188V148.5H34.0312Z"
          fill="#736B6D"
        />
        <path
          id="Vector (Stroke)_13"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0312 160.875V154.688H40.2188V160.875H34.0312Z"
          fill="#736B6D"
        />
        <path
          id="Vector (Stroke)_14"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.781 136.125V129.938H163.969V136.125H157.781Z"
          fill="#736B6D"
        />
        <path
          id="Vector (Stroke)_15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.781 148.5V142.312H163.969V148.5H157.781Z"
          fill="#736B6D"
        />
        <path
          id="Vector (Stroke)_16"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.781 160.875V154.688H163.969V160.875H157.781Z"
          fill="#736B6D"
        />
        <path
          id="Vector (Stroke)_17"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M185.625 12.375H55.6875V6.1875H185.625V12.375Z"
          fill="#E2DDDF"
        />
        <path
          id="Vector (Stroke)_18"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.125 6.1875H43.3125V12.375H37.125V6.1875Z"
          fill="#E2DDDF"
        />
        <path
          id="Vector (Stroke)_19"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.375 6.1875H18.5625V12.375H12.375V6.1875Z"
          fill="#E2DDDF"
        />
        <path
          id="Vector (Stroke)_20"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.75 6.1875H30.9375V12.375H24.75V6.1875Z"
          fill="#E2DDDF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2300_1337">
          <rect width="198" height="198" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
