import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

import {
  AccessRequired,
  useAccessRequired,
} from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, PATHS } from "@mc/constants";
import { DashboardIcon } from "@mc/icons";

import {
  EngagementsGroup,
  ResourcesDropdownLinks,
  ScorecardLink,
  SidebarGroup,
  SidebarLink,
  SidebarWrapper,
} from "./components";
import { useSidebar } from "./contexts";

export const Sidebar = ({ className }: { className?: string }) => {
  const { isSidebarHidden } = useSidebar();

  const showDashboard = useAccessRequired({ feature: FEATURE_FLAGS.DASHBOARD });
  const showCases = useAccessRequired({ feature: FEATURE_FLAGS.CASES });
  const isEngagementsEnabled = useAccessRequired({
    feature: FEATURE_FLAGS.ENGAGEMENTS,
  });
  const isDevOpsDeskEnabled = useAccessRequired({
    feature: FEATURE_FLAGS.DEVOPS_DESK,
  });
  const showEngagementsGroup = isEngagementsEnabled || isDevOpsDeskEnabled;

  if (isSidebarHidden) return null;

  return (
    <SidebarWrapper className={className}>
      {(showDashboard || showCases) && (
        <SidebarGroup>
          {showDashboard && (
            <SidebarLink
              ariaLabel="Go to Dashboard"
              icon={DashboardIcon}
              title="Dashboard"
              to={PATHS.DASHBOARD}
            />
          )}
          {showCases && (
            <SidebarLink
              ariaLabel="Go to Support Cases"
              icon={ChatBubbleLeftEllipsisIcon}
              title="Cases"
              to={PATHS.SUPPORT}
            />
          )}
        </SidebarGroup>
      )}

      <AccessRequired feature={FEATURE_FLAGS.SCORECARDS}>
        <SidebarGroup title="Insights">
          <ScorecardLink />
        </SidebarGroup>
      </AccessRequired>

      {showEngagementsGroup && (
        <EngagementsGroup
          isDevOpsDeskEnabled={isDevOpsDeskEnabled}
          isEngagementsEnabled={isEngagementsEnabled}
        />
      )}

      <SidebarGroup title="Operations">
        <SidebarLink to={PATHS.ACCOUNTS_AWS} title="Accounts" />
        <AccessRequired feature={FEATURE_FLAGS.RESOURCES}>
          <ResourcesDropdownLinks />
        </AccessRequired>
      </SidebarGroup>
    </SidebarWrapper>
  );
};
