import { Button } from "@m/ui";

import { PATHS } from "@mc/constants";
import { ErrorPage } from "@mc/pages";

export const OnboardingRequestError = () => (
  <ErrorPage
    title="Unable to onboard AWS account"
    content="Something unexpected happened."
    action={
      <Button kind="primary" to={PATHS.ACCOUNTS_AWS_ADD}>
        Back
      </Button>
    }
  />
);
