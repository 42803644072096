const servicesApi =
  process.env.REACT_APP_STORYBOOK_SERVICES_API ||
  process.env.REACT_APP_SERVICES_API;

export const getValidateTokenUrl = () =>
  servicesApi + "/api/public/v1/marketplace/token/validate";

export const getValidateInvitationUrl = () =>
  servicesApi + "/api/public/v1/marketplace/invitation/validate";

export const getSignupUrl = () =>
  servicesApi + "/api/public/v1/marketplace/signup";
