import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";

import { parseSort } from "../utils";

interface TableHeaderProps {
  defaultSort: string;
  empty: boolean;
  headerFontSize: "default" | "small";
  headerGroups: any[];
  onSortChange: (sort: string) => void;
}

export const TableHeader = ({
  defaultSort,
  empty,
  headerFontSize = "default",
  headerGroups,
  onSortChange,
}: TableHeaderProps) => {
  const [sort, setSort] = useState(defaultSort);
  const { key: sortKey, direction: sortDirection } = parseSort(sort);

  const toggleSort = (key: string) => {
    if (!key) return;
    let direction = "ASC";
    if (key === sortKey) direction = reverse(sortDirection);
    const newSort = `${key}_${direction}`;
    setSort(newSort);
    onSortChange(newSort);
  };

  return (
    <thead>
      {headerGroups.map((headerGroup, headerGroupIndex) => {
        return (
          <tr
            key={`header-group-${headerGroupIndex}`}
            {...headerGroup.getHeaderGroupProps([{ className: "border-b" }])}
          >
            {headerGroup.headers.map((column: any, columnIndex: number) => (
              <th
                key={`header-group-${headerGroupIndex}-column-${columnIndex}`}
                aria-label={`Table Header ${column?.Header}`}
                {...column.getHeaderProps([
                  {
                    className: clsx(
                      "p-1.5 pt-0 text-default align-middle whitespace-nowrap",
                      {
                        "cursor-pointer hover:text-action":
                          column.sort.key && !empty,
                        "text-gray-400 cursor-default": empty,
                        "text-right": column.align === "right",

                        // Font Size
                        "text-sm font-medium": headerFontSize === "default",
                        "text-xs font-semibold": headerFontSize === "small",
                      }
                    ),
                  },
                ])}
                onClick={() => !empty && toggleSort(column.sort.key)}
              >
                <span className="inline-flex gap-0.5">
                  {column.render("Header")}
                  {column.Icon && (
                    <column.Icon
                      className="h-3 w-3 pl-1"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        column.onIconClick?.();
                      }}
                    />
                  )}
                  {sort &&
                    !empty &&
                    sortKey === column.sort.key &&
                    (sortDirection === "ASC" ? (
                      <ArrowSmallUpIcon
                        className="inline h-2.5 w-2.5 align-top text-action"
                        aria-label={`Sort descending on ${column.render(
                          "Header"
                        )}`}
                      />
                    ) : (
                      <ArrowSmallDownIcon
                        className="inline h-2.5 w-2.5 align-top text-action"
                        aria-label={`Sort ascending on ${column.render(
                          "Header"
                        )}`}
                      />
                    ))}
                </span>
              </th>
            ))}
          </tr>
        );
      })}
    </thead>
  );
};

const reverse = (direction: string): string =>
  direction === "ASC" ? "DESC" : "ASC";
