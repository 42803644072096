import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { Dropdown, DropdownGroup, DropdownItem } from "@m/ui";
import { loadIntercom } from "@m/utils";

import { useAccessRequired } from "@mc/components/AccessRequired";
import {
  KNOWLEDGE_BASE_URL,
  MARKETPLACE_KNOWLEDGE_BASE_URL,
  RELEASE_NOTES_URL,
  SKUS,
} from "@mc/constants";
import { useUsersnapEvent } from "@mc/providers/UsersnapProvider";

export const SupportDropdown = () => {
  const [openFeedbackForm, isUsersnapLoaded] =
    useUsersnapEvent("manual-feedback");

  const isCloudScoreUser = useAccessRequired({
    entitlement: SKUS.CLOUD_SCORE,
  });

  const [openSupportForm] = useUsersnapEvent("app-support-form");

  const handleClickFeedback = () => openFeedbackForm();
  const handleClickHelp = () => openSupportForm();

  const handleClickReleaseNotes = () => {
    loadIntercom(window.open(RELEASE_NOTES_URL, "_blank"));
  };

  const handleClickKnowledgeBase = () => {
    const knowledgeBaseUrl = isCloudScoreUser
      ? MARKETPLACE_KNOWLEDGE_BASE_URL
      : KNOWLEDGE_BASE_URL;
    loadIntercom(window.open(knowledgeBaseUrl, "_blank"));
  };

  return (
    <Dropdown
      direction="bottom-end"
      trigger={
        <div
          className="flex items-center space-x-0.5 text-accent"
          data-testid="support-dropdown-trigger"
        >
          <div className="text-sm font-semibold">Help</div>
          <ChevronDownIcon className="h-2.5 w-2.5" />
        </div>
      }
    >
      <DropdownGroup>
        <DropdownItem onClick={handleClickHelp} disabled={!isUsersnapLoaded}>
          Application Help
        </DropdownItem>
        <DropdownItem
          onClick={handleClickFeedback}
          disabled={!isUsersnapLoaded}
        >
          Feedback
        </DropdownItem>
      </DropdownGroup>
      <DropdownGroup>
        <DropdownItem onClick={handleClickKnowledgeBase}>
          Knowledge Base
        </DropdownItem>
        <DropdownItem onClick={handleClickReleaseNotes}>
          Release Notes
        </DropdownItem>
      </DropdownGroup>
    </Dropdown>
  );
};
