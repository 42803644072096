import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@m/login";

import { TabData, Tabs } from "@mc/components/Tabs";
import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { SETTINGS_TABS, SETTINGS_TABS_DATA } from "../constants";
import { useIsBillingEnabled } from "../hooks";

export const SettingsTabs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const disabledTabIds = [];

  const isBillingEnabled = useIsBillingEnabled();
  if (!isBillingEnabled) disabledTabIds.push(SETTINGS_TABS.billing.id);

  const location = useLocation();
  const activeTabName = location.pathname.split("/").slice(-1)[0];

  const handleTabChange = (tab: TabData) => {
    navigate(
      generateCompanyPath(`${PATHS.SETTINGS}/${tab.id}`, user.company.slug)
    );
  };

  return (
    <Tabs
      activeTabName={activeTabName}
      disabledTabIds={disabledTabIds}
      onTabChange={handleTabChange}
      tabsData={SETTINGS_TABS_DATA}
    />
  );
};
