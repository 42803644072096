import { TBadgeStatus } from "@m/ui";

export enum ENGAGEMENTS_WORK_STATUS {
  ON_SCHEDULE = "ON_SCHEDULE",
  BEHIND_SCHEDULE = "BEHIND_SCHEDULE",
  AHEAD_OF_SCHEDULE = "AHEAD_OF_SCHEDULE",
}

export enum ENGAGEMENTS_BUDGET_STATUS {
  ON_PLANNED_COST = "ON_PLANNED_COST",
  UNDER_PLANNED_COST = "UNDER_PLANNED_COST",
  OVER_PLANNED_COST = "OVER_PLANNED_COST",
}

export enum ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME {
  ON_PLANNED_COST = "Within Budget",
  UNDER_PLANNED_COST = "Under Budget",
  OVER_PLANNED_COST = "Over Budget",
}

export enum ENGAGEMENT_TYPE_DISPLAY_NAME {
  ASSESS = "MAP Assess",
  MOBILIZE = "MAP Mobilize",
  OTHER = "Other",
}

export const MESSAGES_ENGAGEMENTS_TEMPLATE_FILL = {
  GET_ERROR: "Unable to retreive template details",
};

export const MESSAGES_ENGAGEMENTS_STATUS = {
  EMPTY: "Unable to find status data",
  GET_ERROR: "Unable to retreive project status",
  UPDATE_SUCCESS: "Successfully updated the project status",
  UPDATE_ERROR: "Unable to update project status",
};

export const MESSAGES_ENGAGEMENTS_WORK_STATUS = {
  GET_ENGAGEMENT_WORK_STATUS_ERROR:
    "Unable to retrieve engagement work status.",
  UPDATE_WORK_STATUS_ERROR: "Unable to update work status details.",
  UPDATE_WORK_STATUS_SUCCESS: "Successfully updated the work status details.",
};

export const MESSAGES_ENGAGEMENTS_BUDGET = {
  GET_BUDGET_ERROR: "We encountered an issue retrieving budget status details.",
  UPDATE_BUDGET_ERROR: "Unable to update budget status details.",
  UPDATE_BUDGET_SUCCESS: "Successfully updated the budget status details.",
};

export const ENGAGEMENT_BUDGET_BADGE_STATUS: {
  [ENGAGEMENTS_BUDGET_STATUS: string]: TBadgeStatus;
} = {
  [ENGAGEMENTS_BUDGET_STATUS.ON_PLANNED_COST]: "positive",
  [ENGAGEMENTS_BUDGET_STATUS.OVER_PLANNED_COST]: "negative",
  [ENGAGEMENTS_BUDGET_STATUS.UNDER_PLANNED_COST]: "positive",
};

export const ENGAGEMENT_WORK_BADGE_STATUS: {
  [ENGAGEMENTS_WORK_STATUS: string]: TBadgeStatus;
} = {
  [ENGAGEMENTS_WORK_STATUS.ON_SCHEDULE]: "positive",
  [ENGAGEMENTS_WORK_STATUS.BEHIND_SCHEDULE]: "negative",
  [ENGAGEMENTS_WORK_STATUS.AHEAD_OF_SCHEDULE]: "positive",
};
