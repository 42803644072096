import { InformationCircleIcon } from "@heroicons/react/24/outline";
import PT from "prop-types";

export const RBACRestrictionInfoBox = ({ restrictedAccounts = [] }) => {
  if (restrictedAccounts.length < 1) return null;

  return (
    <div
      aria-label="Restricted Resources"
      className="border-1 m-3 rounded-xl border border-[#bddcef] bg-[#f3faff] p-2"
    >
      <InformationCircleIcon className="float-left mr-1 h-3 w-3 text-[#4190c0]" />
      Resources linked to one or more customer AWS accounts have been hidden
      based on your access level in Okta:
      <ul className="m-3 mx-4 mb-0">
        {restrictedAccounts.map((account) => (
          <li key={account.id} className="list-disc font-bold">
            {account.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

RBACRestrictionInfoBox.propTypes = {
  restrictedAccounts: PT.arrayOf(
    PT.shape({
      name: PT.string.isRequired,
      id: PT.string.isRequired,
    })
  ),
};
