import { generateMockId } from "@m/api/testing";
import { AccountAvatar } from "@m/ui";
import { dt } from "@m/utils";

import { ENGAGEMENTS_OWNER_OPTIONS } from "../constants";
import {
  EngagementDetailOwnerType,
  IEngagementsChecklistItem,
  IEngagementsDetailsNote,
} from "../types";

class ExampleEngagementsChecklistItem {
  createTime: string;
  dueDate: string;
  engagementId: string;
  id: string;
  noteList: IEngagementsDetailsNote[];
  open: boolean;
  owner: EngagementDetailOwnerType;
  title: string;
  updateTime: string;
  createdBy: AccountAvatar;

  constructor({
    createTime,
    dueDate,
    engagementId,
    id,
    noteList,
    open,
    owner,
    title,
    createdBy,
  }: Partial<IEngagementsChecklistItem>) {
    this.createTime = createTime || dt.utc().minus({ weeks: 1 }).toISO();
    this.dueDate =
      dueDate || dt.utc().startOf("day").plus({ weeks: 1 }).toISO();
    this.engagementId = engagementId || generateMockId();
    this.id = id || generateMockId();
    this.noteList = noteList || [
      {
        id: "123",
        content: "Example notes",
        createdAt: dt.utc().toISO(),
        createdBy: {
          id: generateMockId(),
          email: "tom@tom.com",
          name: "Tom Tom",
          firstName: "Tom",
          lastName: "Tom",
        },
        updatedAt: dt.utc().toISO(),
      },
    ];

    this.createdBy = createdBy || {
      email: "tom@tom.com",
      name: "Tom Tom",
      firstName: "Tom",
      lastName: "Tom",
    };
    this.open = open;
    this.owner = owner;
    this.title = title || "Example Title";
    this.updateTime = dt.utc().minus({ days: 1 }).toISO();
  }
}

export const EXAMPLE_ENGAGEMENTS_CHECKLIST_ITEM_CUSTOMER =
  new ExampleEngagementsChecklistItem({
    open: false,
    owner: ENGAGEMENTS_OWNER_OPTIONS.CUSTOMER,
    title: `Example Title 5`,
  });

export const EXAMPLE_ENGAGEMENTS_CHECKLIST_ITEM_OPEN =
  new ExampleEngagementsChecklistItem({
    open: true,
    owner: ENGAGEMENTS_OWNER_OPTIONS.MISSION,
    title: `Open Action Item`,
  });

export const EXAMPLE_ENGAGEMENTS_CHECKLIST_ITEM_COMPLETED =
  new ExampleEngagementsChecklistItem({
    open: false,
    owner: ENGAGEMENTS_OWNER_OPTIONS.MISSION,
    title: `Completed Action Item`,
  });

export const EXAMPLE_ENGAGEMENTS_CHECKLIST = [
  EXAMPLE_ENGAGEMENTS_CHECKLIST_ITEM_OPEN,
  EXAMPLE_ENGAGEMENTS_CHECKLIST_ITEM_COMPLETED,
  EXAMPLE_ENGAGEMENTS_CHECKLIST_ITEM_CUSTOMER,
];
