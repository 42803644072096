import { MarketplaceRegistration } from "@m/api/public/types";

/**
 * Find and return the first onboarding that matches a given SKU.
 */
export const findOnboarding = (
  onboardings: Partial<MarketplaceRegistration>[],
  sku: string
): Partial<MarketplaceRegistration> | null => {
  const filteredOnboardings =
    onboardings.filter((onboarding) => onboarding.sku === sku) || [];

  return filteredOnboardings?.[0] || null;
};
