import { ReactNode } from "react";

interface Props {
  action: ReactNode;
  category: string;
  description?: string;
  htmlFor?: string;
}

export const NotificationOption = ({
  action,
  category,
  description,
  htmlFor,
}: Props) => {
  return (
    <div className="flex items-center justify-between space-x-3">
      <div className="space-y-0.5">
        <label className="text-sm font-semibold text-default" htmlFor={htmlFor}>
          {category}
        </label>
        <div className="text-xs font-medium text-subdued">{description}</div>
      </div>
      <div>{action}</div>
    </div>
  );
};
