import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";

import { MissionControlApp } from "@mc/app";
import { initializeSentry } from "@mc/utils/sentry";

import { SentryReportDialog } from "./app/components";

/*
 * Initialize user tracking and error reporting tools
 */
if (process.env.NODE_ENV === "production") {
  // don't log exceptions from Playwright, which runs per-MR, as there's
  // potential for lots of false positives
  if (navigator.webdriver !== true) initializeSentry();
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<SentryReportDialog />}>
      <MissionControlApp />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
