import { Popover } from "@headlessui/react";
import PT from "prop-types";
import { useContext, useState } from "react";
import { usePopper } from "react-popper";

import { ActivityContext } from "../contexts";

export const ActivityPopover = ({
  activity,
  activityLineElement,
  ActivityPanelComponent,
  popoverButtonRef,
}) => {
  const [popperElement, setPopperElement] = useState();

  const { styles, attributes } = usePopper(activityLineElement, popperElement, {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 32],
        },
      },
    ],
  });

  const { setActivity, setPinnedActivityIndex } = useContext(ActivityContext);

  const handleClose = () => {
    setActivity();
    setPinnedActivityIndex();
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className="hidden" ref={popoverButtonRef} />

          {(activity || open) && (
            <Popover.Panel
              aria-label="Activity Panel"
              className="z-10 max-w-sm rounded-xl bg-white shadow-lg shadow-gray-300"
              ref={setPopperElement}
              style={styles.popper}
              static={true}
              {...attributes.popper}
            >
              <Popover.Button>
                <ActivityPanelComponent
                  activity={activity}
                  onClick={handleClose}
                />
              </Popover.Button>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

ActivityPopover.propTypes = {
  activity: PT.object,
  activityLineElement: PT.instanceOf(Element),
  ActivityPanelComponent: PT.elementType.isRequired,
  popoverButtonRef: PT.shape({ current: PT.any }).isRequired,
};
