import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { ScorecardRecommendationStatus } from "@m/api/public/types";

export const GET_CHECK_DETAILS = gql(/* GraphQL */ `
  query useCheckDetails($id: ID!, $where: ScorecardRecommendationFilter) {
    scorecardCheckResult(id: $id) {
      __typename
      description
      flaggedResourceFields
      id
      ignored
      lastRefresh
      name
      pillar
      source
      sourceId
      status
      totalResources
      restrictedAwsAccounts {
        edges {
          node {
            id: databaseId
            name
          }
        }
      }
    }
    scorecardRecommendations(where: $where) {
      edges {
        node {
          id
          notes
          scorecardRecStatus
          scorecardResult {
            id
            pillar
            status
            name
            flaggedResourceFields
          }
          title
          updatedTime
        }
      }
    }
  }
`);

export const useCheckDetails = (checkId: string) => {
  const { data, loading, error } = useQuery(GET_CHECK_DETAILS, {
    variables: {
      id: checkId,
      where: {
        scorecardResultId: checkId,
        scorecardRecStatus: ScorecardRecommendationStatus.Active,
      },
    },
  });

  const check = data?.scorecardCheckResult;
  const recommendations =
    data?.scorecardRecommendations?.edges?.map((edge) => edge.node) || [];
  const restrictedAccounts =
    check?.restrictedAwsAccounts?.edges?.map((edge) => edge.node) || [];

  return {
    data: { check, recommendations, restrictedAccounts },
    error: check === null || error !== undefined,
    loading,
  };
};
