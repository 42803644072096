import { PATHS } from "@mc/constants";
import { usePendingCloudGatewayOnboarding } from "@mc/features/Onboarding/hooks";
import { Navigate } from "@mc/router";

/** Redirects to the current Cloud Gateway onboarding path. */
export const CloudGatewayOnboardingPathRedirect = (): JSX.Element => {
  const pendingCloudGatewayOnboarding = usePendingCloudGatewayOnboarding();
  const payerAccountId = pendingCloudGatewayOnboarding?.payerAccountId;
  const currentPath = getCurrentOnboardingPath(payerAccountId);

  return <Navigate replace to={currentPath} />;
};

const getCurrentOnboardingPath = (payerAccountId: string = null): string => {
  if (payerAccountId !== null) {
    return PATHS.ONBOARDING_CLOUD_GATEWAY_CONNECT_PAYER;
  }
  return PATHS.ONBOARDING_CLOUD_GATEWAY_START;
};
