export const LINE_GRAPH_DEFAULT_LAYERS = [
  "areas",
  "axes",
  "crosshair",
  "grid",
  "legends",
  "lines",
  "markers",
  "mesh",
  "points",
  "slices",
];
