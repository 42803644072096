import { ChangeEventHandler, useState } from "react";

import { CaseCategory, CaseSubcategory } from "@m/api/public/types";
import { Field, Select } from "@m/ui";

import {
  CASE_CATEGORY_DEFINITIONS,
  CaseCategoryDefinition,
} from "../constants";
import { CaseType } from "../types";

import { ServiceLimitInfoBox } from "./ServiceLimitInfoBox";

export const CategorySelector = ({ caseType }: { caseType: CaseType }) => {
  const categories = CASE_CATEGORY_DEFINITIONS[caseType];

  const [categoryName, setCategoryName] = useState<string>();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setCategoryName(
      e.currentTarget.options[e.currentTarget.selectedIndex].text
    );
  };

  if (caseType === CaseType.Support)
    return (
      <select id="category" name="categorySelect" className="hidden">
        <option
          value={`${CaseCategory.Request}:${CaseSubcategory.GeneralGuidance}`}
        >
          General Guidance
        </option>
      </select>
    );

  return (
    <Field htmlFor="category" label="Category" row="2/3" rowAlign="top">
      <div className="flex flex-col gap-1">
        <Select
          id="category"
          name="categorySelect"
          onChange={handleChange}
          options={categories.map(({ name, value }) => (
            <option key={name} value={value}>
              {name}
            </option>
          ))}
        />
        <SubcategorySelector
          categories={categories}
          categoryName={categoryName}
        />
        {caseType === CaseType.Account &&
          categoryName === "Service Quota Increase" && <ServiceLimitInfoBox />}
      </div>
    </Field>
  );
};

const SubcategorySelector = ({
  categories,
  categoryName,
}: {
  categories: CaseCategoryDefinition[];
  categoryName: string;
}) => {
  const currentCategory: CaseCategoryDefinition =
    categories.find((category) => category.name === categoryName) ||
    categories[0];

  const { subcategories = [] } = currentCategory;
  if (subcategories.length === 0) return null;

  return (
    <Select
      aria-label="Subcategory"
      name="subcategorySelect"
      options={subcategories.map(({ name, value }) => (
        <option key={name} value={value}>
          {name}
        </option>
      ))}
    />
  );
};
