import { CalendarIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { Badge, Card } from "@m/ui";
import { dt, formatFullDate, fromSnakeCaseToProperCase } from "@m/utils";

import {
  ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME,
  ENGAGEMENT_BUDGET_BADGE_STATUS,
  ENGAGEMENT_STATUS,
  ENGAGEMENT_TYPE_DISPLAY_NAME,
  ENGAGEMENT_WORK_BADGE_STATUS,
} from "../constants";
import { IBudgetStatus, IEngagement, IWorkStatus } from "../types";
import { getRemainingBudgetPercent, getTrackedTime } from "../utils";

export const EngagementsProjectsCard = ({
  engagement,
}: {
  engagement: IEngagement;
}) => {
  const {
    id,
    title,
    visible: visibleToCustomers,
    workStatus,
    budgetStatus,
    source,
    engagementType,
  } = engagement;
  const isCompleted = source?.status === ENGAGEMENT_STATUS.COMPLETED;
  const engagementTypeLabel = ENGAGEMENT_TYPE_DISPLAY_NAME[engagementType];

  return (
    <Card
      data-testid={`engagement-project-card-${id}`}
      className={clsx("flex h-[280px] flex-col justify-between p-3", {
        "bg-zinc-50 opacity-50": isCompleted,
      })}
    >
      <div className="flex-none">
        <div className="mb-1">
          <Badge
            data-testid="engagement-template-badge"
            label={engagementTypeLabel}
            className="mr-2 text-subdued"
          />
          {!visibleToCustomers && (
            <Badge
              data-testid="engagement-visibility-badge"
              label="Private"
              className="mr-2 text-subdued"
            />
          )}
          {isCompleted && (
            <Badge
              data-testid="engagement-completed-badge"
              label={ENGAGEMENT_STATUS.COMPLETED}
              className="mr-2"
              status="positive"
              strong={true}
            />
          )}
        </div>
        <div className="text-xl font-bold">{title}</div>
      </div>
      <ProjectStatuses
        workStatus={workStatus}
        budgetStatus={budgetStatus}
        isCompleted={isCompleted}
      />
    </Card>
  );
};

const ProjectStatuses = ({
  workStatus,
  budgetStatus,
  isCompleted,
}: {
  workStatus: IWorkStatus;
  budgetStatus: IBudgetStatus;
  isCompleted: boolean;
}) => {
  const budgetBadgeStatus =
    ENGAGEMENT_BUDGET_BADGE_STATUS[budgetStatus?.status];
  const budgetRemainingPercent = getRemainingBudgetPercent(budgetStatus);

  const workStatusLabel = fromSnakeCaseToProperCase(workStatus?.status);
  const workBadgeStatus = ENGAGEMENT_WORK_BADGE_STATUS[workStatus?.status];

  const isOverdue = dt.now().toISO() > workStatus?.dueDate;
  const startDateTimeDifference = isOverdue
    ? getTrackedTime(workStatus?.dueDate, dt.now().toISO())
    : getTrackedTime(dt.now().toISO(), workStatus?.dueDate);
  const dueInText = isOverdue
    ? `Due ${startDateTimeDifference} ago`
    : `Due in ${startDateTimeDifference}`;

  return (
    <div className="flex-none">
      <div className="grid grid-cols-3 py-1 font-semibold text-subdued">
        <div className="inline-flex grow gap-0.5 uppercase">
          <CalendarIcon className="h-3 w-3 stroke-2" />
          Work
        </div>
        <div
          className={clsx("col-span-2", {
            "text-emerald-500": isCompleted,
          })}
        >
          {isCompleted ? (
            <div className="mb-3">
              {`Completed ${formatFullDate(workStatus?.completedTime)}`}
            </div>
          ) : (
            <>
              <Badge
                data-testid="work-status-badge"
                strong={true}
                label={workStatusLabel}
                status={workBadgeStatus}
              />
              <br />
              {dueInText}
            </>
          )}
        </div>
      </div>
      <div className={"grid grid-cols-3 py-1 font-semibold text-subdued"}>
        <div className="inline-flex grow gap-0.5 uppercase">
          <CurrencyDollarIcon className="h-3 w-3 stroke-2" />
          Budget
        </div>
        <div
          className={clsx("col-span-2", {
            "text-emerald-500": isCompleted,
          })}
        >
          {isCompleted ? (
            <div className="mb-3">{`${budgetRemainingPercent}% remaining`}</div>
          ) : (
            <>
              <Badge
                data-testid="budget-status-badge"
                strong={true}
                label={
                  ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME[budgetStatus?.status]
                }
                status={budgetBadgeStatus}
              />
              <br />
              {`${budgetRemainingPercent}% of the budget remaining`}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
