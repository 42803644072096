import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemBudgetSpent: EngagementActivityFeedComponent =
  ({ id, before, after, timestamp }) => {
    if (!before || !after) return null;

    return (
      <EngagementActivityFeedItem timestamp={timestamp} key={id}>
        The budget consumed was updated from {before}% → {after}%
      </EngagementActivityFeedItem>
    );
  };
