import { useEntitlements, useExtensions } from "@m/api";
import { useAuth } from "@m/login";

export interface AccessRequirements {
  /** Entitlement(s) required to grant access */
  entitlement?: string | string[];

  /** Feature flag(s) required to grant access */
  feature?: string | string[];

  /** Mutation restriction(s) to block access */
  mutation?: string | string[];

  /** Query restriction(s) to block access */
  query?: string | string[];

  /** Role(s) required to grant access */
  role?: string | string[];
}

export const useAccessRequired = ({
  entitlement,
  feature,
  mutation,
  query,
  role,
}: AccessRequirements): boolean => {
  const { user } = useAuth();
  const entitlements = useEntitlements();
  const extensions = useExtensions();

  /**
   * if we fail to load extensions or entitlements for whatever reason, block access
   */
  if (!extensions) return false;
  if (!entitlements) return false;

  /**
   * if the user does not have all of the required entitlements, block access
   */
  const isMissingEntitlements = checkForAll(entitlement, entitlements);

  if (isMissingEntitlements) return false;

  /**
   * if the user does not have all of the required feature flags, block access
   */
  const enabledFeatureFlags = extensions?.featureFlags || [];
  const ignoreFeatureFlags = process.env.REACT_APP_IGNORE_FEATURE_FLAGS;

  const isMissingFeatureFlags = checkForAll(feature, enabledFeatureFlags);

  if (isMissingFeatureFlags && !ignoreFeatureFlags) return false;

  /**
   * if the user has restricted access to any of the included queries, block access
   */
  const restrictedQueries = extensions?.rbac?.queries?.restricted || [];

  const hasQueryRestrictions = checkForAny(query, restrictedQueries);

  if (hasQueryRestrictions) return false;

  /**
   * if the user has restricted access to any of the included mutations, block access
   */
  const restrictedMutations = extensions?.rbac?.mutations?.restricted || [];

  const hasMutationRestrictions = checkForAny(mutation, restrictedMutations);

  if (hasMutationRestrictions) return false;

  /**
   * if the user does not have all of the required roles, block access
   */
  const userRoles = user?.roles || [];

  const isMissingRoles = checkForAll(role, userRoles);

  if (isMissingRoles) return false;

  // else allow access
  return true;
};

const checkForAll = (option: string | string[], list: string[]): boolean => {
  if (typeof option === "string") {
    const hasRequiredOption = list.includes(option);
    return option && !hasRequiredOption;
  }

  if (Array.isArray(option)) {
    const hasRequiredOptions = option.reduce<boolean>((prev, curr) => {
      return prev && list.includes(curr);
    }, true);
    return option.length > 0 && !hasRequiredOptions;
  }
};

const checkForAny = (option: string | string[], list: string[]): boolean => {
  if (typeof option === "string") {
    return option && list.includes(option);
  }

  if (Array.isArray(option)) {
    const hasRestrictions = option.reduce<boolean>((prev, curr) => {
      return prev || list.includes(curr);
    }, false);

    return option.length > 0 && hasRestrictions;
  }
};
