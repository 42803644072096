import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";
import { Link, MissionLogo } from "@m/ui";

import { Toolbar } from "@mc/components/Toolbar";
import { CONTACT_US_URL } from "@mc/constants";
import { useIsStaff } from "@mc/hooks";

export const FullScreenLayout = ({
  children = <Outlet />,
}: PropsWithChildren) => {
  const isStaff = useIsStaff();
  const { user, logout } = useAuth();

  return (
    <div className="flex h-screen flex-col">
      <div>
        {isStaff ? (
          <Toolbar />
        ) : (
          <MissionLogo className="mb-1 ml-3 mt-3 h-5 w-5" />
        )}
      </div>

      <div className="grow" />

      <div className="flex grow flex-col items-center overflow-y-auto pb-5">
        {children}
        <div className="mt-5 text-center text-sm font-medium text-default">
          Need help? <Link to={CONTACT_US_URL}>Contact us</Link>
          {user && (
            <>
              {" | "}
              <Link onClick={logout}>Logout</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
