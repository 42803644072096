import { RouteObject } from "react-router-dom";

import {
  ConnectCloudScoreAccountPage,
  OnboardingCompleteProfilePage,
  OnboardingWelcomePage,
} from "@mc/features/Onboarding";

import { CloudScoreOnboardingPathRedirect } from "./redirects";

export const onboardingCloudScoreRoutes: RouteObject[] = [
  {
    path: "start",
    element: <OnboardingWelcomePage />,
  },
  {
    path: "account",
    element: <ConnectCloudScoreAccountPage />,
  },
  {
    path: "profile",
    element: <OnboardingCompleteProfilePage />,
  },
  {
    path: "*",
    element: <CloudScoreOnboardingPathRedirect />,
  },
];
