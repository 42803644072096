import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { CaseCategory, CaseFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, usePaginationProps } from "@m/ui";

import { CASE_LIST_STATUS_FILTERS } from "@mc/features/CustomerCases/constants";

export const GET_DEVOPS_DESK_REQUESTS = gql(/* GraphQL */ `
  query DevOpsDeskList(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $where: CaseFilter
    $sort: [CaseSortEnum]
  ) {
    cases(
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      where: $where
      sort: $sort
    ) {
      edges {
        node {
          id
          description
          shortDescription
          state
          number
          sysId
          priority
          lastActivity
          source
          openedBy {
            id
            name
            email
          }
          assignedTo {
            id
            name
            email
            firstName
            lastName
          }
          openedAt
          category
          totalReplies
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useDevOpsDeskList = (query) => {
  const statusFilter = CASE_LIST_STATUS_FILTERS[query.status]?.where;

  const where: CaseFilter = {
    categoryIn: [CaseCategory.DevopsDesk],
  };

  if (query.status) where.stateIn = statusFilter;

  const { loading, error, data, fetchMore } = useQuery(
    GET_DEVOPS_DESK_REQUESTS,
    {
      variables: {
        ...INITIAL_PAGINATION,
        where,
        search: query.search,
        sort: query.sort || "OPENED_AT_DESC",
      },
    }
  );

  const { edges, pageInfo, totalCount } = data?.cases || {};
  const requests = edges?.map((edge) => edge.node) || [];
  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { requests },
    error,
    loading,
    pageInfo,
    pagination,
  };
};
