import { useNavigate } from "react-router-dom";

import { useAuth } from "@m/login";
import { Spinner } from "@m/ui";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS, ROLES, SKUS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { useEngagementsList } from "../api";

import { EngagementsEmptyPage } from "./EngagementsEmptyPage";
import { EngagementsProjectsPage } from "./EngagementsProjectsPage";

export const EngagementsPage = () => {
  const { user } = useAuth();
  const {
    data: { engagements },
    loading,
  } = useEngagementsList();

  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });

  const hasCloudEngagementsAccess = useAccessRequired({
    entitlement: SKUS.CLOUD_ENGAGEMENTS,
  });

  const navigate = useNavigate();

  const hasEngagements = engagements.length > 0;
  const shouldAllowProjectsRoute =
    hasCloudEngagementsAccess || hasEngagements || isEngagementManager;

  if (loading)
    return (
      <Page title={<PageTitle title="Projects" padding="small" />}>
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      </Page>
    );

  if (!shouldAllowProjectsRoute) {
    navigate(generateCompanyPath(PATHS.ROOT, user.company.slug));
  }

  if (engagements.length < 1) {
    return <EngagementsEmptyPage />;
  }

  return <EngagementsProjectsPage engagements={engagements} />;
};
