import PT from "prop-types";
import { Fragment } from "react";

export const CrosshairLayer = ({ currentSlice }) => {
  if (!currentSlice || currentSlice.points.len < 1) return null;

  return (
    <svg>
      {currentSlice.points.map(({ id, y, color }) => {
        return (
          <Fragment key={id}>
            <circle cx={currentSlice.x} cy={y} r={8} fill="#FFFFFF" />
            <circle cx={currentSlice.x} cy={y} r={4} fill={color} />
          </Fragment>
        );
      })}
    </svg>
  );
};

CrosshairLayer.propTypes = {
  currentSlice: PT.shape({
    points: PT.arrayOf({
      id: PT.string.isRequired,
      y: PT.number.isRequired,
      color: PT.string.isRequired,
    }).isRequired,
    x: PT.number.isRequired,
    y: PT.number.isRequired,
  }),
};
