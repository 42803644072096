import { useEffect, useState } from "react";

import { useModalState } from "@m/ui";

import { Page } from "@mc/components/Page";
import { SettingsPageTitle } from "@mc/features/Settings";

import { useTeamMembers } from "../api";
import { InviteButton, InviteUsersModal, UsersList } from "../components";

export function UserManagementPage() {
  const {
    data: { registeredUsers, unregisteredUsers },
    loading,
    refetch,
  } = useTeamMembers();

  const defaultSort = "ACCOUNT__EMAIL_ASC";
  const [sort, setSort] = useState(defaultSort);

  const inviteModal = useModalState();

  useEffect(() => {
    let variables = {};
    if (sort) variables["sort"] = sort;
    refetch(variables);
  }, [refetch, sort]);

  return (
    <Page data-testid="user-management-page" title={<SettingsPageTitle />}>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-subdued">
            People from your team who have access to Mission Control
          </p>
        </div>
        <InviteButton onClick={inviteModal.open} disabled={loading} />
      </div>

      <div className="mt-3">
        <UsersList
          defaultSort={defaultSort}
          loading={loading}
          onSortChange={setSort}
          users={registeredUsers}
        />
      </div>

      {inviteModal.isOpen && (
        <InviteUsersModal
          open={inviteModal.isOpen}
          onClose={inviteModal.close}
          users={unregisteredUsers}
        />
      )}
    </Page>
  );
}
