import { MissionControlLogin } from "@mc/app/components";

import { authenticatedRoutes } from "./authenticatedRoutes";
import {
  AuthenticatedOutlet,
  SsoOutlet,
  UnauthenticatedOutlet,
} from "./outlets";
import { RootPathRedirect } from "./redirects";
import { signupRoutes } from "./signupRoutes";

export const routes = [
  {
    index: true,
    element: <RootPathRedirect />,
  },
  {
    path: "*",
    element: <AuthenticatedOutlet />,
    children: authenticatedRoutes,
  },
  {
    path: "signup",
    element: <UnauthenticatedOutlet />,
    children: signupRoutes,
  },
  {
    path: "sso/:domain/*",
    element: <SsoOutlet />,
    children: [{ path: "*", element: <MissionControlLogin /> }],
  },
];
