import { ApolloError, BaseMutationOptions, useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  CreateMarketplaceInvitationInput,
  CreateMarketplaceInvitationMutation,
} from "@m/api/public/types";
import { toast } from "@m/ui";

import { SKUS } from "@mc/constants";

export const CREATE_MARKETPLACE_INVITATION = gql(/* GraphQL */ `
  mutation CreateMarketplaceInvitation(
    $input: CreateMarketplaceInvitationInput!
  ) {
    createMarketplaceInvitation(input: $input) {
      message
      ok
    }
  }
`);

export const useCreateMarketplaceInvitation = () => {
  const onCompleted = (
    data: CreateMarketplaceInvitationMutation,
    options: BaseMutationOptions
  ) => {
    const { email, sku } = options.variables.input;
    toast.success(`${email} was invited to ${getProductName(sku)}`);
  };

  const onError = (error: ApolloError, options: BaseMutationOptions) => {
    const { email, sku } = options.variables.input;
    toast.error(`Unable to invite ${email} to ${getProductName(sku)}`);
  };

  const [mutate, result] = useMutation(CREATE_MARKETPLACE_INVITATION, {
    onCompleted,
    onError,
  });

  const createMarketplaceInvitation = (
    input: CreateMarketplaceInvitationInput
  ) => mutate({ variables: { input } });

  return [createMarketplaceInvitation, result] as const;
};

const getProductName = (sku: string) => {
  switch (sku) {
    case SKUS.CLOUD_SCORE:
      return "Cloud Score";
    case SKUS.CLOUD_GATEWAY:
      return "Cloud Gateway";
    case SKUS.CLOUD_ENGAGEMENTS:
      return "Cloud Engagements - DevOps";
    default:
      return "Private Offer";
  }
};
