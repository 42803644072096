import { ProductNode } from "@m/api/public/types";

import { SUPPORTED_PRODUCT_INVITATION_SKUS } from "@mc/constants";

/**
 * Filter eligible products for invitation to only those supported by Mission Control.
 */
export const filterSupportedProductsForInvitation = (
  productsForInvitation: Partial<ProductNode>[]
) =>
  productsForInvitation.filter(({ sku }) =>
    SUPPORTED_PRODUCT_INVITATION_SKUS.includes(sku)
  );
