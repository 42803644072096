import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { SpinnerScreen } from "@m/ui";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { SKUS } from "@mc/constants";
import { usePendingCloudScoreOnboarding } from "@mc/features/Onboarding/hooks";

import { usePollCloudScore } from "../api";
import { CLOUD_SCORE_POLL_INTERVAL } from "../constants";

interface Props extends PropsWithChildren {
  /** Interval used to poll for cloud score availability */
  pollInterval?: number;
}

export const PreparingCloudScoreProvider = ({
  children,
  pollInterval = CLOUD_SCORE_POLL_INTERVAL,
}: Props) => {
  const isCloudScoreUser = useAccessRequired({
    entitlement: SKUS.CLOUD_SCORE,
  });
  const pendingOnboarding = usePendingCloudScoreOnboarding();

  const shouldPoll = isCloudScoreUser === true && pendingOnboarding === null;

  const [isPreparingCloudScore, setIsPreparingCloudScore] =
    useState(shouldPoll);

  const {
    data: { hasCloudScore },
    loading,
    stopPolling,
  } = usePollCloudScore(pollInterval, shouldPoll);

  useEffect(
    function handleStopPolling() {
      if (shouldPoll && hasCloudScore) {
        stopPolling();
        setIsPreparingCloudScore(false);
      }
    },
    [shouldPoll, hasCloudScore, stopPolling]
  );

  return (
    <CloudScoreContext.Provider value={isPreparingCloudScore}>
      {loading ? <SpinnerScreen /> : children}
    </CloudScoreContext.Provider>
  );
};

const CloudScoreContext = createContext<boolean>(false);

export const useIsPreparingCloudScore = () => useContext(CloudScoreContext);
