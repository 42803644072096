import PT from "prop-types";

import { Field, Input } from "@m/ui";
import { toHyphenLowercase } from "@m/utils";

import { InputStatusIcon } from "./InputStatusIcon";

export function TextInput({
  defaultValue,
  fieldLabel,
  loading,
  ok,
  onInputChange,
  disabled,
}) {
  const fieldId = toHyphenLowercase(fieldLabel);

  const handleBlur = (e) => {
    onInputChange(e.target.value);
  };

  return (
    <Field label={fieldLabel} htmlFor={fieldId}>
      <div className="relative">
        <Input
          defaultValue={defaultValue}
          id={fieldId}
          maxLength={99}
          onBlur={handleBlur}
          disabled={disabled}
        />
        <div className="absolute right-2 top-1/4">
          <InputStatusIcon loading={loading} ok={ok} />
        </div>
      </div>
    </Field>
  );
}

TextInput.propTypes = {
  defaultValue: PT.string,
  fieldLabel: PT.string.isRequired,
  loading: PT.bool,
  ok: PT.bool,
  onInputChange: PT.func,
};
