export const getCharsWidth = (text: string): number => {
  const element = document.createElement("span");
  element.style.display = "inline block";
  element.style.fontSize = "large";
  element.style.visibility = "hidden";
  element.innerText = text;

  document.body.appendChild(element);
  const width = element.offsetWidth;
  document.body.removeChild(element);
  return width;
};
