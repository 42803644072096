import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { OnboardingRequestStatus } from "@m/api/public/types";

import { usePendingCloudEngagementsOnboarding } from "@mc/features/Onboarding/hooks";

export const UPDATE_MARKETPLACE_REGISTRATION = gql(/* GraphQL */ `
  mutation UpdateMarketplaceRegistration($input: UpdateRegistrationInput!) {
    updateMarketplaceRegistration(input: $input) {
      ok
      message
    }
  }
`);

export const useCompleteCloudEngagementsOnboarding = (): [
  () => void,
  { loading: boolean }
] => {
  const [mutate, { loading }] = useMutation(UPDATE_MARKETPLACE_REGISTRATION);

  const pendingCloudEngagementsOnboarding =
    usePendingCloudEngagementsOnboarding();
  const { customerIdentifier, marketplaceProductCode } =
    pendingCloudEngagementsOnboarding || {};

  const completeCloudEngagementsOnboarding = () => {
    return mutate({
      variables: {
        input: {
          customerIdentifier,
          productCode: marketplaceProductCode,
          onboardingStatus: OnboardingRequestStatus.Complete,
        },
      },
      refetchQueries: ["GetMarketplaceRegistrations"],
    });
  };

  return [completeCloudEngagementsOnboarding, { loading }];
};
