import { useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";

import { gql } from "@m/api/public";
import { ProductNode } from "@m/api/public/types";
import { SpinnerScreen } from "@m/ui";

import { filterSupportedProductsForInvitation } from "../utils";

interface EligibleProductsForInvitation {
  hasProductsForInvitation: boolean;
  productsForInvitation: Partial<ProductNode>[];
}

export const EligibleProductsForInvitationContext =
  createContext<EligibleProductsForInvitation>({
    hasProductsForInvitation: false,
    productsForInvitation: [],
  });

export const useEligibleProductsForInvitation = () =>
  useContext(EligibleProductsForInvitationContext);

export const ELIGIBLE_PRODUCTS_FOR_INVITATION = gql(/* GraphQL */ `
  query EligibleProductsForInvitation {
    userProfile {
      currentCompany {
        eligibleProductsForInvitations {
          edges {
            node {
              id
              displayName
              sku
            }
          }
        }
      }
    }
  }
`);

export const EligibleProductsForInvitationProvider = ({
  children,
}: PropsWithChildren) => {
  const { data, loading } = useQuery(ELIGIBLE_PRODUCTS_FOR_INVITATION);

  const products = useMemo(() => {
    const eligibleProductsForInvitation =
      data?.userProfile?.currentCompany?.eligibleProductsForInvitations?.edges.map(
        (edge) => edge.node
      ) || [];

    const productsForInvitation = filterSupportedProductsForInvitation(
      eligibleProductsForInvitation
    );

    return {
      hasProductsForInvitation: productsForInvitation.length > 0,
      productsForInvitation,
    };
  }, [data]);

  return (
    <EligibleProductsForInvitationContext.Provider value={products}>
      {loading ? <SpinnerScreen /> : children}
    </EligibleProductsForInvitationContext.Provider>
  );
};
