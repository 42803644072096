export const MISSION_ADMIN_TABS = {
  reports: {
    id: "reports",
    name: "Reports",
    label: "Reports",
  },
  team: {
    id: "team",
    name: "Team",
    label: "My Team",
  },
  git: {
    id: "git",
    name: "Git",
    label: "Git Repositories",
  },
};

export const MISSION_ADMIN_TABS_DATA = Object.values(MISSION_ADMIN_TABS).map(
  (tab) => ({ ...tab })
);
