import { Link } from "react-router-dom";

import { useAuth } from "@m/login";
import { MissionLogo } from "@m/ui";

import { CompanySwitcher } from "@mc/components/CompanySwitcher";
import { FEATURE_FLAGS, PATHS, SKUS } from "@mc/constants";
import { useIsStaff } from "@mc/hooks";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { AccessRequired, useAccessRequired } from "../AccessRequired";

import {
  ScorecardLink,
  SupportDropdown,
  ToolbarLink,
  UserDropdown,
} from "./components";

export const Toolbar = () => {
  const { user } = useAuth();
  return (
    <nav
      className="flex w-full items-center justify-between bg-layout-inverse p-1 text-inverse antialiased"
      data-testid="toolbar"
    >
      <div className="flex items-center gap-5">
        <div className="flex items-center gap-1.5">
          <Link to={generateCompanyPath(PATHS.ROOT, user.company.slug)}>
            <MissionLogo logo="mark" style={{ height: 36 }} color="white" />
          </Link>
          <ToolbarTitle />
        </div>

        <AccessRequired entitlement={SKUS.CLOUD_SCORE}>
          <div className="flex items-center gap-4">
            <AccessRequired feature={FEATURE_FLAGS.SCORECARDS}>
              <ScorecardLink />
            </AccessRequired>
            <ToolbarLink to={PATHS.ACCOUNTS}>Accounts</ToolbarLink>
          </div>
        </AccessRequired>
      </div>

      <div className="flex items-center gap-3">
        <SupportDropdown />
        <UserDropdown />
      </div>
    </nav>
  );
};

const ToolbarTitle = () => {
  const isStaff = useIsStaff();

  const hasCloudEngagements = useAccessRequired({
    entitlement: SKUS.CLOUD_ENGAGEMENTS,
  });
  const hasCloudGateway = useAccessRequired({
    entitlement: SKUS.CLOUD_GATEWAY,
  });

  switch (true) {
    case isStaff:
      return <CompanySwitcher />;
    case hasCloudEngagements:
      return (
        <span className="text-base font-semibold">
          Mission Cloud Engagements - DevOps
        </span>
      );
    case hasCloudGateway:
      return (
        <span className="text-base font-semibold">Mission Cloud Gateway</span>
      );
    default:
      return <CompanySwitcher />;
  }
};
