import PT from "prop-types";
import { useCallback, useContext } from "react";

import { LineGraph, SeriesPropTypes } from "@mc/components/LineGraph";

import { ScorecardTrendContext } from "../contexts";

import { ScorecardTrendActivityPanel } from "./ScorecardTrendActivityPanel";
import { ScorecardTrendTooltip } from "./ScorecardTrendTooltip";

/**
 * Base component to be used by all Cloud Score Trend Graphs.
 */
export const ScorecardTrendGraphBase = ({
  activeTrendLine,
  activities,
  allTrendLines,
  ariaLabel,
  axisBottom,
  emptyMessage,
  loading,
  xScale,
}) => {
  const { className } = useContext(ScorecardTrendContext);

  const sliceTooltip = useCallback(
    ({ slice }) => (
      <ScorecardTrendTooltip
        points={slice.points}
        allTrendLines={allTrendLines}
      />
    ),
    [allTrendLines]
  );

  return (
    <LineGraph
      activities={activities}
      ActivityPanelComponent={ScorecardTrendActivityPanel}
      areaOpacity={0.05}
      ariaLabel={ariaLabel}
      axisBottom={axisBottom}
      axisLeft={{
        tickValues: 5,
      }}
      className={className}
      colors={["#11A1FD"]}
      data={[activeTrendLine]}
      emptyMessage={emptyMessage}
      enableArea={true}
      enableCrosshair={false}
      fill={[{ match: "*", id: "fadeOut" }]}
      gridYValues={[0, 2, 4, 6, 8, 10]}
      loading={loading}
      sliceTooltip={sliceTooltip}
      xScale={xScale}
      yScale={{
        type: "linear",
        stacked: false,
        min: 0,
        max: 10,
      }}
    />
  );
};

ScorecardTrendGraphBase.propTypes = {
  activeTrendLine: SeriesPropTypes.isRequired,
  allTrendLines: PT.arrayOf(SeriesPropTypes).isRequired,
  ariaLabel: PT.string,
  axisBottom: PT.object.isRequired,
  className: PT.string,
  emptyMessage: PT.string,
  loading: PT.bool,
  xScale: PT.object.isRequired,
};
