import clsx from "clsx";
import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { useAuth } from "@m/login";
import { Badge, BadgePropsStrict } from "@m/ui";

import { generateCompanyPath } from "@mc/utils/companyPath";

interface Props {
  badgeLabel?: BadgePropsStrict["label"];
  children: ReactNode;
  className?: string;
  to: string;
}

export const ToolbarLink: FC<Props> = ({
  badgeLabel,
  children,
  className,
  to,
  ...props
}) => {
  const { user } = useAuth();

  return (
    <NavLink
      className={clsx(
        "text-base font-semibold text-inverse antialiased",
        "hover:opacity-[.85]",
        "flex items-center gap-1",
        className
      )}
      to={generateCompanyPath(to, user.company.slug)}
      {...props}
    >
      {children}
      {badgeLabel ? (
        <Badge
          label={badgeLabel}
          size="small"
          status="default"
          strong={false}
        />
      ) : null}
    </NavLink>
  );
};
