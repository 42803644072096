import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { ReactNode } from "react";

import { Card, Spinner } from "@m/ui";
import { fromDollars, toDollars, toPercent } from "@m/utils";

import { useCostSummary } from "../api";

export const CostSummaryWidget = () => {
  const {
    data: { currentMonthCost, projectedMonthCost, previousMonthCost },
    loading,
  } = useCostSummary();

  const currentMonthCostValue = fromDollars(currentMonthCost);
  const projectedMonthCostValue = fromDollars(projectedMonthCost);
  const previousMonthCostValue = fromDollars(previousMonthCost);

  const percentOfProjectedCost = getPercentOfProjectedCost(
    currentMonthCostValue,
    projectedMonthCostValue
  );

  const {
    costDifferenceFromPreviousMonth,
    percentDifferenceFromPreviousMonth,
  } = getDifferenceFromPreviousMonth(
    projectedMonthCostValue,
    previousMonthCostValue
  );

  const formattedPercentOfProjectedCost = toPercent(percentOfProjectedCost);
  const formattedPercentDifferenceFromPreviousMonth = toPercent(
    percentDifferenceFromPreviousMonth
  );
  const formattedCostDifferenceFromPreviousMonth = toDollars(
    costDifferenceFromPreviousMonth
  );

  const isOverPreviousMonth = costDifferenceFromPreviousMonth > 0;
  const isUnderPreviousMonth = costDifferenceFromPreviousMonth < 0;
  const compareToPreviousMonth = isOverPreviousMonth || isUnderPreviousMonth;

  return (
    <div className="flex flex-col gap-2" data-testid="Cost Summary">
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
        <CostSummaryCard
          title="Current Month Cost"
          value={currentMonthCostValue}
          subtitle={
            <span className="flex items-center gap-0.5">
              <span className="text-blue-500">
                {formattedPercentOfProjectedCost}
              </span>{" "}
              of projected cost
            </span>
          }
          loading={loading}
        />
        <CostSummaryCard
          title="Projected Month Cost"
          value={projectedMonthCostValue}
          subtitle={
            compareToPreviousMonth && (
              <span className="flex flex-wrap items-center justify-center gap-0.5">
                {isOverPreviousMonth && (
                  <ArrowUpIcon className="inline-flex h-2 w-2 text-status-error" />
                )}
                {isUnderPreviousMonth && (
                  <ArrowDownIcon className="inline-flex h-2 w-2 text-status-good" />
                )}
                <span
                  className={clsx({
                    "text-status-error": isOverPreviousMonth,
                    "text-status-good": isUnderPreviousMonth,
                  })}
                >
                  {formattedCostDifferenceFromPreviousMonth} (
                  {formattedPercentDifferenceFromPreviousMonth})
                </span>{" "}
                {isOverPreviousMonth && "over "}
                {isUnderPreviousMonth && "under "}
                previous month
              </span>
            )
          }
          loading={loading}
        />
        <CostSummaryCard
          title="Previous Month Cost"
          value={previousMonthCostValue}
          loading={loading}
        />
      </div>
    </div>
  );
};

interface CardProps {
  title: string;
  value: number;
  subtitle?: ReactNode;
  loading: boolean;
}

const CostSummaryCard = ({
  title,
  value,
  subtitle = null,
  loading,
}: CardProps) => {
  const available = value >= 0;
  const formattedValue = toDollars(value);

  return (
    <Card className="flex min-h-[140px] w-full flex-col items-center justify-center gap-1 p-3 text-center">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h3 className="text-xs font-semibold uppercase text-subdued">
            {title}
            {!available && " Not Available"}
          </h3>
          {available && (
            <p className="text-2xl font-semibold text-default">
              {formattedValue}
            </p>
          )}
          {available && subtitle && (
            <p className="whitespace-nowrap text-sm font-semibold text-subdued">
              {subtitle}
            </p>
          )}
        </>
      )}
    </Card>
  );
};

const getPercentOfProjectedCost = (
  currentMonthCost: number,
  projectedMonthCost: number
) => {
  return currentMonthCost / projectedMonthCost;
};

const getDifferenceFromPreviousMonth = (
  projectedMonthCost: number,
  previousMonthCost: number
) => {
  const costDifferenceFromPreviousMonth =
    projectedMonthCost - previousMonthCost;
  const percentDifferenceFromPreviousMonth =
    costDifferenceFromPreviousMonth / previousMonthCost;

  return {
    costDifferenceFromPreviousMonth,
    percentDifferenceFromPreviousMonth,
  };
};
