import { RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";
import { AccountsPage, AddAccountPage } from "@mc/features/Accounts/pages";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

export const accountsRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.ACCOUNTS_AWS} />,
  },
  {
    path: "aws",
    children: [
      {
        index: true,
        element: <AccountsPage />,
      },
      {
        path: "add",
        element: (
          <AccessRequired
            fallback={<Navigate replace to={PATHS.ACCOUNTS_AWS} />}
            feature={FEATURE_FLAGS.ACCOUNTS_ADD_AWS_ACCOUNT}
            mutation={MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST}
          >
            <AddAccountPage />,
          </AccessRequired>
        ),
      },
      {
        path: "connect/:id",
        element: (
          <AccessRequired
            fallback={<Navigate replace to={PATHS.ACCOUNTS_AWS} />}
            feature={FEATURE_FLAGS.ACCOUNTS_CONNECT_AWS_ACCOUNT}
            mutation={MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST}
          >
            <AddAccountPage />,
          </AccessRequired>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
