import {
  ArrowUpRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useEntitlements } from "@m/api";
import { Button, Card, Field, Input, Spinner } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import {
  CLOUD_FORMATION_IAM_ROLES,
  CLOUD_FORMATION_MAX_ATTEMPTS,
  CLOUD_FORMATION_POLL_INTERVAL,
  PATHS,
  SKUS,
  SUPPORTED_ONBOARDING_SKUS,
} from "@mc/constants";
import {
  AwsConsoleSigninLink,
  AwsManagementConsoleInfo,
} from "@mc/features/Onboarding/components";
import { GreenCheckIcon } from "@mc/features/Onboarding/icons";
import { Navigate } from "@mc/router";
import { getCloudFormationTemplateURL } from "@mc/utils/cloudFormation";

import {
  useCanRegisterAwsAccountId,
  useCreateAwsAccountOnboardingRequest,
  useCreateMarketplaceAccountOnboardingRequest,
  useIamExternalId,
  useValidateBootstrapRoleDeployed,
  useValidateTrustedAdvisorAccess,
} from "../api";
import { OnboardingRequestError } from "../components";
import { ACCOUNTS_BREADCRUMBS } from "../constants";
import { TEMPLATE_VERIFICATION_ERRORS } from "../types";

interface Props {
  /** Max number of validation attempts for IAM roles before timing out */
  maxAttempts?: number;

  /** Poll interval for validating IAM roles have been deployed  */
  pollInterval?: number;
}

export const AddAccountPage = ({
  maxAttempts = CLOUD_FORMATION_MAX_ATTEMPTS,
  pollInterval = CLOUD_FORMATION_POLL_INTERVAL,
}: Props) => {
  const { id: idParam = "" } = useParams<{ id?: string }>();
  const isKnownAccount = idParam !== "";

  const entitlements = useEntitlements();
  const sku = entitlements?.[0] || null; // todo(djbowers): make selectable to support multiple marketplace offerings
  const roleName = getRoleName(sku);
  const requestedIamRoles = [roleName];

  // template state
  const [isTemplateRunning, setIsTemplateRunning] = useState(false);
  const [bootstrapValidationComplete, setBootstrapValidationComplete] =
    useState(false);
  const [
    trustedAdvisorValidationComplete,
    setTrustedAdvisorValidationComplete,
  ] = useState(false);
  const [validationAttempts, setValidationAttempts] = useState(0);

  // form data
  const [awsAccountId, setAwsAccountId] = useState(idParam);
  const [awsAccountName, setAwsAccountName] = useState("");

  // error states
  const [accountIdError, setAccountIdError] = useState(null);
  const [businessSupportError, setBusinessSupportError] = useState(null);

  const [validateAwsAccountId, { data: canRegisterAwsAccount }] =
    useCanRegisterAwsAccountId();

  const { data: iamExternalId, loading: loadingIamExternalId } =
    useIamExternalId();

  const [
    validateBootstrapRoleDeployed,
    {
      data: bootstrapRoleValidated,
      error: bootstrapRoleErrorCode,
      isPolling: isPollingBootstrapRole,
      loading: bootstrapRoleLoading,
      stopPolling: stopPollingBootstrapRole,
    },
  ] = useValidateBootstrapRoleDeployed(
    awsAccountId,
    roleName,
    iamExternalId,
    pollInterval
  );

  const [
    validateTrustedAdvisorAccess,
    { data: trustedAdvisorAccessValidated, error: trustedAdvisorAccessError },
  ] = useValidateTrustedAdvisorAccess(awsAccountId, iamExternalId);

  const [
    createAwsAccountOnboardingRequest,
    {
      data: awsAccountOnboardingRequestCreated,
      error: awsAccountOnboardingRequestError,
    },
  ] = useCreateAwsAccountOnboardingRequest(
    awsAccountId,
    requestedIamRoles,
    awsAccountName
  );

  const [
    createMarketplaceAccountOnboardingRequest,
    {
      data: marketplaceAccountOnboardingRequestCreated,
      error: marketplaceAccountOnboardingRequestError,
    },
  ] = useCreateMarketplaceAccountOnboardingRequest(
    awsAccountId,
    requestedIamRoles,
    awsAccountName
  );

  const createOnboardingRequest = isMarketplaceOffering(sku)
    ? createMarketplaceAccountOnboardingRequest
    : createAwsAccountOnboardingRequest;

  const isAccountIdValid =
    awsAccountId.length === 12 && (canRegisterAwsAccount || isKnownAccount);
  const isNameLongEnough = awsAccountName.length >= 3;

  const runTemplateDisabled =
    loadingIamExternalId ||
    isTemplateRunning ||
    !isAccountIdValid ||
    !isNameLongEnough;

  const bootstrapRolePollingTimedOut =
    validationAttempts === maxAttempts &&
    !bootstrapRoleLoading &&
    !bootstrapRoleValidated;

  const trustedAdvisorAccessRequired = sku === SKUS.CLOUD_SCORE;

  const errorMessage = useMemo(() => {
    if (bootstrapRoleErrorCode === TEMPLATE_VERIFICATION_ERRORS.NOT_FOUND)
      return "CloudFormation template was not detected in the account";

    if (bootstrapRoleErrorCode === TEMPLATE_VERIFICATION_ERRORS.BAD_USER_INPUT)
      return "Invalid account ID";

    if (
      bootstrapRoleErrorCode ===
      TEMPLATE_VERIFICATION_ERRORS.INTERNAL_SERVER_ERROR
    )
      return "Something went wrong. Please try again";

    return "Could not detect access to this account";
  }, [bootstrapRoleErrorCode]);

  const handleChangeAccountId: InputHandler = (e) => {
    const id = e.target.value;
    setAccountIdError(null);

    if (!isNaN(Number(id))) {
      setAwsAccountId(id);
      if (id.length === 12) validateAwsAccountId(id);
    }
  };

  const handleOpenTemplate = () =>
    openTemplateUrl(sku, isKnownAccount, iamExternalId);

  const handleRunCloudFormationTemplate = () => {
    setValidationAttempts(0);
    setIsTemplateRunning(true);
    setBusinessSupportError(false);
    handleOpenTemplate();
    validateBootstrapRoleDeployed();
  };

  const handleRerunCloudFormationTemplate = () => {
    setValidationAttempts(0);
    handleOpenTemplate();
  };

  useEffect(
    function handleCanNotRegisterAccountId() {
      if (canRegisterAwsAccount === false)
        setAccountIdError("Unable to register this AWS account.");
    },
    [canRegisterAwsAccount]
  );

  useEffect(
    function handleBootstrapRolePollingTimedOut() {
      if (bootstrapRolePollingTimedOut) {
        stopPollingBootstrapRole();
        setIsTemplateRunning(false);
      }
    },
    [bootstrapRolePollingTimedOut, stopPollingBootstrapRole]
  );

  useEffect(
    function handleIncrementValidationAttempts() {
      if (isPollingBootstrapRole) {
        setValidationAttempts((prev) => prev + 1);
      }
    },
    [isPollingBootstrapRole]
  );

  useEffect(
    function handleBootstrapRoleValidated() {
      if (bootstrapRoleValidated && !bootstrapValidationComplete) {
        stopPollingBootstrapRole();
        setBootstrapValidationComplete(true);
        if (trustedAdvisorAccessRequired) {
          validateTrustedAdvisorAccess();
        } else {
          createOnboardingRequest();
        }
      }
    },
    [
      bootstrapRoleValidated,
      bootstrapValidationComplete,
      createOnboardingRequest,
      stopPollingBootstrapRole,
      trustedAdvisorAccessRequired,
      validateTrustedAdvisorAccess,
    ]
  );

  useEffect(
    function handleTrustedAdvisorAccessValidated() {
      if (trustedAdvisorAccessValidated && !trustedAdvisorValidationComplete) {
        setTrustedAdvisorValidationComplete(true);
        createOnboardingRequest();
      }
    },
    [
      trustedAdvisorAccessValidated,
      trustedAdvisorValidationComplete,
      createOnboardingRequest,
    ]
  );

  useEffect(
    function handleTrustedAdvisorAccessError() {
      if (trustedAdvisorAccessError) {
        setIsTemplateRunning(false);
        setBusinessSupportError(true);
      }
    },
    [trustedAdvisorAccessError]
  );

  const onboardingRequestCreated =
    awsAccountOnboardingRequestCreated ||
    marketplaceAccountOnboardingRequestCreated;

  const onboardingRequestError =
    awsAccountOnboardingRequestError ||
    marketplaceAccountOnboardingRequestError;

  if (onboardingRequestCreated) return <Navigate to={PATHS.ACCOUNTS} />;

  return (
    <Page
      className="flex flex-col gap-2"
      data-testid="add-account-page"
      title={<PageTitle breadcrumbs={ACCOUNTS_BREADCRUMBS} padding="small" />}
      width="small"
    >
      {onboardingRequestError && <OnboardingRequestError />}

      {!onboardingRequestError && (
        <>
          <div className="text-xl font-semibold text-default">
            {isKnownAccount ? "Connect" : "Add New"} AWS Account
          </div>

          <Card className="flex flex-col gap-5 p-2">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-3">
                <Field
                  label="Account ID"
                  htmlFor="account-id"
                  error={accountIdError}
                >
                  <Input
                    autoComplete="off"
                    className="text-sm"
                    disabled={isKnownAccount}
                    id="account-id"
                    maxLength={12}
                    minLength={12}
                    name="accountId"
                    onChange={handleChangeAccountId}
                    pattern="^\d{12}$"
                    required
                    rightIcon={isAccountIdValid && GreenCheckIcon}
                    title="Enter a valid AWS account ID."
                    type="text"
                    value={awsAccountId}
                  />
                  {isAccountIdValid && (
                    <div className="mt-1 flex items-center gap-1">
                      <div>
                        <ExclamationTriangleIcon className="h-2.5 w-2.5 text-status-warning" />
                      </div>
                      <div className="text-sm font-medium text-default">
                        <AwsConsoleSigninLink awsAccountId={awsAccountId} />
                      </div>
                    </div>
                  )}
                </Field>
                <Field label="Account Name" htmlFor="account-name">
                  <Input
                    className="text-sm"
                    id="account-name"
                    name="accountName"
                    onChange={(e) => setAwsAccountName(e.target.value)}
                    required
                    type="text"
                    value={awsAccountName}
                  />
                </Field>
              </div>

              <div className="flex flex-col gap-2">
                <div className="text-base font-semibold text-default">
                  Run the CloudFormation Template below to deploy our necessary
                  IAM roles. These roles allow Mission Cloud the following
                  access:
                </div>
                <div className="flex items-center gap-1">
                  <GreenCheckIcon />
                  <div className="text-sm font-medium text-default">
                    Data from Trusted Advisor and other AWS Support APIs to
                    analyze best practices
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <GreenCheckIcon />
                  <div className="text-sm font-medium text-default">
                    Resource data to provide an accurate resource inventory list
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center gap-2">
                <Button
                  disabled={runTemplateDisabled}
                  kind="primary"
                  leftIcon={isTemplateRunning && Spinner}
                  onClick={handleRunCloudFormationTemplate}
                  rightIcon={!isTemplateRunning && ArrowUpRightIcon}
                  size="large"
                >
                  {isTemplateRunning ? (
                    <>Verifying Access</>
                  ) : (
                    <>Run CloudFormation Template</>
                  )}
                </Button>
                {isTemplateRunning && (
                  <Button
                    fill="none"
                    kind="primary"
                    onClick={handleRerunCloudFormationTemplate}
                  >
                    Rerun CloudFormation Template
                  </Button>
                )}
                {bootstrapRolePollingTimedOut && (
                  <div className="flex items-center gap-1">
                    <ExclamationTriangleIcon className="h-2.5 w-2.5 text-status-warning" />
                    <div className="text-sm font-bold text-default">
                      {errorMessage}
                    </div>
                  </div>
                )}
                {businessSupportError && (
                  <div className="flex items-center gap-1">
                    <ExclamationTriangleIcon className="h-2.5 w-2.5 text-status-warning" />
                    <div className="text-sm font-bold text-default">
                      AWS Business Support or higher is required
                    </div>
                  </div>
                )}
              </div>
            </div>

            {!isTemplateRunning && <AwsManagementConsoleInfo />}
          </Card>
        </>
      )}
    </Page>
  );
};

const isMarketplaceOffering = (sku: string) =>
  SUPPORTED_ONBOARDING_SKUS.includes(sku);

const getRoleName = (sku: string) => {
  switch (sku) {
    case SKUS.CLOUD_SCORE:
      return CLOUD_FORMATION_IAM_ROLES.MISSION_CLOUD_SCORE;
    case SKUS.CLOUD_GATEWAY:
      return CLOUD_FORMATION_IAM_ROLES.MISSION_INVENTORY;
    default:
      return CLOUD_FORMATION_IAM_ROLES.MISSION_ENGINEERING;
  }
};

const openTemplateUrl = (
  sku: string,
  isKnownAccount: boolean,
  iamExternalId?: string
) => {
  const url = getCloudFormationTemplateURL(sku, {
    iamExternalId,
    isLinkedAccount: isKnownAccount,
  });

  window.open(url, "_blank");
};

type InputHandler = ChangeEventHandler<HTMLInputElement>;
