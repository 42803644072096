import { FormEventHandler } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Field, Fields, Input } from "@m/ui";

import { PATHS } from "@mc/constants";
import { WORK_EMAIL_PATTERN } from "@mc/utils/emails";

interface CreateAccountFormProps {
  loading: boolean;
  onSubmit: (elements: CreateAccountFormElements) => void;
}

export const CreateAccountForm = ({
  loading = false,
  onSubmit,
}: CreateAccountFormProps) => {
  const navigate = useNavigate();

  const handleSignIn = () => navigate(PATHS.ROOT);

  const handleSubmit: FormEventHandler<CreateAccountFormElement> = (e) => {
    e.preventDefault();
    onSubmit(e.currentTarget.elements);
  };

  return (
    <div className="flex flex-col items-center gap-y-5">
      <form
        className="flex flex-col items-center gap-y-5"
        data-testid="create-account-form"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-4xl font-bold">
            Create your Mission Control account
          </h1>
          <p className="text-xl text-subdued">
            Please provide the details below to get started
          </p>
        </div>

        <Fields className="w-full max-w-md">
          <Field label="First Name" htmlFor="first-name">
            <Input
              autoFocus={true}
              id="first-name"
              name="firstNameInput"
              pattern="[a-z A-Z]+"
              required={true}
              title="First Name can not contain numbers or symbols."
            />
          </Field>
          <Field label="Last Name" htmlFor="last-name">
            <Input
              id="last-name"
              name="lastNameInput"
              pattern="[a-z A-Z]+"
              required={true}
              title="Last Name can not contain numbers or symbols."
            />
          </Field>
          <Field label="Work Email" htmlFor="work-email">
            <Input
              id="work-email"
              name="workEmailInput"
              pattern={WORK_EMAIL_PATTERN}
              required={true}
              title="Please enter a valid company email address."
              type="email"
            />
          </Field>
        </Fields>

        <Button
          className="w-full max-w-md"
          kind="primary"
          loading={loading}
          size="large"
          type="submit"
        >
          Create your account
        </Button>
      </form>

      <div className="flex w-full flex-col items-center gap-y-2">
        <div className="text-sm font-medium text-subdued">
          Already have an account?
        </div>
        <Button className="w-full max-w-md" onClick={handleSignIn}>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export interface CreateAccountFormElements extends HTMLFormControlsCollection {
  firstNameInput: HTMLInputElement;
  lastNameInput: HTMLInputElement;
  workEmailInput: HTMLInputElement;
}

interface CreateAccountFormElement extends HTMLFormElement {
  readonly elements: CreateAccountFormElements;
}
