import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  CaseDetailsPage,
  CaseListPage,
  CreateCasePage,
} from "@mc/features/CustomerCases";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

export const supportRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.SUPPORT_CASES} />,
  },
  {
    path: "cases",
    element: <CaseListPage />,
  },
  {
    path: "cases/create",
    element: <CreateCasePage />,
  },
  {
    path: "cases/create/:caseType",
    element: <CreateCasePage />,
  },
  {
    path: "cases/:sysId",
    element: <CaseDetailsPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
