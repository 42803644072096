import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";

import { UpdatePaymentMethodInput } from "@m/api/public/types";
import { DropdownItem } from "@m/ui";

/*
 * This component is adapted (mostly copied) from an open source
 * library implementation:
 *
 * https://github.com/brendanbond/react-acceptjs/blob/main/src/HostedForm/HostedForm.tsx
 *
 * We decided to pull this out into our own component, because:
 *
 * - only a small fraction of that library is what we actually need
 * - it's a fairly simple component, and another dependency we don't have to
 *   keep up-to-date
 * - by writing this ourselves, we don't have to worry about any supply chain
 *   security issues (i.e., what if this npm package gets compromised and
 *   replaced with something that compromises our card capture process)
 */

interface Props {
  apiLoginID: string;
  clientKey: string;
  onSubmit: (input: UpdatePaymentMethodInput) => void;
  environment: string;
}

export const UpdatePaymentMethod = ({
  apiLoginID,
  clientKey,
  onSubmit,
  environment = "SANDBOX",
}: Props) => {
  const scriptUrl =
    environment === "PRODUCTION"
      ? "https://js.authorize.net/v3/AcceptUI.js"
      : "https://jstest.authorize.net/v3/AcceptUI.js";
  const [errors, setErrors] = useState(null);

  window["authorizeNetResponseHandler"] = useCallback(
    (response) => {
      if (response.messages.resultCode === "Error") {
        setErrors(response.messages.message);
      } else {
        onSubmit(response);
      }
    },
    [onSubmit]
  );

  const label = "Update Payment Method";

  return (
    <div>
      <Helmet>
        <script defer src={scriptUrl} />
      </Helmet>
      <DropdownItem
        className="AcceptUI"
        data-apiloginid={apiLoginID}
        data-clientkey={clientKey}
        data-billingaddressoptions={JSON.stringify({
          show: true,
          required: true,
        })}
        data-acceptuiformbtntxt="Save"
        data-acceptuiformheadertxt={label}
        data-paymentoptions={JSON.stringify({ showCreditCard: true })}
        data-responsehandler="authorizeNetResponseHandler"
      >
        {label}
      </DropdownItem>

      {errors && (
        <div>
          {typeof errors === "string"
            ? errors
            : errors.map((error, index) => (
                <div key={`error-${index}`}>
                  `Error (${error.code}): ${error.text})`
                </div>
              ))}
        </div>
      )}
    </div>
  );
};
