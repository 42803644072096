import { MarketplaceRegistrationStatus } from "@m/api/v4/types";

import { SubscriptionDetailsCard } from "@mc/features/MarketplaceSubscriptions/components";

import { useMarketplaceRegistrations } from "../providers";

export const MarketplaceSubscriptionDetails = () => {
  const { pendingOnboardings } = useMarketplaceRegistrations();

  // For now, there should only be one active subscription. This may change at some point but is the case for the foreseeable future
  const pendingOnboarding = pendingOnboardings.filter(
    (onboarding) =>
      onboarding.registrationStatus === MarketplaceRegistrationStatus.Active
  )?.[0];

  if (!pendingOnboarding) return null;

  return (
    <SubscriptionDetailsCard
      expiresAt={pendingOnboarding.expirationTime}
      productDisplayName={pendingOnboarding.catalogItem.displayName}
      registrationStatus={pendingOnboarding.registrationStatus}
      subscribedAccountId={pendingOnboarding.awsAccountId}
      subscribedAt={pendingOnboarding.subscribeTime}
    />
  );
};
