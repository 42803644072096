import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { MESSAGES_COST_SUMMARY } from "../constants";

export const COST_SUMMARY_QUERY = gql(/* GraphQL */ `
  query CloudHealthCostSummary {
    cloudhealthCostSummary(first: 1) {
      edges {
        node {
          id
          monthToDateCost
          previousMonthCost
          projectedMonthCost
        }
      }
    }
  }
`);

export const useCostSummary = () => {
  const onError = () => toast.error(MESSAGES_COST_SUMMARY.COST_SUMMARY_ERROR);

  const { data, ...result } = useQuery(COST_SUMMARY_QUERY, { onError });

  const cloudHealthCostSummary = data?.cloudhealthCostSummary?.edges?.map(
    (edge) => edge.node
  )?.[0];

  return {
    data: {
      currentMonthCost: cloudHealthCostSummary?.monthToDateCost,
      previousMonthCost: cloudHealthCostSummary?.previousMonthCost,
      projectedMonthCost: cloudHealthCostSummary?.projectedMonthCost,
    },
    ...result,
  };
};
