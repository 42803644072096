import { useAccessRequired } from "@mc/components/AccessRequired";
import { PATHS, ROLES } from "@mc/constants";
import { DevOpsDeskPopup } from "@mc/features/DevOpsDesk/components";
import { useEngagementsList } from "@mc/features/Engagements/api";

import { SidebarGroup } from "./SidebarGroup";
import { SidebarLink } from "./SidebarLink";

export const EngagementsGroup = ({
  isDevOpsDeskEnabled,
  isEngagementsEnabled,
}: {
  isDevOpsDeskEnabled: boolean;
  isEngagementsEnabled: boolean;
}) => {
  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });

  const {
    data: { engagements },
    loading,
  } = useEngagementsList();

  const hasEngagements = engagements.length > 0;

  const shouldShowProjectsLink =
    isEngagementsEnabled && (hasEngagements || isEngagementManager);

  return shouldShowProjectsLink || isDevOpsDeskEnabled ? (
    <SidebarGroup title="Engagements">
      {shouldShowProjectsLink ? (
        <SidebarLink
          count={hasEngagements ? engagements.length : undefined}
          loading={loading}
          title="Projects"
          to={PATHS.ENGAGEMENTS_PROJECTS}
        />
      ) : null}
      {isDevOpsDeskEnabled ? (
        <SidebarLink
          className="w-full"
          title="Engineer Assist"
          to={PATHS.ENGAGEMENTS_ENGINEER_ASSIST}
          isNew={true}
          popupContent={<DevOpsDeskPopup />}
        />
      ) : null}
    </SidebarGroup>
  ) : null;
};
