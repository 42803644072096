import { RouteObject } from "react-router-dom";

import { Logout } from "@m/login";

import { RegistrationPage } from "@mc/features/Register";
import { AuthenticatedProviders } from "@mc/providers";

import { defaultRoutes } from "./defaultRoutes";
import { onboardingRoutes } from "./onboardingRoutes";
import { DefaultOutlet, OnboardingOutlet, RegistrationOutlet } from "./outlets";

export const authenticatedRoutes: RouteObject[] = [
  {
    path: ":companyId",
    element: <AuthenticatedProviders />,
    children: [
      {
        path: "*",
        element: <DefaultOutlet />,
        children: defaultRoutes,
      },
      {
        path: "onboarding/*",
        element: <OnboardingOutlet />,
        children: onboardingRoutes,
      },
      {
        path: "register",
        element: <RegistrationOutlet />,
        children: [{ path: "*", element: <RegistrationPage /> }],
      },
      { path: "logout", element: <Logout /> },
    ],
  },
];
