import {
  Account,
  ActivityStreamOperation,
  Engagement,
  EngagementBudgetSpentActivityStream,
  EngagementBudgetStatus,
  EngagementBudgetStatusChangeActivityStream,
  EngagementStartActivityStream,
  EngagementWorkStatusChangeActivityStream,
  EngagementWorkStatusDueDateActivityStream,
  EngagementWorkStatusProgressActivityStream,
  EngagementWorkStatusStartDateActivityStream,
} from "@m/api/public/types";
import { dt } from "@m/utils";

import { rightNow } from "@mc/features/ActivityFeed/utils";
import {
  ENGAGEMENTS_BUDGET_STATUS,
  ENGAGEMENTS_WORK_STATUS,
} from "@mc/features/Engagements/constants";
import { EXAMPLE_ENGAGEMENT } from "@mc/features/Engagements/examples";
import { generateMockId } from "@mc/testing";

export class ExampleEngagementBudgetStatusChangeActivity
  implements EngagementBudgetStatusChangeActivityStream
{
  id: string;
  __typename?: "EngagementBudgetStatusChangeActivityStream";
  actor?: Account;
  after?: string;
  before?: string;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = null,
    after = ENGAGEMENTS_BUDGET_STATUS.UNDER_PLANNED_COST,
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementBudgetStatusChangeActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
export class ExampleEngagementBudgetSpentActivity
  implements EngagementBudgetSpentActivityStream
{
  id: string;
  __typename?: "EngagementBudgetSpentActivityStream";
  actor?: Account;
  after?: number;
  before?: number;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = 50,
    after = 90,
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementBudgetSpentActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
export class ExampleEngagementWorkStatusChangeActivity
  implements EngagementWorkStatusChangeActivityStream
{
  id: string;
  __typename?: "EngagementWorkStatusChangeActivityStream";
  actor?: Account;
  after?: string;
  before?: string;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = null,
    after = ENGAGEMENTS_WORK_STATUS.AHEAD_OF_SCHEDULE,
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementWorkStatusChangeActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
export class ExampleEngagementWorkStatusStartDateActivity
  implements EngagementWorkStatusStartDateActivityStream
{
  id: string;
  __typename?: "EngagementWorkStatusStartDateActivityStream";
  actor?: Account;
  after?: string;
  before?: string;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = "",
    after = dt.now().toISO(),
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementWorkStatusStartDateActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
export class ExampleEngagementWorkStatusDueDateActivity
  implements EngagementWorkStatusDueDateActivityStream
{
  id: string;
  __typename?: "EngagementWorkStatusDueDateActivityStream";
  actor?: Account;
  after?: string;
  before?: string;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = "",
    after = dt.now().plus({ days: 20 }).toISO(),
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementWorkStatusDueDateActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
export class ExampleEngagementWorkStatusProgressActivity
  implements EngagementWorkStatusProgressActivityStream
{
  id: string;
  __typename?: "EngagementWorkStatusProgressActivityStream";
  actor?: Account;
  after?: number;
  before?: number;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = 0,
    after = 40,
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementWorkStatusProgressActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
export class ExampleEngagementStartActivity
  implements EngagementStartActivityStream
{
  id: string;
  __typename?: "EngagementStartActivityStream";
  actor?: Account;
  after?: string;
  before?: string;
  budgetStatus?: EngagementBudgetStatus;
  changes?: any;
  engagement?: Engagement;
  operation: ActivityStreamOperation;
  timestamp: any;
  type?: string;

  constructor({
    before = null,
    after = EXAMPLE_ENGAGEMENT.title,
    operation = ActivityStreamOperation.Update,
    timestamp = rightNow(),
    id = generateMockId(),
  }) {
    this.__typename = "EngagementStartActivityStream";
    this.id = id;
    this.before = before;
    this.after = after;
    this.operation = operation;
    this.timestamp = timestamp;
  }
}
