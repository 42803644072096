import { RouteObject } from "react-router-dom";

import { OnboardingWelcomePage } from "@mc/features/Onboarding";

import { CloudEngagementsOnboardingPathRedirect } from "./redirects";

export const onboardingCloudEngagementsRoutes: RouteObject[] = [
  {
    path: "start",
    element: <OnboardingWelcomePage />,
  },
  {
    path: "*",
    element: <CloudEngagementsOnboardingPathRedirect />,
  },
];
