import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS } from "../constants";

export const GET_ENGAGEMENT_DETAILS = gql(/* GraphQL */ `
  query EngagementDetails($id: ID!) {
    engagement(id: $id) {
      __typename
      id
      sourceId
      title
      source {
        status
        title
      }
      visible
      engagementType
    }
  }
`);

export const useEngagementDetails = (id: string) => {
  const onError = () => toast.error(MESSAGES_ENGAGEMENTS.GET_ENGAGEMENT_ERROR);

  const { data, loading, error } = useQuery(GET_ENGAGEMENT_DETAILS, {
    variables: {
      id,
    },
    onError,
  });

  const engagementDetails = data?.engagement;

  return { data: { engagementDetails }, loading, error };
};
