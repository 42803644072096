import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { Link, Tooltip } from "@m/ui";

import { PATHS } from "@mc/constants";

import { CONFIGURATION_TOOLTIP_WARNING_MESSAGE } from "../constants";

export const ScorecardConfigurationsTooltip = () => (
  <Tooltip
    side="top"
    align="end"
    asChild={true}
    color="light"
    content={
      <div className="w-[355px]">
        <div className="mt-0.5 text-left text-xs font-light text-default">
          {CONFIGURATION_TOOLTIP_WARNING_MESSAGE}
        </div>
        <div className="mt-1">
          <Link to={PATHS.ACCOUNTS_AWS} rightIcon={ArrowRightIcon}>
            Go to Accounts
          </Link>
        </div>
      </div>
    }
    className="bg-white shadow-lg"
  >
    <div className="flex justify-center">
      <ExclamationTriangleIcon
        className="mr-1 h-2.5 w-2.5 text-amber-500"
        aria-label="Account Warning"
      />
      <div className="mt-[2px] text-xs font-light text-subdued">
        Unavailable
      </div>
    </div>
  </Tooltip>
);
