import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { SKUS } from "@mc/constants";

interface ISidebarContext {
  closeSidebar: () => void;
  isSidebarHidden: boolean;
  open: boolean;
  openSidebar: () => void;
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<ISidebarContext>({
  closeSidebar: () => {},
  isSidebarHidden: false,
  open: false,
  openSidebar: () => {},
  toggleSidebar: () => {},
});

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const hasCloudScore = useAccessRequired({ entitlement: SKUS.CLOUD_SCORE });
  const hasCloudEngagements = useAccessRequired({
    entitlement: SKUS.CLOUD_ENGAGEMENTS,
  });

  // don't render the sidebar until we've confirmed that the user is not restricted
  // to a limited view of mission control based on marketplace entitlements
  const isSidebarHidden = hasCloudScore || hasCloudEngagements;

  const [navOpen, setNavOpen] = useState(false);

  const sidebarContext = useMemo(
    () => ({
      closeSidebar: () => setNavOpen(false),
      isSidebarHidden,
      open: navOpen,
      openSidebar: () => setNavOpen(true),
      toggleSidebar: () => setNavOpen(!open),
    }),
    [isSidebarHidden, navOpen]
  );

  return (
    <SidebarContext.Provider value={sidebarContext}>
      {children}
    </SidebarContext.Provider>
  );
};
