import PT from "prop-types";
import { useContext } from "react";

import { dt } from "@m/utils";

import { ActivityContext } from "../contexts";
import { SeriesPropTypes } from "../propTypes";

import { ActivityLine } from "./ActivityLine";

export const ActivityLayer = ({ height, margin, series }) => {
  const { activities } = useContext(ActivityContext);

  const firstSeries = series[0];
  const points = firstSeries.data;
  const lines = [];

  // iterate through points to map activities to x values based on timestamp
  points.forEach((point) => {
    const pointISODate = dt.fromISO(point.data.x.toISOString()).toISODate();

    // iterate through activities to find those that match the timestamp of the current point
    activities.forEach((activity, index) => {
      const activityISODate = dt.fromISO(activity.timestamp).toISODate();

      // found a matching activity, use x value of point to plot activity line
      if (activityISODate === pointISODate) {
        const { x } = point.position;
        const verticalMargin = margin.top + margin.bottom;
        const y = height - verticalMargin;

        lines.push(
          <ActivityLine
            key={`activity-${activityISODate}`}
            activity={activity}
            index={index}
            x={x}
            y={y}
          />
        );
      }
    });
  });

  return lines;
};

ActivityLayer.propTypes = {
  height: PT.number.isRequired,
  margin: PT.shape({ top: PT.number, bottom: PT.number }).isRequired,
  series: PT.arrayOf(PT.shape(SeriesPropTypes)).isRequired,
};
