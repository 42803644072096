import { SKUS } from "@mc/constants";

import { useMarketplaceRegistrations } from "../providers";
import { findOnboarding } from "../utils";

/** Returns the first pending Cloud Gateway onboarding found. */
export const usePendingCloudGatewayOnboarding = () => {
  const { pendingOnboardings } = useMarketplaceRegistrations();
  return findOnboarding(pendingOnboardings, SKUS.CLOUD_GATEWAY);
};
