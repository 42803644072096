import { gql } from "@m/api/v4";
import { PhotoOptOutQuery } from "@m/api/v4/types";
import { useAuth } from "@m/login";
import { toast } from "@m/ui";

import { MESSAGES_USER_PROFILE } from "@mc/features/UserProfile/constants";
import { useQuery } from "@mc/utils/graphql";

export const GET_PHOTO_OPT_OUT = gql(/* GraphQL */ `
  query PhotoOptOut {
    viewer {
      isCharthopOptOut
    }
  }
`);

export const usePhotoOptOut = () => {
  const { user, updateUserSession } = useAuth();

  const onError = () =>
    toast.error(MESSAGES_USER_PROFILE.GET_PHOTO_OPT_FAILURE);

  const onCompleted = (data: PhotoOptOutQuery) => {
    const { isCharthopOptOut } = data.viewer;
    updateUserSession({ ...user, isCharthopOptOut });
  };

  const { loading, data } = useQuery(GET_PHOTO_OPT_OUT, {
    context: { clientName: "v4" },
    onCompleted,
    onError,
  });

  const isCharthopOptOut = data?.viewer?.isCharthopOptOut;

  return { loading, data: isCharthopOptOut };
};
