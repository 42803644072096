import clsx from "clsx";
import ct from "countries-and-timezones";
import PT from "prop-types";
import { useState } from "react";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";

import { Button, Field, Input } from "@m/ui";

import { InputStatusIcon } from "./InputStatusIcon";

export const PhoneNumberInput = ({
  phoneNumber,
  onPhoneNumberChange,
  loading,
  ok,
}) => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  const [businessPhone, setBusinessPhone] = useState(
    () => parsedPhoneNumber?.number || ""
  );
  const [extension, setExtension] = useState(
    () => parsedPhoneNumber?.ext || ""
  );
  const [isExtension, setIsExtension] = useState(
    () => parsedPhoneNumber?.ext?.length > 0
  );

  const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const error =
    !isPossiblePhoneNumber(businessPhone) && businessPhone.length !== 0;

  const toggleAddExtension = () => setIsExtension(true);

  const toggleRemoveExtension = () => {
    setExtension("");
    setIsExtension(false);
    if (extension.length > 0) onPhoneNumberChange(businessPhone);
  };

  const handlePhoneNumber = (value) => {
    if (value === undefined) {
      setBusinessPhone("");
    } else {
      setBusinessPhone(value);
    }
    setExtension("");
    setIsExtension(false);
  };

  const handleUpdatePhoneNumber = () => {
    if (error) return;

    if (businessPhone.length > 0 && extension.length > 0) {
      onPhoneNumberChange(`${businessPhone};ext=${extension}`);
    } else {
      onPhoneNumberChange(businessPhone);
    }
  };

  const handleExtension = (e) => setExtension(e.target.value);

  const inputStatusIcon = (
    <div className="absolute right-2 top-1/4">
      <InputStatusIcon loading={loading} ok={ok} />
    </div>
  );

  return (
    <Field
      label="Phone Number"
      htmlFor="user-profile-phone-number"
      description="The phone number provided may be used by a Mission team member if an
        issue arises."
    >
      <div className="flex justify-start gap-1">
        <div className="relative w-full flex-auto">
          <PhoneInput
            defaultCountry={timezone.countries[0]}
            flags={flags}
            international
            limitMaxLength
            numberInputProps={{
              className: clsx(
                "form-control-container form-control-input form-control__focus",
                {
                  "form-control__invalid": error,
                }
              ),
            }}
            onBlur={handleUpdatePhoneNumber}
            onChange={handlePhoneNumber}
            placeholder="Enter phone number"
            id="user-profile-phone-number"
            value={businessPhone}
          />
          {!isExtension && inputStatusIcon}
        </div>
        {isExtension && businessPhone.length > 0 && (
          <div className="relative flex-auto">
            <Input
              autoFocus
              placeholder="ext."
              className="w-[80px]"
              onBlur={handleUpdatePhoneNumber}
              onChange={handleExtension}
              value={extension}
            />
            {inputStatusIcon}
          </div>
        )}
        {businessPhone.length > 0 && !isExtension && (
          <Button
            onClick={toggleAddExtension}
            className="whitespace-nowrap"
            kind="primary"
            fill="subdued"
          >
            Add Extension
          </Button>
        )}
        {businessPhone.length > 0 && isExtension && (
          <Button
            onClick={toggleRemoveExtension}
            className="whitespace-nowrap"
            kind="primary"
            fill="subdued"
          >
            Remove Extension
          </Button>
        )}
      </div>
    </Field>
  );
};

PhoneNumberInput.propTypes = {
  phoneNumber: PT.string,
};
