import { FormEventHandler, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CaseCategory,
  CasePriority,
  CaseSubcategory,
} from "@m/api/public/types";
import { Button, Buttons, DynamicSelect, Field, Fields, Textarea } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { useAwsAccounts } from "@mc/features/Accounts/api";
import { useCreateCase } from "@mc/features/CustomerCases/api";
import { CreateCaseForm } from "@mc/features/CustomerCases/pages";
import { CaseType } from "@mc/features/CustomerCases/types";
import { createCaseDescription } from "@mc/features/CustomerCases/utils";

import {
  DevOpsDeskRequestForm,
  DevOpsDeskSupportBanner,
  MaintenanceWindowNeeded,
  PreferredContactMethods,
} from "../components";

export const CreateDevOpsDeskPage = () => {
  const {
    loading: isAccountsLoading,
    data: { accounts },
  } = useAwsAccounts();
  const navigate = useNavigate();
  const [createCase, { loading }] = useCreateCase();

  const accountOptions = useMemo(() => {
    return accounts.map((account) => {
      const { databaseId, name } = account;
      return { value: databaseId, label: name, subLabel: databaseId };
    });
  }, [accounts]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const handleClickCancel = () => {
    navigate(PATHS.ENGAGEMENTS_ENGINEER_ASSIST);
  };

  const handleSubmit: FormEventHandler<CreateCaseForm> = (e) => {
    e.preventDefault();
    const { elements } = e.currentTarget;

    submitDevOpsDeskRequest(elements);
  };

  const submitDevOpsDeskRequest = (elements: CreateDevOpsDeskFormElements) => {
    const {
      titleInput,
      descriptionInput,
      repositoryUrlInput,
      maintenanceWindowNeededRadio,
      desiredCompletionDateInput,
      preferredContactMethodRadio,
      schedulingLinkInput,
    } = elements;

    const title = titleInput.value;

    const repositoryUrl = repositoryUrlInput.value;
    const maintenanceWindowNeeded =
      maintenanceWindowNeededRadio.value as MaintenanceWindowNeeded;
    const desiredCompletionDate = desiredCompletionDateInput.value;
    const preferredContactMethod =
      preferredContactMethodRadio.value as PreferredContactMethods;
    const schedulingLink = schedulingLinkInput.value;

    const category = "DevOps Desk";
    const subcategory = "DevOps Desk";
    const priority = "Medium";

    const accountIds = selectedAccounts
      .map((account) => account.value)
      .join(", ");

    const description = createCaseDescription({
      description: descriptionInput.value,
      category,
      subcategory,
      priority,
      accountIds,
      caseType: CaseType.DevOps,
      repositoryUrl,
      maintenanceWindowNeeded,
      desiredCompletionDate,
      preferredContactMethod,
      schedulingLink,
    });

    createCase({
      shortDescription: title,
      description,
      category: CaseCategory.DevopsDesk,
      subcategory: CaseSubcategory.DevopsDesk,
      priority: CasePriority.Moderate,
    });
  };

  return (
    <Page
      title={
        <PageTitle
          breadcrumbs={[
            {
              path: PATHS.ENGAGEMENTS_ENGINEER_ASSIST,
              name: "Engineer Assist",
            },
            {
              path: PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE,
              name: "New Request",
            },
          ]}
          padding="small"
        />
      }
      width="small"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-[40px]">
        <DevOpsDeskSupportBanner className={"w-full"} />
        <Fields>
          <Textarea
            aria-label="Title"
            autoFocus={true}
            autogrow
            containerClasses="text-2xl"
            id="title"
            name="titleInput"
            noStyles
            nextFieldOnReturn={true}
            placeholder="Request title"
            required={true}
            rows={1}
          />
          <Textarea
            aria-label="Description"
            autogrow
            id="description"
            name="descriptionInput"
            noStyles
            placeholder="Add case description including any technologies and AWS services that will be used"
            required={true}
            rows={3}
          />
        </Fields>

        <hr />

        <Field
          htmlFor="accounts"
          label="Accounts"
          row="2/3"
          rowAlign="top"
          className="w-full"
        >
          <DynamicSelect
            id="accounts"
            name="accountsSelect"
            aria-label="Accounts Select"
            placeholder="Add accounts"
            initialOptions={accountOptions}
            selectedOptions={selectedAccounts}
            isMulti={true}
            isLoading={isAccountsLoading}
            onSelectedOptionsChange={setSelectedAccounts}
          />
        </Field>

        <DevOpsDeskRequestForm />

        <Buttons>
          <Button kind="primary" loading={loading} type="submit">
            Create request
          </Button>
          <Button kind="primary" fill="none" onClick={handleClickCancel}>
            Cancel
          </Button>
        </Buttons>
      </form>
    </Page>
  );
};

export interface CreateDevOpsDeskFormElements
  extends HTMLFormControlsCollection {
  titleInput: HTMLInputElement;
  descriptionInput: HTMLInputElement;
  accountsInput?: HTMLInputElement;
  repositoryUrlInput?: HTMLInputElement;
  maintenanceWindowNeededRadio?: HTMLInputElement;
  desiredCompletionDateInput?: HTMLInputElement;
  preferredContactMethodRadio?: HTMLInputElement;
  schedulingLinkInput?: HTMLInputElement;
}
