import PT from "prop-types";

import { dt } from "@m/utils";

import { GREETINGS } from "../constants";

export const Greeting = ({ firstName }) => {
  const hours = dt.local().hour;
  let greeting;

  if (hours < 12) {
    greeting = GREETINGS.MORNING;
  } else if (hours < 17) {
    greeting = GREETINGS.AFTERNOON;
  } else {
    greeting = GREETINGS.EVENING;
  }

  const title = firstName ? `${greeting}, ${firstName}!` : `${greeting}!`;
  return <h1 className="mb-4 text-2xl font-semibold">{title}</h1>;
};

Greeting.propTypes = {
  firstName: PT.string.isRequired,
};
