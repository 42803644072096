import { useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";

import { SwitchCompanyResourceType } from "@m/api/public/types";
import { useAuth } from "@m/login";
import { SpinnerScreen } from "@m/ui";

import { useGetCompanies, useSwitchCompany } from "@mc/api";
import { BASE_PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";

export const CompanySwitcherProvider = ({ children }) => {
  const companyIdMatch = useMatch("/:companyId/*");
  const dashboardCaseDetailsMatch = useMatch("/dashboard/cases/:sysId");
  const supportCaseDetailsMatch = useMatch("/support/cases/:sysId");

  const { user } = useAuth();
  const { pathname } = useLocation();

  const {
    data: { companies },
    loading: getCompaniesLoading,
  } = useGetCompanies();

  const [
    switchCompany,
    { loading: switchCompanyLoading, called: switchCompanyCalled },
  ] = useSwitchCompany();

  const companyId = companyIdMatch?.params?.companyId;
  const pathnameWithoutSlug = companyIdMatch?.params?.["*"];

  let sysId =
    dashboardCaseDetailsMatch?.params?.sysId ??
    supportCaseDetailsMatch?.params?.sysId;

  // If the 'sysId' is actually just the 'create' route, set it
  // back to null so that we don't attempt a company switch
  // with it.
  if (sysId === "create") sysId = null;

  /** If a user does not have a company slug in local storage, log out to refresh  */
  const isUserSessionValid = user?.company && "slug" in user.company;
  const shouldLogout = !isUserSessionValid;

  // A base path usually means that the user navigated to a path within mc directly
  // (e.g. /cloud-score). Less commonly, it can instead mean that the user navigated
  // to a support case url from service now (e.g. /dashboard/cases/:sysId)
  const isBasePath = BASE_PATHS.includes(companyId);
  const isCompanyMatch =
    companyId === user.company.slug || companyId === user.company.id;
  const isParamEmpty = companyId === undefined;
  const hasMultiCompanyAccess = companies.length > 1;

  const isValidCompanySlug = !isBasePath && !isCompanyMatch && !isParamEmpty;
  const isValidSupportCase = Boolean(sysId);

  // If the switch is blocked due to the user not having access to
  // multiple companies, the current location will still include the
  // invalid company slug. If this is the case, use the pathname without
  // the slug to get rid of the invalid slug.
  const isSwitchBlocked = isValidCompanySlug && !hasMultiCompanyAccess;
  const path = isSwitchBlocked ? pathnameWithoutSlug : pathname;
  const shouldUpdatePath = isParamEmpty || isBasePath || isSwitchBlocked;

  const shouldSwitchCompany =
    hasMultiCompanyAccess &&
    !shouldLogout &&
    !switchCompanyCalled &&
    (isValidCompanySlug || isValidSupportCase);

  const loading =
    getCompaniesLoading ||
    switchCompanyLoading ||
    shouldSwitchCompany ||
    shouldLogout;

  return (
    <>
      {shouldSwitchCompany && (
        <SwitchCompany
          switchCompany={switchCompany}
          sysId={sysId}
          companyId={companyId}
        />
      )}
      {shouldLogout && <Logout />}

      {loading ? (
        <SpinnerScreen data-testid="company-switcher-provider" />
      ) : (
        <UpdateCurrentPath path={path} shouldUpdatePath={shouldUpdatePath}>
          {children}
        </UpdateCurrentPath>
      )}
    </>
  );
};

const SwitchCompany = ({ switchCompany, sysId, companyId }) => {
  useEffect(
    function handleSwitchCompany() {
      const { CompanySlug, SupportCase } = SwitchCompanyResourceType;

      switchCompany({
        resourceType: sysId ? SupportCase : CompanySlug,
        resourceId: sysId ?? companyId,
      });
    },
    [switchCompany, companyId, sysId]
  );

  return null;
};

const UpdateCurrentPath = ({ children, path, shouldUpdatePath }) => {
  const navigate = useNavigate();

  useEffect(
    function handleUpdateCurrentPath() {
      if (shouldUpdatePath) navigate(path, { replace: true });
    },
    [navigate, path, shouldUpdatePath]
  );

  return children;
};

const Logout = () => {
  const { logout } = useAuth();

  useEffect(
    function handleLogout() {
      logout();
    },
    [logout]
  );

  return null;
};
