import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@m/login";

import { TabData, Tabs } from "@mc/components/Tabs";
import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { MISSION_ADMIN_TABS_DATA } from "../constants";

export const MissionAdminTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const activePath = pathname.split(PATHS.STAFF + "/")[1] || "";
  const activeTabName = activePath.split("/")[0] || "";

  const handleTabChange = (tab: TabData) => {
    navigate(
      generateCompanyPath(PATHS.STAFF + "/" + tab.id, user.company.slug)
    );
  };

  return (
    <Tabs
      tabsData={MISSION_ADMIN_TABS_DATA}
      onTabChange={handleTabChange}
      activeTabName={activeTabName}
    />
  );
};
