import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  description: string;
  feature: string;
}

export const NotificationsGroup = ({
  children,
  description,
  feature,
}: Props) => {
  return (
    <div className="flex flex-col gap-3 lg:flex-row">
      <div className="basis-2/5">
        <div className="text-lg font-semibold text-default">{feature}</div>
        <div className="text-sm font-medium text-subdued">{description}</div>
      </div>
      <div className="basis-3/5 space-y-3">{children}</div>
    </div>
  );
};
