import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { MissionControlLogin } from "@mc/app/components";

/** Renders child routes, if the user is logged in, otherwise renders login page. */
export const AuthenticatedOutlet = () => {
  const { user } = useAuth();
  return user ? <Outlet /> : <MissionControlLogin />;
};
