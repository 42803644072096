import { PATHS } from "@mc/constants";
import { useScorecardScore } from "@mc/features/CloudScore/api";

import { ToolbarLink } from "./ToolbarLink";

export const ScorecardLink = () => {
  const {
    data: { score },
  } = useScorecardScore();

  return (
    <ToolbarLink to={PATHS.CLOUD_SCORE} badgeLabel={score}>
      Cloud Score
    </ToolbarLink>
  );
};
