import { useCallback, useState } from "react";

import { getSignupUrl } from "../utils";

export const useMarketplaceSignup = (token: string) => {
  const [data, setData] = useState<{ auth0Url?: string }>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<MARKETPLACE_SIGNUP_ERRORS>(undefined);
  const [called, setCalled] = useState<boolean>(false);

  const signup = useCallback(
    (input: MarketplaceSignupInput) => {
      setLoading(true);
      setCalled(true);

      fetch(getSignupUrl(), {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "x-svs-marketplace-signup": token,
        },
        body: JSON.stringify(input),
      })
        .then((res) => res.json())
        .then((data: MarketplaceSignupPayload) => {
          const { error_message, success, auth0_url } = data;
          if (success) {
            setData({ auth0Url: auth0_url });
            setError(null);
            return;
          }

          if (error_message.includes("domain is already registered")) {
            setError(MARKETPLACE_SIGNUP_ERRORS.DOMAIN_IN_USE);
          } else {
            setError(MARKETPLACE_SIGNUP_ERRORS.SIGNUP_ERROR);
          }
          setData(null);
        })
        .catch(() => {
          setError(MARKETPLACE_SIGNUP_ERRORS.SIGNUP_ERROR);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [token]
  );

  return [signup, { data, loading, error, called }] as const;
};

export enum MARKETPLACE_SIGNUP_ERRORS {
  DOMAIN_IN_USE = "DOMAIN_IN_USE",
  SIGNUP_ERROR = "SIGNUP_ERROR",
}

export interface MarketplaceSignupInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  companyName?: string;
  invitationId?: string;
}

export interface MarketplaceSignupPayload {
  success: boolean;
  error_message: string | null;
  auth0_url?: string;
}
