import { XMarkIcon } from "@heroicons/react/24/outline";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import clsx from "clsx";
import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { useAuth } from "@m/login";
import { MissionLogo } from "@m/ui";

import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { useSidebar } from "../contexts";

import css from "./Sidebar.module.scss";

export const SidebarWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { user } = useAuth();
  const { open, closeSidebar } = useSidebar();

  const handleMediaQueryChange = (isStatic: boolean) => {
    isStatic && enableBodyScroll(document);

    !isStatic && open && disableBodyScroll(document);
  };

  const isDesktop = useMediaQuery(
    { minWidth: 768 },
    undefined,
    handleMediaQueryChange
  );

  open ? disableBodyScroll(document) : enableBodyScroll(document);

  const classes = clsx(
    css.sidebar,
    "bg-layout-panel text-zinc-600",
    className,
    {
      [`${css.static}`]: isDesktop,
      [`${css.floating}`]: !isDesktop && open,
    }
  );

  return (
    <nav data-testid="sidebar" aria-hidden={!open} className={classes}>
      {!isDesktop && (
        <div className="flex items-center justify-between px-2 pt-2 text-accent">
          <Link
            to={generateCompanyPath(PATHS.ROOT, user.company.slug)}
            onClick={closeSidebar}
          >
            <MissionLogo style={{ height: 32 }} />
          </Link>
          <button onClick={closeSidebar}>
            <XMarkIcon className="h-2.5 w-2.5" />
          </button>
        </div>
      )}
      <menu className="divide-y divide-form-border">{children}</menu>
    </nav>
  );
};
