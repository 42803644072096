import { OnboardingRequestStatus } from "@m/api/public/types";

import { PATHS } from "@mc/constants";
import { usePendingCloudEngagementsOnboarding } from "@mc/features/Onboarding/hooks";
import { Navigate } from "@mc/router";

/** Redirects to the current Cloud Engagements onboarding path. */
export const CloudEngagementsOnboardingPathRedirect = () => {
  const pendingCloudEngagementsOnboarding =
    usePendingCloudEngagementsOnboarding();
  const onboardingStatus = pendingCloudEngagementsOnboarding?.onboardingStatus;
  const currentPath = getCurrentOnboardingPath(onboardingStatus);

  return <Navigate replace to={currentPath} />;
};

const getCurrentOnboardingPath = (
  onboardingStatus: OnboardingRequestStatus
) => {
  if (onboardingStatus !== OnboardingRequestStatus.Complete) {
    return PATHS.ONBOARDING_CLOUD_ENGAGEMENTS_START;
  }
  return PATHS.ENGAGEMENTS_PROJECTS;
};
