import { CaseCategory } from "@m/api/public/types";

export const getCaseType = (category: CaseCategory) => {
  if (category === CaseCategory.Alerts || category === CaseCategory.Aws)
    return "Alert";

  if (category === CaseCategory.DevopsDesk) return "Engineer Assist";

  return "Support";
};
