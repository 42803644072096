import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

import { Tooltip } from "@m/ui";
import { formatDate } from "@m/utils";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS } from "@mc/constants";

/* eslint-disable-next-line mission-control/no-relative-imports */
import { version } from "../../../../../package.json";

export const DebugConsole = () => {
  if (
    !useAccessRequired({ feature: FEATURE_FLAGS.MISSION_CONTROL_DIAGNOSTICS })
  )
    return null;

  const data = {
    "App Version": `v${version} ${process.env.CI_COMMIT_SHORT_SHA || "dev"}`,
    "Build Time": formatDate(process.env.BUILD_TIME),
  };

  return (
    <div className="fixed bottom-2 right-2">
      <Tooltip
        side="left"
        align="end"
        asChild
        content={
          <>
            <h5 className="mb-0.5 text-xs font-bold">Mission Control Debug</h5>
            <dl>
              {Object.entries(data).map(([key, value]) => (
                <Fragment key={key}>
                  <dt className="mt-0.5 text-2xs font-bold">{key}</dt>
                  <dd>{value}</dd>
                </Fragment>
              ))}
            </dl>
          </>
        }
      >
        <span className="block cursor-pointer rounded-full bg-neutral-200 p-0.5 text-neutral-500">
          <CodeBracketIcon className="h-2.5 w-2.5" />
        </span>
      </Tooltip>
    </div>
  );
};
