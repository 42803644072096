import { useQuery } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";

import { gql } from "@m/api/public";
import {
  MarketplaceRegistration,
  OnboardingRequestStatus,
} from "@m/api/public/types";
import { SpinnerScreen } from "@m/ui";

import { filterSupportedMarketplaceRegistrations } from "../utils";

interface MarketplaceRegistrations {
  /* Marketplace registrations that have been successfully onboarded */
  marketplaceSubscriptions: Partial<MarketplaceRegistration>[];
  hasMarketplaceSubscriptions: boolean;

  /* Marketplace registrations that are pending onboarding */
  pendingOnboardings: Partial<MarketplaceRegistration>[];
  hasPendingOnboardings: boolean;
}

export const MarketplaceRegistrationsContext =
  createContext<MarketplaceRegistrations>({
    hasMarketplaceSubscriptions: false,
    hasPendingOnboardings: false,
    marketplaceSubscriptions: [],
    pendingOnboardings: [],
  });

export const useMarketplaceRegistrations = () =>
  useContext(MarketplaceRegistrationsContext);

export const GET_MARKETPLACE_REGISTRATIONS = gql(/* GraphQL */ `
  query GetMarketplaceRegistrations($where: MarketplaceRegistrationFilter) {
    userProfile {
      currentCompany {
        marketplaceRegistrations(where: $where) {
          edges {
            node {
              awsAccountId
              catalogItem {
                displayName
              }
              customerIdentifier
              databaseId
              expirationTime
              iamExternalId
              id
              marketplaceProductCode
              onboardingStatus
              payerAccountId
              registrationStatus
              sku
              subscribeTime
            }
          }
        }
      }
    }
  }
`);

export const MarketplaceRegistrationsProvider = ({ children }) => {
  const { data, loading } = useQuery(GET_MARKETPLACE_REGISTRATIONS);

  const registrations = useMemo(() => {
    const marketplaceRegistrations =
      data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges.map(
        (edge) => edge.node
      ) || [];

    const supportedMarketplaceRegistrations =
      filterSupportedMarketplaceRegistrations(marketplaceRegistrations);

    const pendingOnboardings = supportedMarketplaceRegistrations.filter(
      ({ onboardingStatus }) =>
        !FINISHED_ONBOARDING_STATUSES.includes(onboardingStatus)
    );

    const marketplaceSubscriptions = supportedMarketplaceRegistrations.filter(
      ({ onboardingStatus }) =>
        FINISHED_ONBOARDING_STATUSES.includes(onboardingStatus)
    );

    return {
      hasMarketplaceSubscriptions: marketplaceSubscriptions.length > 0,
      hasPendingOnboardings: pendingOnboardings.length > 0,
      marketplaceSubscriptions,
      pendingOnboardings,
    };
  }, [data]);

  return (
    <MarketplaceRegistrationsContext.Provider value={registrations}>
      {loading ? <SpinnerScreen /> : children}
    </MarketplaceRegistrationsContext.Provider>
  );
};

const FINISHED_ONBOARDING_STATUSES = [
  OnboardingRequestStatus.Complete,
  OnboardingRequestStatus.Processing,
];
