import { useEffect, useState } from "react";

import { getValidateTokenUrl } from "../utils";

export const useValidateRegistrationToken = (token: string) => {
  const [data, setData] = useState<{ success: boolean }>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetch(getValidateTokenUrl(), {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-svs-marketplace-signup": token,
      },
    })
      .then((res) => res.json())
      .then((data: ValidateRegistrationTokenResponse) => {
        setData({ success: data.success });
        setError(data.error_message);
      })
      .catch((reason) => {
        setError(reason);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  return { data, loading, error };
};

export interface ValidateRegistrationTokenResponse {
  success: boolean;
  error_message: string | null;
}
