import { generateMockId } from "@m/api/testing";
import { AccountAvatar } from "@m/ui";
import { dt } from "@m/utils";

import {
  ENGAGEMENTS_MILESTONE_STATUS,
  ENGAGEMENTS_OWNER_OPTIONS,
} from "../constants";
import {
  EngagementDetailOwnerType,
  IEngagementMilestoneItem,
  IEngagementMilestoneTask,
  IEngagementsDetailsNote,
} from "../types";

class ExampleEngagementMilestoneTask {
  complete: boolean;
  id: string;
  title: string;

  constructor({ complete, title }: Partial<IEngagementMilestoneTask>) {
    this.complete = complete || false;
    this.id = generateMockId();
    this.title = title || "";
  }
}

class ExampleMilestoneItem {
  dueDate: string;
  engagementId: string;
  id: string;
  noteList: IEngagementsDetailsNote[];
  owner: EngagementDetailOwnerType;
  percentageComplete: number;
  status: ENGAGEMENTS_MILESTONE_STATUS;
  tasks: IEngagementMilestoneTask[];
  title: string;
  createdBy: AccountAvatar;
  createTime: string;
  completedTime: string;

  constructor({
    dueDate,
    engagementId,
    id,
    noteList,
    owner,
    percentageComplete,
    status,
    tasks,
    title,
    createdBy,
    createTime,
    completedTime,
  }: Partial<IEngagementMilestoneItem>) {
    this.dueDate =
      dueDate || dt.utc().startOf("day").plus({ weeks: 1 }).toISO();
    this.engagementId = engagementId || generateMockId();
    this.id = id || generateMockId();
    this.noteList = noteList || [];
    this.owner = owner || ENGAGEMENTS_OWNER_OPTIONS.MISSION;
    this.percentageComplete = percentageComplete || 0;
    this.status = status || ENGAGEMENTS_MILESTONE_STATUS.OPEN;
    this.tasks = tasks || [];
    this.title = title || "";
    this.createdBy = createdBy || {
      email: "tom@tom.com",
      name: "Tom Tom",
      firstName: "Tom",
      lastName: "Tom",
    };
    this.createTime = createTime || dt.utc().minus({ days: 5 }).toISO();
    this.completedTime = completedTime || null;
  }
}

export const EXAMPLE_MILESTONE_ITEM_OPEN = new ExampleMilestoneItem({
  dueDate: dt.utc().startOf("day").minus({ days: 5 }).toISO(),
  id: "6880464719624664",
  noteList: [
    {
      id: "123",
      content: "Some test notes",
      createdAt: dt.utc().toISO(),
      createdBy: {
        id: generateMockId(),
        email: "tom@tom.com",
        name: "Tom Tom",
        firstName: "Tom",
        lastName: "Tom",
      },
      updatedAt: dt.utc().toISO(),
    },
  ],
  owner: ENGAGEMENTS_OWNER_OPTIONS.MISSION,
  status: ENGAGEMENTS_MILESTONE_STATUS.OPEN,
  tasks: [
    new ExampleEngagementMilestoneTask({
      complete: true,
      title: "Nunc suscipit justo nec neque varius molestie.",
    }),
    new ExampleEngagementMilestoneTask({
      complete: false,
      title:
        "Nullam semper nisl at nisi bibendum, pretium malesuada ipsum lobortis.",
    }),
  ],
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
});

export const EXAMPLE_MILESTONE_ITEM_RESOLVED = new ExampleMilestoneItem({
  dueDate: dt.utc().startOf("day").minus({ days: 2 }).toISO(),
  id: "3731149057975389",
  noteList: [],
  owner: ENGAGEMENTS_OWNER_OPTIONS.CUSTOMER,
  status: ENGAGEMENTS_MILESTONE_STATUS.RESOLVED,
  tasks: [],
  title: "Donec sed eros molestie mauris vestibulum posuere sed eu magna.",
});

export const EXAMPLE_MILESTONE_ITEM_RESOLVED_2 = new ExampleMilestoneItem({
  dueDate: dt.utc().startOf("day").minus({ days: 20 }).toISO(),
  id: "8842186061931405",
  noteList: [],
  owner: ENGAGEMENTS_OWNER_OPTIONS.CUSTOMER,
  status: ENGAGEMENTS_MILESTONE_STATUS.RESOLVED,
  tasks: [],
  title: "Proin sed augue interdum, iaculis quam non, blandit mauris.",
});

export const EXAMPLE_MILESTONE_ITEM_ARCHIVED = new ExampleMilestoneItem({
  dueDate: dt.utc().startOf("day").minus({ days: 10 }).toISO(),
  id: "16570290825524592",
  noteList: [],
  owner: ENGAGEMENTS_OWNER_OPTIONS.CUSTOMER,
  status: ENGAGEMENTS_MILESTONE_STATUS.ARCHIVED,
  tasks: [],
  title: "Nunc accumsan justo et nisi finibus, at molestie massa interdum.",
});

export const EXAMPLE_MILESTONES_ITEMS = [
  EXAMPLE_MILESTONE_ITEM_OPEN,
  EXAMPLE_MILESTONE_ITEM_RESOLVED,
  EXAMPLE_MILESTONE_ITEM_RESOLVED_2,
  EXAMPLE_MILESTONE_ITEM_ARCHIVED,
];
