import { FormEventHandler } from "react";

import { Button, Field, Fields, Input } from "@m/ui";

interface CreateTeamFormProps {
  onSubmit: (elements: CreateTeamFormElements) => void;
}

export const CreateTeamForm = ({ onSubmit }: CreateTeamFormProps) => {
  const handleSubmit: FormEventHandler<CreateTeamFormElement> = (e) => {
    e.preventDefault();
    onSubmit(e.currentTarget.elements);
  };

  return (
    <form
      className="flex flex-col items-center gap-y-5"
      data-testid="create-team-form"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-y-2 text-center">
        <h1 className="text-4xl font-bold">Create your company</h1>
        <p className="text-xl text-subdued">
          Please provide the name of your company or team
        </p>
      </div>

      <Fields className="w-full max-w-md">
        <Field label="Company Name" htmlFor="company-name">
          <Input
            autoFocus={true}
            id="company-name"
            name="companyNameInput"
            required={true}
            type="text"
          />
        </Field>

        <Button type="submit" kind="primary" size="large" className="w-full">
          Create company
        </Button>
      </Fields>
    </form>
  );
};

export interface CreateTeamFormElements extends HTMLFormControlsCollection {
  companyNameInput: HTMLInputElement;
}

interface CreateTeamFormElement extends HTMLFormElement {
  readonly elements: CreateTeamFormElements;
}
