import { RouteObject } from "react-router-dom";

import { OnboardingInvitePage } from "@mc/features/Onboarding";
import { FullScreenLayout } from "@mc/layouts";

import { onboardingCloudEngagementsRoutes } from "./onboardingCloudEngagementsRoutes";
import { onboardingCloudGatewayRoutes } from "./onboardingCloudGatewayRoutes";
import { onboardingCloudScoreRoutes } from "./onboardingCloudScoreRoutes";
import {
  EligibleProductsForInvitationOutlet,
  OnboardingCloudEngagementsOutlet,
  OnboardingCloudGatewayOutlet,
  OnboardingCloudScoreOutlet,
} from "./outlets";
import { OnboardingPathRedirect } from "./redirects";

export const onboardingRoutes: RouteObject[] = [
  {
    element: <FullScreenLayout />,
    children: [
      {
        path: "*",
        element: <OnboardingPathRedirect />,
      },
      {
        path: "invite",
        element: <EligibleProductsForInvitationOutlet />,
        children: [{ path: "*", element: <OnboardingInvitePage /> }],
      },
      {
        path: "cloud-score",
        element: <OnboardingCloudScoreOutlet />,
        children: onboardingCloudScoreRoutes,
      },
      {
        path: "cloud-gateway",
        element: <OnboardingCloudGatewayOutlet />,
        children: onboardingCloudGatewayRoutes,
      },
      {
        path: "cloud-engagements",
        element: <OnboardingCloudEngagementsOutlet />,
        children: onboardingCloudEngagementsRoutes,
      },
    ],
  },
];
