export const SETTINGS_TABS = {
  profile: {
    id: "profile",
    name: "Profile",
  },
  users: {
    id: "users",
    name: "Users",
  },
  notifications: {
    id: "notifications",
    name: "Notifications",
  },
  billing: {
    id: "billing",
    name: "Billing",
  },
  subscriptions: {
    id: "subscriptions",
    name: "Subscriptions",
  },
};

const tabs = Object.values(SETTINGS_TABS);

export const SETTINGS_TABS_DATA = tabs.map((tab) => ({
  label: tab.name,
  ...tab,
}));
