import { Page } from "@mc/components/Page";
import { SettingsPageTitle } from "@mc/features/Settings";

import { MarketplaceSubscriptionCards } from "../components";

export const MarketplaceSubscriptionsPage = () => (
  <Page
    data-testid="marketplace-subscriptions-page"
    title={<SettingsPageTitle />}
    width="large"
  >
    <MarketplaceSubscriptionCards />
  </Page>
);
