import { useAuth } from "@m/login";
import { Button, useModalState } from "@m/ui";

import { EmptyState } from "@mc/components/EmptyState";

import { MarketplaceInviteDialog } from "../components";
import { AccessLockArtboard } from "../icons";

export const OnboardingInvitePage = () => {
  const {
    user: { company },
  } = useAuth();

  const inviteDialog = useModalState();

  return (
    <div data-testid="onboarding-invite-page" className="mt-5">
      <EmptyState
        className="w-full max-w-4xl"
        image={<AccessLockArtboard height={198} width={198} />}
        title="This company does not have access to Mission Control"
        description="They can try our services for free with an invite to Cloud Score"
        actions={
          <Button kind="primary" onClick={inviteDialog.open}>
            Invite to Marketplace Offer
          </Button>
        }
      />

      <MarketplaceInviteDialog
        companyName={company.name}
        onClose={inviteDialog.close}
        open={inviteDialog.isOpen}
      />
    </div>
  );
};
