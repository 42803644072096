import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { FC } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  /** Element or component the DropdownItem renders as */
  as?: "button" | "a" | "div";

  /** Additional classes */
  className?: string;

  /** Disabled state */
  disabled?: boolean;

  /** Negative status */
  negative?: boolean;

  /** If should show as selected */
  selected?: boolean;

  /** Unstrict Props */
  [propName: string]: any;
};

const defaultProps = {
  as: "button",
};

export const DropdownItem: FC<Props> = ({
  as = defaultProps.as,
  children,
  className,
  disabled,
  negative,
  selected,
  ...props
}) => {
  const getTagName = () => {
    // If `as` is passed in and isn't default, it is always used
    if (as !== defaultProps.as) return as;

    // If `href` is passed in, the element becomes an anchor
    if (props.href) return "a";

    // If 'to' is passed in, the element becomes a react-router-dom NavLink component
    if (props.to) return NavLink;

    // Otherwise use default
    return as;
  };

  const Element: any = getTagName();

  return (
    <Menu.Item>
      {({ active }) => {
        const classes = clsx(
          "flex w-full items-center rounded-md p-1 text-sm font-semibold text-default",
          active && "bg-neutral-200 bg-opacity-[.6]",
          negative && "text-negative",
          selected && "bg-[#EDF3F8]",
          !disabled && "cursor-pointer",
          disabled && "opacity-[.6] cursor-default",
          className
        );
        return (
          <Element className={classes} disabled={disabled} {...props}>
            {children}
          </Element>
        );
      }}
    </Menu.Item>
  );
};
