import { useCallback, useState } from "react";

import { getValidateInvitationUrl } from "../utils";

export const useValidateProductInvitation = (token: string) => {
  const [data, setData] =
    useState<ValidateProductInvitationResponse>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(undefined);

  const validateProductInvitation = useCallback(
    (input: ValidateProductInvitationInput) => {
      setLoading(true);

      fetch(getValidateInvitationUrl(), {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "x-svs-marketplace-signup": token,
        },
        body: JSON.stringify(input),
      })
        .then((res) => res.json())
        .then((data: ValidateProductInvitationResponse) => {
          if (!data?.success) throw "Failed to validate product invitation.";
          setData(data);
          setError(null);
        })
        .catch((reason) => {
          setError(reason);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [token]
  );

  return [validateProductInvitation, { data, loading, error }] as const;
};

export interface ValidateProductInvitationInput {
  emailAddress: string;
}

export interface ValidateProductInvitationResponse {
  success: boolean;
  valid: boolean;
  invitation?: { id: string };
}
