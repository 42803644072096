import { MarketplaceSubscriptionDetails } from ".";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { Button } from "@m/ui";

import { useCompleteCloudEngagementsOnboarding } from "../api";

export const CloudEngagementsOnboardingWelcomeDetails = () => {
  const [completeCloudEngagementsOnboarding] =
    useCompleteCloudEngagementsOnboarding();

  const handleClickStart = () => completeCloudEngagementsOnboarding();

  return (
    <div className="flex w-[394px] flex-col items-center justify-center gap-5">
      <MarketplaceSubscriptionDetails />

      <Button kind="primary" className="w-full py-2" onClick={handleClickStart}>
        Go to Mission Cloud Engagements - DevOps
        <ArrowRightIcon className="h-2 w-2" />
      </Button>
    </div>
  );
};
