import { useEffect, useState } from "react";

import { Engagement } from "@m/api/public/types";
import { Button, Confirm, Field, Input, Toggle } from "@m/ui";

import { useUpdateEngagement } from "../api";
import {
  MAX_ENGAGEMENT_TITLE_LENGTH,
  MESSAGES_ENGAGEMENTS,
} from "../constants";

type Props = {
  engagement: Engagement;
  isModalOpen: boolean;
  onModalClose?: () => void;
};

export const EngagementSettingsModal = ({
  engagement,
  isModalOpen,
  onModalClose,
}: Props) => {
  const [updateEngagement] = useUpdateEngagement(engagement.id);

  const [updatedEngagement, setUpdatedEngagement] = useState(engagement);

  useEffect(() => {
    setUpdatedEngagement(engagement);
  }, [engagement]);

  const { visible, id, title } = updatedEngagement;

  const handleVisibility = () => {
    setUpdatedEngagement((previousEngagement) => ({
      ...previousEngagement,
      visible: !previousEngagement.visible,
    }));
  };

  const handleTitleChange = ({ target: { value } }) => {
    setUpdatedEngagement((previousEngagement) => ({
      ...previousEngagement,
      title: value,
    }));
  };

  const handleSaveEngagement = async () => {
    onModalClose();

    const response = await updateEngagement({
      title,
      id,
      isEnabled: visible,
    });

    if (response.errors) {
      return setUpdatedEngagement(engagement);
    }
  };

  const handleClose = () => {
    setUpdatedEngagement(engagement);
    onModalClose();
  };

  const CHARACTER_COUNT = MAX_ENGAGEMENT_TITLE_LENGTH - title?.length;
  const actionDisabled = CHARACTER_COUNT === MAX_ENGAGEMENT_TITLE_LENGTH;

  return (
    <Confirm
      onClose={onModalClose}
      open={isModalOpen}
      actions={[
        <Button
          key="save-update-engagement-button"
          className="focus-visible:outline-0 focus-visible:ring-0"
          onClick={handleSaveEngagement}
          disabled={actionDisabled}
          kind="primary"
          aria-label="Save Update Engagement"
        >
          Save
        </Button>,
        <Button
          key="cancel-update-engagement-button"
          className="focus-visible:outline-0 focus-visible:ring-0"
          aria-label="Cancel Update Engagement"
          onClick={handleClose}
          kind="primary"
          fill="none"
        >
          Cancel
        </Button>,
      ]}
    >
      <div data-testid="engagement-settings-modal">
        <Field
          className="pb-2"
          flag="required"
          label="Project Title"
          description={MESSAGES_ENGAGEMENTS.TITLE_DESCRIPTION}
          htmlFor="title"
          error={
            <p className="font-regular mb-0.5 text-xs text-subdued">
              {CHARACTER_COUNT} Characters left
            </p>
          }
        >
          <Input
            id="title"
            maxLength={MAX_ENGAGEMENT_TITLE_LENGTH}
            onChange={handleTitleChange}
            placeholder="Untitled"
            value={title}
          />
        </Field>

        <div className="flex w-full items-center justify-between">
          <Field label="Visible to customer" />
          <Toggle
            disabled={actionDisabled}
            checked={visible}
            onChange={handleVisibility}
          />
        </div>
      </div>
    </Confirm>
  );
};
