import { Outlet } from "react-router-dom";

import { useEligibleProductsForInvitation } from "@mc/features/Onboarding";
import { NotFound } from "@mc/pages";

/** Renders child routes, if the user has eligible products for invitation. */
export const EligibleProductsForInvitationOutlet = () => {
  const { hasProductsForInvitation } = useEligibleProductsForInvitation();
  return hasProductsForInvitation ? <Outlet /> : <NotFound />;
};
