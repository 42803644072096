import { Outlet } from "react-router-dom";

import { PreparingCloudScorePage } from "@mc/features/CloudScore";
import { useIsPreparingCloudScore } from "@mc/features/CloudScore/providers";

/**
 * Renders child routes, if the user's Cloud Score is available,
 * otherwise renders Preparing Cloud Score page.
 */
export const CloudScoreOutlet = () =>
  useIsPreparingCloudScore() ? <PreparingCloudScorePage /> : <Outlet />;
