import { Page } from "@mc/components/Page";
import { SettingsPageTitle } from "@mc/features/Settings";

import { StoredPaymentMethod } from "../components";

export const BillingSettingsPage = () => (
  <Page
    data-testid="billing-settings-page"
    title={<SettingsPageTitle />}
    width="large"
  >
    <div className="flex flex-col gap-y-5">
      <Section title="Payment Methods">
        <StoredPaymentMethod />
      </Section>
    </div>
  </Page>
);

const Section = ({ children, title }) => (
  <div className="flex flex-col gap-y-1">
    <div className="text-sm font-semibold text-subdued">{title}</div>
    {children}
  </div>
);
