import {
  ArrowUpRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { Banner, Button, Card, Field, Input, Spinner } from "@m/ui";

import {
  CLOUD_FORMATION_IAM_ROLES,
  CLOUD_FORMATION_MAX_ATTEMPTS,
  CLOUD_FORMATION_POLL_INTERVAL,
  SKUS,
} from "@mc/constants";
import { getCloudFormationTemplateURL } from "@mc/utils/cloudFormation";

import {
  useCreateMarketplaceOnboardingRequest,
  useValidateOnboardingRoleDeployed,
} from "../api";
import {
  AccountAlreadyExistsError,
  AwsConsoleSigninLink,
  AwsManagementConsoleInfo,
  ConnectAwsPayerAccountError,
} from "../components";
import { usePendingCloudGatewayOnboarding } from "../hooks";
import { GreenCheckIcon } from "../icons";

const awsAccountName = "Payer";
const roleName = CLOUD_FORMATION_IAM_ROLES.MISSION_CONTROL_GATEWAY_BILLING;

interface Props {
  /** Max number of validation attempts for identify payer account IAM role before timing out */
  maxAttempts?: number;

  /** Poll interval for validating identify payer account IAM role has been deployed  */
  pollInterval?: number;
}

export const ConnectAwsPayerAccountPage = ({
  maxAttempts = CLOUD_FORMATION_MAX_ATTEMPTS,
  pollInterval = CLOUD_FORMATION_POLL_INTERVAL,
}: Props): JSX.Element => {
  const pendingCloudGatewayOnboarding = usePendingCloudGatewayOnboarding();
  const payerAccountId = pendingCloudGatewayOnboarding?.payerAccountId;
  const iamExternalId = pendingCloudGatewayOnboarding?.iamExternalId;

  const [isTemplateRunning, setIsTemplateRunning] = useState(false);
  const [validationAttempts, setValidationAttempts] = useState(0);

  const pollingTimedOut = validationAttempts === maxAttempts;

  const [
    validateOnboardingRoleDeployed,
    {
      data: onboardingRoleValidated,
      isPolling: isPollingOnboardingRole,
      stopPolling: stopPollingOnboardingRole,
    },
  ] = useValidateOnboardingRoleDeployed(
    payerAccountId,
    iamExternalId,
    roleName,
    pollInterval
  );

  const [
    createOnboardingRequest,
    { error: onboardingRequestError, accountExists },
  ] = useCreateMarketplaceOnboardingRequest(
    payerAccountId,
    [roleName],
    awsAccountName
  );

  const handleRunCloudFormationTemplate = () => {
    setValidationAttempts(0);
    setIsTemplateRunning(true);
    openTemplateUrl(iamExternalId);
    validateOnboardingRoleDeployed();
  };

  const handleRerunCloudFormationTemplate = () => {
    setValidationAttempts(0);
    openTemplateUrl(iamExternalId);
  };

  useEffect(
    function handleIncrementValidationAttempts() {
      if (isPollingOnboardingRole) setValidationAttempts((prev) => prev + 1);
    },
    [isPollingOnboardingRole]
  );

  useEffect(
    function handlePollingTimedOut() {
      if (pollingTimedOut) {
        stopPollingOnboardingRole();
        setIsTemplateRunning(false);
      }
    },
    [pollingTimedOut, stopPollingOnboardingRole]
  );

  useEffect(
    function handleOnboardingRoleValidated() {
      if (onboardingRoleValidated) {
        stopPollingOnboardingRole();
        createOnboardingRequest();
        // after onboarding request is created, marketplace registration status will
        // change to 'Complete' and the user will be kicked out of onboarding.
      }
    },
    [
      onboardingRoleValidated,
      stopPollingOnboardingRole,
      createOnboardingRequest,
    ]
  );

  if (accountExists) return <AccountAlreadyExistsError />;
  if (onboardingRequestError) return <ConnectAwsPayerAccountError />;

  return (
    <div className="flex max-w-2xl flex-col items-center gap-5">
      <div className="text-4xl font-bold text-default">
        Connect Payer Account
      </div>

      <Card className="flex flex-col gap-5 p-2">
        <div className="flex flex-col gap-3">
          <Banner
            status="warning"
            label={
              <>
                This account was identified as the payer account for the account
                subscribed.{" "}
                <AwsConsoleSigninLink awsAccountId={payerAccountId} />
              </>
            }
          />
          <Field label="Payer Account">
            <Input disabled value={payerAccountId} className="text-sm" />
          </Field>
          <Field label="Account Name">
            <Input disabled value={awsAccountName} className="text-sm" />
          </Field>
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-base font-semibold text-default">
            Run the CloudFormation Template below to deploy our necessary IAM
            roles. These roles allow Mission Cloud the following access:
          </div>
          <div className="flex items-center gap-1">
            <GreenCheckIcon />
            <div className="text-sm font-medium text-default">
              Data from Trusted Advisor and other AWS Support APIs to analyze
              best practices
            </div>
          </div>
          <div className="flex items-center gap-1">
            <GreenCheckIcon />
            <div className="text-sm font-medium text-default">
              Resource data to provide an accurate resource inventory list
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-2">
          <Button
            disabled={isTemplateRunning}
            kind="primary"
            onClick={handleRunCloudFormationTemplate}
            rightIcon={!isTemplateRunning && ArrowUpRightIcon}
            leftIcon={isTemplateRunning && Spinner}
            size="large"
          >
            {isTemplateRunning ? (
              <>Verifying Access</>
            ) : (
              <>Run CloudFormation Template</>
            )}
          </Button>
          {isTemplateRunning && (
            <Button
              fill="none"
              kind="primary"
              onClick={handleRerunCloudFormationTemplate}
            >
              Rerun CloudFormation Template
            </Button>
          )}
          {pollingTimedOut && (
            <div className="flex items-center gap-1">
              <ExclamationTriangleIcon className="h-2.5 w-2.5 text-status-warning" />
              <div className="text-sm font-bold text-default">
                Verification timed out. Try again
              </div>
            </div>
          )}
        </div>

        {!isTemplateRunning && <AwsManagementConsoleInfo />}
      </Card>
    </div>
  );
};

const openTemplateUrl = (iamExternalId?: string) => {
  const url = getCloudFormationTemplateURL(SKUS.CLOUD_GATEWAY, {
    iamExternalId,
    isPayerAccount: true,
  });

  window.open(url, "_blank");
};
