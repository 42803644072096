import PT from "prop-types";
import { useMemo, useState } from "react";

import {
  SCORECARD_TREND_OPTIONS,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { ScorecardTrendContext } from "../contexts";
import { getPillar } from "../utils";

import { ScorecardTrendChangeIndicator } from "./ScorecardTrendChangeIndicator";
import { ScorecardTrendGraph1Month } from "./ScorecardTrendGraph1Month";
import { ScorecardTrendGraph1Year } from "./ScorecardTrendGraph1Year";
import { ScorecardTrendGraph3Months } from "./ScorecardTrendGraph3Months";
import { ScorecardTrendGraph6Months } from "./ScorecardTrendGraph6Months";
import { ScorecardTrendTimeRangeSelector } from "./ScorecardTrendTimeRangeSelector";

const { ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR } =
  SCORECARD_TREND_TIME_RANGES;

export const ScorecardTrendGraph = ({ className, pillarId }) => {
  const [selectedRange, setSelectedRange] = useState(SIX_MONTHS.key);
  const [scoreChange, setScoreChange] = useState();

  const handleChangeSelectedRange = (range) => {
    setSelectedRange(range);
    if (range !== selectedRange) {
      setScoreChange();
    }
  };

  const scorecardTrendContext = useMemo(
    () => ({ className, onLoad: setScoreChange, selectedRange }),
    [className, selectedRange]
  );

  const pillar = getPillar(pillarId);
  if (!pillar) return null;

  const summaryName = pillar.pillar ? pillar.name : "Overall";

  return (
    <div aria-label="Cloud Score Progress Graph">
      <div className="flex justify-between">
        <div className="flex flex-wrap items-center gap-x-2">
          <div className="whitespace-nowrap text-base font-semibold text-default">
            {summaryName} Progress
          </div>
          <ScorecardTrendChangeIndicator
            scoreChange={scoreChange}
            selectedRange={selectedRange}
          />
        </div>
        <ScorecardTrendTimeRangeSelector
          options={SCORECARD_TREND_OPTIONS}
          selectedOption={selectedRange}
          onChange={handleChangeSelectedRange}
        />
      </div>

      <ScorecardTrendContext.Provider value={scorecardTrendContext}>
        {selectedRange === ONE_MONTH.key && (
          <ScorecardTrendGraph1Month pillarId={pillarId} />
        )}
        {selectedRange === THREE_MONTHS.key && (
          <ScorecardTrendGraph3Months pillarId={pillarId} />
        )}
        {selectedRange === SIX_MONTHS.key && (
          <ScorecardTrendGraph6Months pillarId={pillarId} />
        )}
        {selectedRange === ONE_YEAR.key && (
          <ScorecardTrendGraph1Year pillarId={pillarId} />
        )}
      </ScorecardTrendContext.Provider>
    </div>
  );
};

ScorecardTrendGraph.propTypes = {
  className: PT.string,
  pillarId: PT.string.isRequired,
};
