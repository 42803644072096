import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { PropsWithChildren, useState } from "react";

import { useMatch } from "@mc/router";

interface Props extends PropsWithChildren {
  closable?: boolean;
  defaultOpen?: boolean;
  path?: string;
  title?: string;
}

export const SidebarGroup = ({
  children,
  closable = false,
  defaultOpen = true,
  path = "",
  title,
}: Props) => {
  const match = useMatch(path);
  const [open, setOpen] = useState<boolean>(
    closable ? defaultOpen || match !== null : true
  );

  const toggleMenu = () => {
    if (closable) setOpen((prev) => !prev);
  };
  const ChevronIcon = open ? ChevronDownIcon : ChevronLeftIcon;
  const TitleElement = closable ? "button" : "div";
  return (
    <div
      className={clsx("p-2", {
        "pb-3": title,
      })}
      aria-label={title && `${title} Navigation Group`}
    >
      {title && (
        <TitleElement
          className={clsx(
            "mb-0.5 flex w-full items-center px-1 text-left text-2xs font-semibold uppercase tracking-wider text-subdued",
            {
              "cursor-pointer": closable,
            }
          )}
          onClick={toggleMenu}
        >
          <span className="grow">{title}</span>
          {closable && <ChevronIcon className="inline h-2 w-2" />}
        </TitleElement>
      )}
      {open && children}
    </div>
  );
};
