import { CasePriority } from "@m/api/public/types";

interface CasePriorityDefinition {
  name: string;
  value: CasePriority;
}

export const CASE_PRIORITY_DEFINITIONS: {
  [caseType: string]: CasePriorityDefinition[];
} = {
  account: [
    {
      name: "General Request",
      value: CasePriority.Low,
    },
    {
      name: "Urgent Request",
      value: CasePriority.Moderate,
    },
  ],
  operations: [
    {
      name: "Low",
      value: CasePriority.Low,
    },
    {
      name: "Medium",
      value: CasePriority.Moderate,
    },
    {
      name: "High",
      value: CasePriority.High,
    },
    {
      name: "Critical",
      value: CasePriority.Critical,
    },
  ],
  support: [
    {
      name: "Low",
      value: CasePriority.Low,
    },
    {
      name: "Medium",
      value: CasePriority.Moderate,
    },
    {
      name: "High",
      value: CasePriority.High,
    },
    {
      name: "Critical",
      value: CasePriority.Critical,
    },
  ],
};
