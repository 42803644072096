export const toProperCase = (str: string): string => {
  if (!str || typeof str !== "string") return "";
  const words = str.toLowerCase().split(/\s/);

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

export const toDollars = (num: unknown) => {
  if (
    (typeof num === "number" ||
      (typeof num === "string" && num.trim() !== "")) &&
    !isNaN(num as number)
  ) {
    return Number(num).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
};

export const fromDollars = (currency: unknown): undefined | number => {
  // Pass through numbers
  if (typeof currency === "number") {
    return currency;
  }

  if (typeof currency !== "string") {
    return undefined;
  }

  // Remove non-numeric characters except for the decimal point and minus sign
  const numericString = currency.replace(/[^0-9.-]/g, "");

  // Check if the resulting string is empty
  if (numericString === "") {
    return undefined;
  }

  // Parse the numeric string into a float and round it to two decimal places
  let parsedValue = parseFloat(numericString).toFixed(2);

  // Handle cases where the string starts with a decimal point, e.g., ".123"
  if (parsedValue.startsWith(".")) {
    parsedValue = "0" + parsedValue;
  }

  // Convert the result back to a number and ensure it's a valid number
  const result = +parsedValue;
  return isNaN(result) ? undefined : result;
};

export const toHyphenLowercase = (str = ""): string => {
  return str?.replace(/\s+/g, "-").replace("_", "-").toLowerCase() || "";
};

export const fromSnakeCaseToProperCase = (str: string) => {
  const words = str?.toLowerCase().split(/[-_]/) || [];

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

export const toCamelCase = (str = ""): string => {
  return (
    str
      ?.toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, " ")
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, "")
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      .replace(/ (.)/g, function ($1) {
        return $1.toUpperCase();
      })
      // Removes spaces
      .replace(/ /g, "")
  );
};

export const toPercent = (value: unknown, decimal = 2) => {
  let num: number;

  if (typeof value === "number") num = value;
  else if (typeof value === "string") num = parseFloat(value);
  else return undefined;

  if (isNaN(num)) return undefined;

  return (num * 100).toFixed(decimal) + "%";
};

export const recursiveToCamel = (item: unknown): unknown => {
  const toCamel = (str: string) =>
    str.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, ""));

  const isObject = (item: unknown): boolean =>
    item === Object(item) && typeof item !== "function" && !Array.isArray(item);

  if (isObject(item))
    return Object.fromEntries(
      Object.entries(item as Record<string, unknown>).map(
        ([key, value]: [string, unknown]) => [
          toCamel(key),
          recursiveToCamel(value),
        ]
      )
    );

  if (Array.isArray(item))
    return item.map((el: unknown) => recursiveToCamel(el));

  return item;
};
