import { ACTIVITY_TYPES } from "@mc/components/ReplyTimeline/constants/activities";

export const CASE_STATUS = {
  AWAITING_INFO: "AWAITING_INFO",
  CANCELLED: "CANCELLED",
  CLOSED: "CLOSED",
  IN_PROGRESS: "IN_PROGRESS",
  NEW: "NEW",
  OPEN: "OPEN",
  RESOLVED: "RESOLVED",
};

export const CASE_PRIORITY = {
  HIGH: "HIGH",
  CRITICAL: "CRITICAL",
  MODERATE: "MODERATE",
  LOW: "LOW",
};

export const CASE_STATUS_FILTERS = {
  active: {
    id: 1,
    value: "Active",
    name: "Active",
    param: "active",
    includes: [
      CASE_STATUS.OPEN,
      CASE_STATUS.AWAITING_INFO,
      CASE_STATUS.NEW,
      CASE_STATUS.IN_PROGRESS,
    ],
  },
  "need-response": {
    id: 2,
    value: "Need Response",
    name: "Need Resonse",
    param: "need-response",
    includes: [CASE_STATUS.AWAITING_INFO],
  },
  resolved: {
    id: 3,
    value: "Resolved",
    name: "Resolved",
    param: "resolved",
    includes: [CASE_STATUS.RESOLVED, CASE_STATUS.CLOSED, CASE_STATUS.CANCELLED],
  },
};

export const CASE_WAITING_ON_BANNER_MESSAGE = {
  VENDOR_RESPONSE:
    "We are waiting for information from a third-party and will update the case as soon as we can.",
  CUSTOMER_RESPONSE:
    "We need your response to continue servicing this request.",
  CUSTOMER_HOLD:
    "This case is on hold. We will follow up with you soon about the status of the case.",
  MAJOR_CASE_RESOLUTION:
    "A major incident has been created for this case. When the incident has resolved, this case will close.",
  MAINTENANCE_WINDOW:
    "A change has been scheduled. We will update the case when implementation has begun.",
};

export const CASE_LIST_HEADERS = {
  SUBJECT: { label: "Subject", sort: "NUMBER", width: "90%" },
  CATEGORY: { label: "Category", sort: "CATEGORY" },
  REPLIES: { label: "Replies", sort: "REPLIES" },
  PRIORITY: { label: "Priority", sort: "PRIORITY" },
  OPENED: { label: "Opened", sort: "OPENED_AT", width: "10%" },
  ASSIGNEE: { label: "Assignee", sort: "ASSIGNED_TO__FIRST_NAME" },
};

export const REPLY_TYPES = new Set([
  ACTIVITY_TYPES.COMMENT,
  ACTIVITY_TYPES.EMAIL_SENT,
  ACTIVITY_TYPES.EMAIL_RECEIVED,
]);

export const NEW_CASE_FORM_AWS_REGIONS = [
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
  "eu-central-1",
  "eu-north-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "ca-central-1",
  "sa-east-1",
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
];
