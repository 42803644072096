import clsx from "clsx";
import { FC } from "react";

export type TBadgeSize = "small" | "default";

export type TBadgeStatus =
  | "default"
  | "active"
  | "positive"
  | "warning"
  | "negative"
  | "inverse";

export interface BadgePropsStrict {
  /** Set the component HTML element. Warning: Using non-standard markup can produce accessibility issues. */
  as?: "span" | "div" | "a";

  /** Additional classes */
  className?: string;

  /** Badge content */
  label: string | number;

  /** Badge status/color */
  status?: TBadgeStatus;

  /** Badge sizes */
  size?: TBadgeSize;

  /** Gives the Badge a more visually intense color */
  strong?: boolean;
}

interface BadgeProps extends BadgePropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

export const Badge: FC<BadgeProps> = ({
  as: Element = "span",
  className,
  label,
  status = "default",
  size = "default",
  strong,
  ...props
}) => {
  const isDefault = status === "default" || !status;
  const isLight = !strong;
  const isDark = strong;

  const classes = clsx(
    "inline-flex items-center whitespace-nowrap rounded-full px-[6px] py-[2px] text-center font-bold leading-none",
    {
      // Light colors
      "bg-layout-border text-default": isLight && isDefault,
      "bg-emerald-50 text-emerald-500": isLight && status === "positive",
      "bg-amber-100 bg-opacity-70 text-amber-500":
        isLight && status === "warning",
      "bg-red-50 text-red-500": isLight && status === "negative",
      "bg-blue-50 text-blue-700": isLight && status === "active",
      "bg-layout-bg text-action": isLight && status === "inverse",

      // Dark colors
      "bg-layout-inverse text-inverse": isDark && isDefault,
      "bg-emerald-500 text-emerald-50": isDark && status === "positive",
      "bg-amber-500 text-amber-50": isDark && status === "warning",
      "bg-red-500 text-red-50": isDark && status === "negative",
      "bg-layout-active text-inverse":
        isDark && (status === "active" || status === "inverse"),
      antialiased: isDark,

      // Sizes
      "text-2xs": size === "small",
      "text-sm": size === "default",

      // Overrides
      "cursor-pointer": !!props.onClick,
    },
    className
  );

  return (
    <Element
      className={classes}
      data-component="Badge"
      data-testid="badge"
      {...props}
    >
      {label}
    </Element>
  );
};
