import { useAccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, PATHS, SKUS } from "@mc/constants";
import { useMarketplaceRegistrations } from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

export const DefaultPathRedirect = () => {
  const rootPath = useDefaultPath();
  return <Navigate replace to={rootPath} />;
};

const useDefaultPath = () => {
  const { DASHBOARD, CASES, SCORECARDS } = FEATURE_FLAGS;
  const { hasPendingOnboardings } = useMarketplaceRegistrations();
  const hasDashboardAccess = useAccessRequired({ feature: DASHBOARD });
  const hasCasesAccess = useAccessRequired({ feature: CASES });
  const hasScorecardAccess = useAccessRequired({ feature: SCORECARDS });
  const hasCloudEngagementsAccess = useAccessRequired({
    entitlement: SKUS.CLOUD_ENGAGEMENTS,
  });

  if (hasPendingOnboardings) return PATHS.ONBOARDING;
  if (hasCloudEngagementsAccess) return PATHS.ENGAGEMENTS_PROJECTS;
  if (hasDashboardAccess) return PATHS.DASHBOARD;
  if (hasCasesAccess) return PATHS.SUPPORT_CASES;
  if (hasScorecardAccess) return PATHS.CLOUD_SCORE;
  return PATHS.ACCOUNTS;
};
