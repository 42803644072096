import { toast } from "@m/ui";
import { dt, fromDollars } from "@m/utils";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES_ENGAGEMENTS_STATUS } from "../constants";
import { IEngagementStatus } from "../types";

import { GET_ENGAGEMENT_STATUS } from "./useEngagementStatus";

export const UPDATE_ENGAGEMENT_STATUS = gql`
  mutation UpdateEngagementStatus(
    $budgetInput: UpdateBudgetStatusInput!
    $workInput: UpdateWorkStatusInput!
  ) {
    updateBudgetStatus(input: $budgetInput) {
      ok
      message
    }
    updateWorkStatus(input: $workInput) {
      ok
      message
    }
  }
`;

export const useUpdateEngagementStatus = (engagementId: string) => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_STATUS.UPDATE_SUCCESS);
  const onError = () => toast.error(MESSAGES_ENGAGEMENTS_STATUS.UPDATE_ERROR);

  const [mutate, { loading }] = useMutation(UPDATE_ENGAGEMENT_STATUS, {
    onCompleted,
    onError,
  });

  const updateEngagementStatus = (updates: IEngagementStatus) => {
    const { budgetStatus, workStatus } = updates;

    const formattedStartDate = dt.fromISO(workStatus.startDate).toUTC().toISO();
    const formattedDueDate = dt.fromISO(workStatus.dueDate).toUTC().toISO();
    const formattedOriginalSof = fromDollars(budgetStatus.originalSof);
    const formattedChangeOrders = fromDollars(budgetStatus.changeOrders);
    const formattedTotalCredits = fromDollars(budgetStatus.totalCreditsApplied);
    const formattedBudgetSpent = fromDollars(budgetStatus.budgetSpent);
    const formattedEstimation = fromDollars(budgetStatus.estimateAtCompletion);

    return mutate({
      variables: {
        budgetInput: {
          engagementId,
          id: budgetStatus.id,
          status: budgetStatus.status,
          originalSof: formattedOriginalSof,
          changeOrders: formattedChangeOrders,
          totalCreditsApplied: formattedTotalCredits,
          budgetSpent: formattedBudgetSpent,
          estimateAtCompletion: formattedEstimation,
        },
        workInput: {
          engagementId,
          id: workStatus.id,
          status: workStatus.status,
          startDate: formattedStartDate,
          dueDate: formattedDueDate,
          percentageComplete: workStatus.percentageComplete,
        },
      },
      refetchQueries: [
        {
          query: GET_ENGAGEMENT_STATUS,
          variables: {
            id: engagementId,
          },
        },
      ],
    });
  };

  return [updateEngagementStatus, { loading }] as const;
};
