import { PATHS } from "@mc/constants";
import { useEligibleProductsForInvitation } from "@mc/features/Onboarding";
import {
  usePendingCloudEngagementsOnboarding,
  usePendingCloudGatewayOnboarding,
  usePendingCloudScoreOnboarding,
} from "@mc/features/Onboarding/hooks";
import { Navigate } from "@mc/router";

/** Redirects to the current onboarding path. */
export const OnboardingPathRedirect = () => {
  const { hasProductsForInvitation } = useEligibleProductsForInvitation();
  const pendingCloudEngagementsOnboarding =
    usePendingCloudEngagementsOnboarding();
  const pendingCloudGatewayOnboarding = usePendingCloudGatewayOnboarding();
  const pendingCloudScoreOnboarding = usePendingCloudScoreOnboarding();

  const currentPath = getCurrentOnboardingPath(
    hasProductsForInvitation,
    pendingCloudEngagementsOnboarding,
    pendingCloudGatewayOnboarding,
    pendingCloudScoreOnboarding
  );
  return <Navigate replace to={currentPath} />;
};

const getCurrentOnboardingPath = (
  hasProductsForInvitation: boolean,
  pendingCloudEngagementsOnboarding: object | null,
  pendingCloudGatewayOnboarding: object | null,
  pendingCloudScoreOnboarding: object | null
) => {
  if (hasProductsForInvitation) {
    return PATHS.ONBOARDING_PRODUCT_INVITE;
  }
  if (pendingCloudScoreOnboarding !== null) {
    return PATHS.ONBOARDING_CLOUD_SCORE;
  }
  if (pendingCloudGatewayOnboarding !== null) {
    return PATHS.ONBOARDING_CLOUD_GATEWAY;
  }
  if (pendingCloudEngagementsOnboarding !== null) {
    return PATHS.ONBOARDING_CLOUD_ENGAGEMENTS;
  }
  return PATHS.ROOT;
};
