import clsx from "clsx";
import PT from "prop-types";
import { useContext, useState } from "react";

import { ActivityContext } from "../contexts";

export const ActivityLine = ({ lineWidth = 8, activity, index, x, y }) => {
  const [svgElement, setSvgElement] = useState();
  const [hover, setHover] = useState(false);

  const {
    setActivity,
    setActivityLineElement,
    pinnedActivityIndex,
    setPinnedActivityIndex,
  } = useContext(ActivityContext);

  const isPinned = pinnedActivityIndex === index;
  const isPinActive = pinnedActivityIndex !== undefined;
  const popoverDisabled = isPinActive;

  const openPopover = () => {
    setActivityLineElement(svgElement);
    setActivity(activity);
  };

  const closePopover = () => {
    setActivityLineElement();
    setActivity();
  };

  const handleClick = () => {
    if (isPinned) setPinnedActivityIndex();
    else {
      setPinnedActivityIndex(index);
      openPopover();
    }
  };

  const handleMouseEnter = () => {
    setHover(true);
    if (!popoverDisabled) openPopover();
  };

  const handleMouseLeave = () => {
    setHover(false);
    if (!popoverDisabled) closePopover();
  };

  return (
    <g
      className="cursor-pointer"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={setSvgElement}
    >
      <rect
        x={x - lineWidth / 2}
        width={lineWidth}
        height={y}
        fill="transparent"
      />
      <path
        className={clsx({
          "stroke-orange-300": !hover && !isPinned && popoverDisabled,
          "stroke-orange-500": !hover && !isPinned,
          "stroke-orange-800": hover && !isPinned,
          "stroke-blue-600": isPinned,
        })}
        d={`M ${x},0 V ${y} `}
        strokeOpacity="0.75"
        strokeDasharray="3 3"
      />
    </g>
  );
};

ActivityLine.propTypes = {
  lineWidth: PT.number,
  activity: PT.object.isRequired,
  index: PT.number.isRequired,
  x: PT.number.isRequired,
  y: PT.number.isRequired,
};
