import { Card } from "@m/ui";

import { Page } from "@mc/components/Page";
import { MissionAdminPageTitle } from "@mc/features/MissionAdmin";

import {
  ActiveUsersReport,
  CasesReport,
  TeamAssignmentReport,
} from "../components";

export const ReportsPage = () => (
  <Page data-testid="reports-page" title={<MissionAdminPageTitle />}>
    <Card className="flex flex-col gap-2 p-3">
      <ActiveUsersReport />
      <TeamAssignmentReport />
      <CasesReport />
    </Card>
  </Page>
);
