import clsx from "clsx";
import { ComponentType, ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import { useAuth } from "@m/login";
import { Badge, TBadgeSize, Tooltip } from "@m/ui";

import { generateCompanyPath } from "@mc/utils/companyPath";

import { useSidebar } from "../contexts";

interface Props extends NavLinkProps {
  ariaLabel?: string;
  className?: string;
  count?: number;
  icon?: ComponentType<any>;
  loading?: boolean;
  size?: TBadgeSize;
  title: string;
  to: string;
  isNew?: boolean;
  popupContent?: ReactNode;
}

export const SidebarLink = ({
  ariaLabel,
  className,
  count,
  icon: Icon,
  loading = false,
  size = "default",
  title,
  to = "",
  isNew = false,
  popupContent,
  ...props
}: Props) => {
  const { user } = useAuth();
  const { closeSidebar } = useSidebar();

  return (
    <NavLink
      aria-label={ariaLabel || `Go to ${title}`}
      className={({ isActive }) =>
        clsx(
          "flex items-center space-x-1 rounded-lg px-1 py-0.5 font-medium transition",
          className,
          {
            "bg-layout-active/[.07] text-action": isActive,

            "text-xs": size === "small",
            "text-sm": size === "default",
          }
        )
      }
      onClick={closeSidebar}
      to={generateCompanyPath(to, user.company.slug)}
      {...props}
    >
      {({ isActive }) => (
        <>
          <span className="inline-flex grow items-center gap-1">
            {Icon && <Icon className="h-3 w-3 stroke-2" />}
            {title}
          </span>
          {!loading && count !== undefined && (
            <Badge
              aria-label={`${title} Count`}
              label={count}
              size="small"
              status={isActive ? "active" : "default"}
              strong={isActive}
            />
          )}
          {isNew &&
            (popupContent ? (
              <Tooltip
                className="w-full p-2"
                color="light"
                content={popupContent}
                side="right"
              >
                <Badge
                  className="hover:cursor-help"
                  label="New"
                  status="positive"
                  strong
                  size="small"
                />
              </Tooltip>
            ) : (
              <Badge label="New" status="positive" strong size="small" />
            ))}
        </>
      )}
    </NavLink>
  );
};
