import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

import {
  EngagementDecisionStatus,
  EngagementMilestoneStatus,
  RiskLogItemStatus,
} from "@m/api/public/types";

import { EmptyCircleIcon } from "@mc/icons";

import { DETAIL_ITEM_STATUS, ENGAGEMENTS_MILESTONE_STATUS } from "../constants";
import {
  FilledCircleIcon,
  HalfFilledCircleIcon,
  QuarterFilledCircleIcon,
  ThreeQuartersFilledCircleIcon,
} from "../icons";

export const EngagementDetailStatus = ({
  percentageComplete,
  status,
}: {
  percentageComplete?: number;
  status:
    | RiskLogItemStatus
    | EngagementMilestoneStatus
    | EngagementDecisionStatus
    | ENGAGEMENTS_MILESTONE_STATUS
    | DETAIL_ITEM_STATUS;
}) => {
  let StatusIcon;

  const isOpen = status === DETAIL_ITEM_STATUS.OPEN;
  const isResolved =
    status === DETAIL_ITEM_STATUS.RESOLVED ||
    status === EngagementDecisionStatus.Completed;
  const isArchived = status === DETAIL_ITEM_STATUS.ARCHIVED;

  switch (true) {
    case isResolved:
      StatusIcon = (
        <CheckCircleIcon
          aria-label="Resolved"
          className="h-2.5 w-2.5 text-action"
        />
      );
      break;
    case isArchived:
      StatusIcon = (
        <XCircleIcon
          aria-label="Archived"
          className="h-2.5 w-2.5 text-accent"
        />
      );
      break;
    case isOpen && !percentageComplete:
    case percentageComplete >= 0 && percentageComplete < 25:
      StatusIcon = (
        <EmptyCircleIcon
          aria-label={
            percentageComplete !== undefined
              ? `${percentageComplete}% Complete`
              : "Open"
          }
          className="h-2.5 w-2.5 stroke-1 text-accent"
        />
      );
      break;
    case percentageComplete >= 25 && percentageComplete < 50:
      StatusIcon = (
        <QuarterFilledCircleIcon
          aria-label={`${percentageComplete}% Complete`}
          className="h-2.5 w-2.5 text-action"
        />
      );
      break;
    case percentageComplete >= 50 && percentageComplete < 75:
      StatusIcon = (
        <HalfFilledCircleIcon
          aria-label={`${percentageComplete}% Complete`}
          className="h-2.5 w-2.5 text-action"
        />
      );
      break;
    case percentageComplete >= 75 && percentageComplete < 100:
      StatusIcon = (
        <ThreeQuartersFilledCircleIcon
          aria-label={`${percentageComplete}% Complete`}
          className="h-2.5 w-2.5 text-action"
        />
      );
      break;
    case percentageComplete >= 100:
      StatusIcon = (
        <FilledCircleIcon
          aria-label={`${percentageComplete}% Complete`}
          className="h-2.5 w-2.5 text-action"
        />
      );
      break;
    default:
      StatusIcon = (
        <EmptyCircleIcon
          aria-label="Open"
          className="h-2.5 w-2.5 text-accent"
        />
      );
      break;
  }

  return (
    <div className=" px-1" data-testid="engagement-detail-status">
      {StatusIcon}
    </div>
  );
};
