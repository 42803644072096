import { useState } from "react";

import { ButtonRadioGroup, DatePicker, Field, Input } from "@m/ui";
import { dt } from "@m/utils";

export const DevOpsDeskRequestForm = () => {
  const [maintenanceWindowNeeded, setMaintenanceWindowNeeded] =
    useState<MaintenanceWindowNeeded>("no");
  const [preferredContactMethod, setPreferredContactMethod] =
    useState<PreferredContactMethods>("slack");

  const handleChangeMaintenanceWindowNeeded = (
    value: MaintenanceWindowNeeded
  ) => {
    setMaintenanceWindowNeeded(value);
  };
  const handleChangePreferredContactMethod = (
    value: PreferredContactMethods
  ) => {
    setPreferredContactMethod(value);
  };

  return (
    <>
      <Field row="2/3" label="Repository URL" htmlFor="repository-url">
        <Input
          id="repository-url"
          name="repositoryUrlInput"
          placeholder="https://github.com/user/repository"
        />
      </Field>
      <Field row="2/3" label="Maintenance Window Needed">
        <ButtonRadioGroup
          className="w-full"
          name="maintenanceWindowNeededRadio"
          onChange={handleChangeMaintenanceWindowNeeded}
          options={[
            { value: "no", label: "No" },
            { value: "yes", label: "Yes" },
          ]}
          value={maintenanceWindowNeeded}
          width="fill-even"
        />
      </Field>
      <Field
        htmlFor="desired-completion-date"
        label="Desired Completion Date"
        row="2/3"
      >
        <DatePicker
          className="w-full"
          id="desired-completion-date"
          min={dt.now().plus({ days: 2 }).toISODate()}
          name="desiredCompletionDateInput"
        />
      </Field>

      <hr />

      <Field row="2/3" label="Preferred Contact Method">
        <ButtonRadioGroup
          className="w-full"
          name="preferredContactMethodRadio"
          onChange={handleChangePreferredContactMethod}
          options={[
            { value: "slack", label: "Slack" },
            { value: "email", label: "Email" },
          ]}
          value={preferredContactMethod}
          width="fill-even"
        />
      </Field>
      <Field row="2/3" label="Scheduling Link" htmlFor="scheduling-link">
        <Input
          id="scheduling-link"
          name="schedulingLinkInput"
          placeholder="https://calendly.com/user/"
        />
      </Field>
    </>
  );
};

export type PreferredContactMethods = "slack" | "email";
export type MaintenanceWindowNeeded = "no" | "yes";
