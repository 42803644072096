import type { ComponentPropsWithoutRef } from "react";

import { Buttons } from "@m/ui";

export const ModalActions = ({
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) => (
  <Buttons align="right" data-component="ModalActions" {...props}>
    {children}
  </Buttons>
);
