import { RouteObject } from "react-router-dom";

import {
  CloudScoreDetailsPage,
  CloudScoreSummaryPage,
} from "@mc/features/CloudScore";

import { cloudScoreOverlayRoutes } from "./cloudScoreOverlayRoutes";

export const cloudScoreRoutes: RouteObject[] = [
  {
    path: "",
    element: <CloudScoreSummaryPage />,
    children: cloudScoreOverlayRoutes,
  },
  {
    path: "pillars/:pillarId",
    element: <CloudScoreDetailsPage />,
    children: cloudScoreOverlayRoutes,
  },
];
