export interface CloudFormationTemplateUrlConfig {
  stackName: string;
  templateFileName: string;
}

export const CLOUD_FORMATION_TEMPLATE_URL_CONFIGS: {
  [TEMPLATE_KEY: string]: CloudFormationTemplateUrlConfig;
} = {
  ONBOARDING: {
    stackName: "mission-iam-access",
    templateFileName: "onboarding.yml",
  },
  CLOUD_SCORE: {
    stackName: "mission-cloudscore-access",
    templateFileName: "scorecard.yml",
  },
  MISSION_CONTROL_GATEWAY: {
    stackName: "mission-control-gateway-access",
    templateFileName: "mission-control-gateway.yml",
  },
  MISSION_CONTROL_GATEWAY_BILLING: {
    stackName: "mission-control-gateway-billing-access",
    templateFileName: "mission-control-gateway-billing.yml",
  },
  MISSION_CONTROL_GATEWAY_LINKED_ACCOUNT: {
    stackName: "mission-control-gateway-linked-account-access",
    templateFileName: "mission-control-gateway-linked-accounts.yml",
  },
};

export const CLOUD_FORMATION_IAM_ROLES = {
  MISSION_CLOUD_SCORE: "MissionCloudScore",
  MISSION_CONTROL_GATEWAY: "MissionControlGateway",
  MISSION_CONTROL_GATEWAY_BILLING: "MissionControlGatewayBilling",
  MISSION_ENGINEERING: "MissionEngineering",
  MISSION_INVENTORY: "MissionInventory",
};

export const CLOUD_FORMATION_POLL_INTERVAL = 15000; // milliseconds
export const CLOUD_FORMATION_MAX_ATTEMPTS = 40; // 40 attempts * 15 seconds = 10 minutes

export const CLOUD_FORMATION_REGION = "us-east-1";

export const CLOUD_FORMATION_URL_BASE =
  "https://console.aws.amazon.com/cloudformation/home";
export const CLOUD_FORMATION_TEMPLATE_URL_BASE =
  "https://s3-us-west-2.amazonaws.com/bootstrap.missioncloud.com/src";
