import {
  RadioGroup,
  RadioGroupProps,
  RadioOptionProps,
} from "@headlessui/react";
import clsx from "clsx";
import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

type value = ComponentPropsWithoutRef<"input">["value"] | boolean;

interface ButtonRadioGroupProps extends RadioGroupProps<ElementType, value> {
  options: OptionProps[];
  width?: "inline" | "fill" | "fill-even";
}

interface OptionProps extends RadioOptionProps<ElementType, value> {
  label: string | ReactNode;
}

export const ButtonRadioGroup = ({
  className,
  options,
  width = "inline",
  ...props
}: ButtonRadioGroupProps) => {
  const inline = width === "inline";
  const fill = width === "fill";
  const fillEven = width === "fill-even";

  const classes = clsx(className, {
    "inline-flex self-start": inline,
    flex: fill || fillEven,
  });
  const optionClasses = clsx({
    grow: fill,
    "flex-1": fillEven,
  });
  return (
    <RadioGroup
      className={classes}
      {...props}
      data-component="ButtonRadioGroup"
    >
      {options.map((option, i) => (
        <Option key={i} className={optionClasses} {...option} />
      ))}
    </RadioGroup>
  );
};

const Option = ({ className, label, ...props }: OptionProps) => {
  const classes = clsx(
    className,
    "flex justify-center items-center cursor-pointer border text-sm font-medium first:rounded-l last:rounded-r px-2 py-1 ml-[-1px] first:ml-0 select-none form-control__focus-visible",

    // Hover States
    "ui-not-checked:hover:border-blue-500 ui-not-checked:hover:text-action ui-not-checked:hover:relative",

    // Default styling
    "ui-not-checked:border-layout-border ui-not-checked:text-default",

    // Active styling
    "ui-checked:border-blue-500 ui-checked:bg-blue-50 ui-checked:text-action ui-checked:relative"
  );
  return (
    <RadioGroup.Option className={classes} {...props}>
      {label}
    </RadioGroup.Option>
  );
};
