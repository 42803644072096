import { MarketplaceRegistration } from "@m/api/public/types";

import { SUPPORTED_ONBOARDING_SKUS } from "@mc/constants";

/**
 * Filter marketplace registrations to only those supported by Mission Control.
 */
export const filterSupportedMarketplaceRegistrations = (
  marketplaceRegistrations: Partial<MarketplaceRegistration>[]
) =>
  marketplaceRegistrations.filter(({ sku }) =>
    SUPPORTED_ONBOARDING_SKUS.includes(sku)
  );
