import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Field, Fields, Input } from "@m/ui";

import { PATHS } from "@mc/constants";
import {
  CompanySizeInput,
  JobIndustryInput,
  JobLevelInput,
} from "@mc/features/UserProfile/components";

export const OnboardingCompleteProfilePage = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState();

  const submitForm = (fields) => {
    const jobRole = fields.jobRole.value;
    const jobLevel = fields["user-profile-job-level"].value;
    const industry = fields.jobIndustry.value;
    const companySize = fields.companySize.value;
    if (!jobRole || !jobLevel || !industry || !companySize) {
      setErrorMessage("Please complete all fields.");
      return;
    }
    console.log({ jobRole, jobLevel, industry, companySize }); // todo: wire-up
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage();
    submitForm(e.currentTarget.elements);
    navigate(PATHS.CLOUD_SCORE);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-screen-md"
      data-testid="onboarding-cloud-score-complete-profile-page"
    >
      <Fields>
        <div className="space-y-2 text-center">
          <h1 className="p-0 text-3xl font-semibold">Complete your profile</h1>

          {errorMessage ? (
            <p className="m-0 text-sm text-red-500">{errorMessage}</p>
          ) : (
            <p className="m-0 text-lg text-gray-500">
              Please complete your profile while we
              <br /> generate your Cloud Score.
            </p>
          )}
        </div>

        <Field label="Job Role" htmlFor="jobRole">
          <Input id="jobRole" />
        </Field>
        <JobLevelInput />
        <JobIndustryInput />
        <CompanySizeInput />

        <Button
          className="w-small float-right"
          kind="primary"
          size="large"
          type="submit"
        >
          Continue
        </Button>
      </Fields>
    </form>
  );
};
