import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { PropsWithChildren, useState } from "react";

import { useMatch } from "@mc/router";

interface Props extends PropsWithChildren {
  defaultOpen?: boolean;
  path?: string;
  title: string;
}

export const DropdownLinks = ({
  children,
  defaultOpen = true,
  path = "",
  title,
}: Props) => {
  const match = useMatch(path);
  const [open, setOpen] = useState(defaultOpen || match !== null);

  const toggleMenu = () => setOpen((prev) => !prev);
  const ChevronIcon = open ? ChevronDownIcon : ChevronLeftIcon;

  return (
    <div aria-label={`${title} Links`}>
      <button className="flex w-full items-center" onClick={toggleMenu}>
        <div className="grow">
          <span className="flex items-center space-x-1 rounded-lg px-1 py-0.5 font-medium transition">
            {title}
          </span>
        </div>
        <ChevronIcon className="mr-[11px] inline w-2" />
      </button>
      <div className="flex flex-col space-y-[1px]">{open && children}</div>
    </div>
  );
};
