import { ChangeEvent, useState } from "react";

import { TFormEventHandler } from "@m/types";
import { Button, Buttons } from "@m/ui";
import { dt } from "@m/utils";

import { IBudgetStatus, IEngagementStatus, IWorkStatus } from "../types";

import { BudgetStatusForm } from "./BudgetStatusForm";
import { WorkStatusForm } from "./WorkStatusForm";

export interface IEngagementsStatusFormProps {
  workStatus: IWorkStatus;
  budgetStatus: IBudgetStatus;
  loading: boolean;
  onClose: () => void;
  onSubmit: (updatedStatus: IEngagementStatus) => void;
}

export const EngagementsStatusForm = ({
  workStatus,
  budgetStatus,
  loading,
  onClose,
  onSubmit,
}: IEngagementsStatusFormProps) => {
  const [updatedWorkStatus, setWorkStatus] = useState<IWorkStatus>(workStatus);
  const [updatedBudgetStatus, setBudgetStatus] =
    useState<IBudgetStatus>(budgetStatus);

  const handleUpdateWorkStatus = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let newValue: string;

    const {
      target: { name, value },
    } = e;

    newValue = value;

    // make sure the date value comes through in a known format
    // just in case the user tries to enter something different
    if (name === "startDate")
      newValue = dt.fromISO(value).toFormat("yyyy-MM-dd");
    if (name === "dueDate") newValue = dt.fromISO(value).toFormat("yyyy-MM-dd");

    setWorkStatus((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleUpdateBudgetStatus = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { name, value },
    } = e;

    setBudgetStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit: TFormEventHandler = (e) => {
    e.preventDefault();

    onSubmit({
      workStatus: updatedWorkStatus,
      budgetStatus: updatedBudgetStatus,
    });
  };

  return (
    <form className="flex flex-col space-y-0.5" onSubmit={handleSubmit}>
      <h2 className="pb-1 text-base font-bold">Work</h2>
      {updatedWorkStatus && (
        <WorkStatusForm
          workStatus={updatedWorkStatus}
          onUpdate={handleUpdateWorkStatus}
        />
      )}
      <h2 className="pb-1 text-base font-bold">Budget</h2>
      {updatedBudgetStatus && (
        <BudgetStatusForm
          budgetStatus={updatedBudgetStatus}
          onUpdate={handleUpdateBudgetStatus}
        />
      )}
      <Buttons align="right" className="!mt-2">
        <Button loading={loading} type="submit" kind="primary">
          Update
        </Button>
        <Button kind="primary" fill="none" onClick={onClose}>
          Cancel
        </Button>
      </Buttons>
    </form>
  );
};
