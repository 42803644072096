import { Badge, Field, Fields, Input, Spinner } from "@m/ui";

import { Page } from "@mc/components/Page";
import { SettingsPageTitle } from "@mc/features/Settings";
import { useIsStaff } from "@mc/hooks";

import {
  useUpdateFirstName,
  useUpdateJobLevel,
  useUpdateJobRole,
  useUpdateLastName,
  useUpdatePhoneNumber,
  useUserProfile,
} from "../api";
import {
  JobLevelInput,
  PhoneNumberInput,
  StaffPhotoOptOut,
  TextInput,
} from "../components";
import { MESSAGES_USER_PROFILE } from "../constants";

export function UserProfilePage() {
  const {
    loading,
    error,
    data: { profile },
  } = useUserProfile();

  const isStaff = useIsStaff();

  const [updateFirstName, { ok: firstNameOk, loading: firstNameLoading }] =
    useUpdateFirstName(profile);

  const [updateLastName, { ok: lastNameOk, loading: lastNameLoading }] =
    useUpdateLastName(profile);

  const [
    updatePhoneNumber,
    { ok: phoneNumberOk, loading: phoneNumberLoading },
  ] = useUpdatePhoneNumber(profile);

  const [updateJobRole, { ok: jobRoleOk, loading: jobRoleLoading }] =
    useUpdateJobRole(profile);

  const [updateJobLevel] = useUpdateJobLevel(profile);

  const {
    firstName,
    lastName,
    email,
    currentCompany,
    jobRole,
    jobLevel,
    businessPhone,
  } = profile;

  const ready = !loading && !error;

  return (
    <Page
      data-testid="user-profile-page"
      title={<SettingsPageTitle />}
      width={isStaff ? "default" : "small"}
    >
      {ready && (
        <div className="flex flex-col items-start gap-6 lg:flex-row">
          <form className="w-full">
            <Fields>
              <TextInput
                fieldLabel="First Name"
                onInputChange={updateFirstName}
                defaultValue={firstName}
                loading={firstNameLoading}
                ok={firstNameOk}
              />
              <TextInput
                fieldLabel="Last Name"
                onInputChange={updateLastName}
                defaultValue={lastName}
                loading={lastNameLoading}
                ok={lastNameOk}
              />
              <PhoneNumberInput
                phoneNumber={
                  typeof businessPhone === "string" ? businessPhone : ""
                }
                onPhoneNumberChange={updatePhoneNumber}
                ok={phoneNumberOk}
                loading={phoneNumberLoading}
              />
              <TextInput fieldLabel="Email" defaultValue={email} disabled />
              <TextInput
                fieldLabel="Job Role"
                onInputChange={updateJobRole}
                defaultValue={jobRole}
                loading={jobRoleLoading}
                ok={jobRoleOk}
              />
              <JobLevelInput
                jobLevel={jobLevel}
                onJobLevelChange={updateJobLevel}
              />
            </Fields>
          </form>

          {isStaff && (
            <Fields className="w-full rounded border bg-zinc-50 p-3 lg:max-w-[24rem]">
              {currentCompany.mcn && (
                <Field
                  label="Mission Customer Number"
                  labelId="user-profile-mcn"
                >
                  <Input
                    aria-labelledby="user-profile-mcn"
                    value={currentCompany.mcn}
                    disabled
                  />
                </Field>
              )}
              <Field
                label="Connectwise Tags"
                labelId="user-profile-connectwise-types"
              >
                {currentCompany?.types && (
                  <div
                    className="flex flex-wrap gap-1"
                    aria-labelledby="user-profile-connectwise-types"
                  >
                    {[...currentCompany.types] // Can't sort non-reassignable
                      .sort()
                      .map((type) => (
                        <Badge key={`cw-profile-type-${type}`} label={type} />
                      ))}
                  </div>
                )}

                {!currentCompany?.types && (
                  <span className="text-accent">&ndash;</span>
                )}
              </Field>
              <StaffPhotoOptOut />
            </Fields>
          )}
        </div>
      )}

      {!loading && error && <p>{MESSAGES_USER_PROFILE.USER_PROFILE_ERROR}</p>}

      {loading && <Spinner />}
    </Page>
  );
}
