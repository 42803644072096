import PT from "prop-types";

import { useScorecardTrendDaily } from "../api";
import {
  CLOUD_SCORE_MESSAGES,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { useLoadScoreChange } from "../hooks";
import { getDailyTrendActivities, getDailyTrendLines } from "../utils";

import { ScorecardTrendGraphBase } from "./ScorecardTrendGraphBase";

const { fullText, intervalCount } = SCORECARD_TREND_TIME_RANGES.ONE_MONTH;

export const ScorecardTrendGraph1Month = ({ pillarId }) => {
  const {
    data: { dailyScoreResults },
    loading,
  } = useScorecardTrendDaily({ days: intervalCount });

  useLoadScoreChange({
    scoreResults: dailyScoreResults,
    pillarId,
  });

  const { activeTrendLine, allTrendLines } = getDailyTrendLines(
    dailyScoreResults,
    pillarId
  );

  const activities = getDailyTrendActivities(dailyScoreResults, pillarId);

  return (
    <ScorecardTrendGraphBase
      activeTrendLine={activeTrendLine}
      activities={activities}
      allTrendLines={allTrendLines}
      ariaLabel={`${fullText} Cloud Score Trend Graph`}
      axisBottom={{
        format: "%b %d",
        tickValues: "every 2 days",
      }}
      emptyMessage={CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_DAILY_EMPTY}
      loading={loading}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
      }}
    />
  );
};

ScorecardTrendGraph1Month.propTypes = {
  pillarId: PT.string.isRequired,
};
