import { Account } from "@m/api/public/types";

import {
  EngagementActivityFeedItemBudgetSpent,
  EngagementActivityFeedItemBudgetStatusChange,
  EngagementActivityFeedItemProjectStart,
} from "../components";

import {
  ENGAGEMENT_ACTIVITY_FEED_GROUPS,
  type EngagementActivityFeedComponent,
  type EngagementActivityFeedGroupConfig,
} from "./activityFeed";

interface EngagementActivityFeedMap {
  [__typename: string]: {
    __typename: string;
    group: EngagementActivityFeedGroupConfig;
    component: EngagementActivityFeedComponent;
  };
}

export const ENGAGEMENT_ACTIVITY_FEED_MAP: EngagementActivityFeedMap = {
  EngagementStartActivityStream: {
    __typename: "EngagementStartActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.projectUpdates.id,
    component: EngagementActivityFeedItemProjectStart,
  },
  EngagementBudgetSpentActivityStream: {
    __typename: "EngagementBudgetSpentActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.budget.id,
    component: EngagementActivityFeedItemBudgetSpent,
  },
  EngagementBudgetStatusChangeActivityStream: {
    __typename: "EngagementBudgetStatusChangeActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.budget.id,
    component: EngagementActivityFeedItemBudgetStatusChange,
  },
} as const;
