import { BeakerIcon } from "@heroicons/react/24/outline";

import { Link, Modal, ModalTitle } from "@m/ui";

const MaintenanceIcon = () => (
  <div className="mx-auto mb-4 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100">
    <BeakerIcon className="h-3 w-3 text-yellow-600" aria-hidden="true" />
  </div>
);

export function MaintenanceDialog() {
  return (
    <Modal open={true} className="text-center">
      <MaintenanceIcon />
      <ModalTitle as="h3" className="text-xl">
        We'll be right back!
      </ModalTitle>
      <div className="my-1">
        <p>Mission Control is temporarily undergoing maintenance.</p>
        <p className="pt-1">
          Check{" "}
          <Link
            href="https://status.missioncloud.com"
            target="_blank"
            rel="noreferrer"
          >
            https://status.missioncloud.com
          </Link>{" "}
          for updates.
        </p>
      </div>
    </Modal>
  );
}
