import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemProjectStart: EngagementActivityFeedComponent =
  ({ after, timestamp }) => {
    if (!after) return null;

    return (
      <EngagementActivityFeedItem timestamp={timestamp}>
        <div className="font-semibold">{after} was shared with you</div>
      </EngagementActivityFeedItem>
    );
  };
