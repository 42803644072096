interface ProductOffering {
  sku: string;
  requiresOnboarding: boolean;
  eligibleForInvitation: boolean;
}

interface ProductCatalog {
  [productKey: string]: ProductOffering;
}

export const productCatalog: ProductCatalog = {
  CLOUD_SCORE: {
    sku: "MC-SAAS-CLOUDSCORE-2023-11",
    requiresOnboarding: true,
    eligibleForInvitation: true,
  },
  CLOUD_GATEWAY: {
    sku: "MC-SAAS-CLOUDGATEWAY-2024-07",
    requiresOnboarding: true,
    eligibleForInvitation: true,
  },
  CLOUD_ENGAGEMENTS: {
    sku: "MC-SAAS-ENGAGEMENTS-DEVOPS-2024-07",
    requiresOnboarding: true,
    eligibleForInvitation: true,
  },
};

export const SKUS = Object.fromEntries(
  Object.entries(productCatalog).map(([productKey, productOffering]) => [
    productKey,
    productOffering.sku,
  ])
);

export const SUPPORTED_ONBOARDING_SKUS = Object.values(productCatalog).reduce(
  (supportedSkus, productOffering) =>
    productOffering.requiresOnboarding
      ? [...supportedSkus, productOffering.sku]
      : supportedSkus,
  []
);

export const SUPPORTED_PRODUCT_INVITATION_SKUS = Object.values(
  productCatalog
).reduce(
  (supportedSkus, productOffering) =>
    productOffering.eligibleForInvitation
      ? [...supportedSkus, productOffering.sku]
      : supportedSkus,
  []
);
