import { gql, useMutation } from "@m/api";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS } from "../constants";

import { GET_ENGAGEMENT_DETAILS } from "./useEngagementDetails";
import { GET_ENGAGEMENTS_LIST } from "./useEngagementsList";

export const UPDATE_ENGAGEMENT = gql`
  mutation UpdateEngagement($input: UpdateEngagementInput!) {
    updateEngagement(input: $input) {
      ok
      message
      engagement {
        title
        visible
        id
      }
    }
  }
`;

export const useUpdateEngagement = (id: string) => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS.UPDATE_ENGAGEMENT_ERROR);

  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS.UPDATE_ENGAGEMENT_SUCCESS);

  const [mutate] = useMutation(UPDATE_ENGAGEMENT, {
    refetchQueries: [
      { query: GET_ENGAGEMENT_DETAILS, variables: { id } },
      GET_ENGAGEMENTS_LIST,
    ],
    onCompleted,
    onError,
  });

  const updateEngagement = ({
    id,
    isEnabled,
    title,
  }: IUpdateEngagementInput) => {
    return mutate({
      variables: {
        input: {
          id,
          visible: isEnabled,
          title,
        },
      },
    });
  };

  return [updateEngagement];
};

interface IUpdateEngagementInput {
  id: string;
  isEnabled: boolean;
  title: string;
}
