import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { PATHS } from "@mc/constants";
import {
  useEligibleProductsForInvitation,
  useMarketplaceRegistrations,
} from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

/**
 * Renders child routes, if the user has finished registration and onboarding.
 *
 * Redirects to registration routes, if the user hasn't finished registration.
 * Redirects to onboarding routes, if the user hasn't finished onboarding.
 */
export const DefaultOutlet = () => {
  const { user } = useAuth();
  const { hasPendingOnboardings } = useMarketplaceRegistrations();
  const { hasProductsForInvitation } = useEligibleProductsForInvitation();

  const forceRegistration = user?.isPendingInvite;
  const forceOnboarding = hasPendingOnboardings || hasProductsForInvitation;

  if (forceRegistration) return <Navigate replace to={PATHS.REGISTER} />;
  if (forceOnboarding) return <Navigate replace to={PATHS.ONBOARDING} />;
  return <Outlet />;
};
