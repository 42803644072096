import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { Sidebar, SidebarProvider } from "@mc/components/Sidebar";
import { Toolbar } from "@mc/components/Toolbar";

export const DefaultLayout = ({ children = <Outlet /> }: PropsWithChildren) => {
  return (
    <SidebarProvider>
      <div className="min-w-screen flex h-full min-h-screen w-full flex-col">
        <Toolbar />
        <div className="flex min-w-[450px] grow">
          <Sidebar />
          <div className="flex grow flex-col overflow-auto">{children}</div>
        </div>
      </div>
    </SidebarProvider>
  );
};
