import {
  RiskLogItemImpact,
  RiskLogItemOwner,
  RiskLogItemProbability,
  RiskLogItemStatus,
  RiskLogItemType,
} from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";
import { AccountAvatar } from "@m/ui";
import { dt } from "@m/utils";

import { generateRandomNumber } from "@mc/testing";

import { IEngagementsDetailsNote, IRiskLogItem } from "../types";

class ExampleRiskLogItem {
  createTime: string;
  databaseId: number;
  dateNeeded: string;
  description: string;
  engagementId: number;
  id: string;
  impact: RiskLogItemImpact;
  itemType: RiskLogItemType;
  mitigationStrategy: string;
  noteList: Partial<IEngagementsDetailsNote>[];
  owner: RiskLogItemOwner;
  probability: RiskLogItemProbability;
  status: RiskLogItemStatus;
  title: string;
  updateTime: string;
  createdBy: AccountAvatar;

  constructor({
    createTime,
    databaseId,
    dateNeeded,
    description,
    engagementId,
    id,
    impact,
    itemType,
    mitigationStrategy,
    noteList,
    owner,
    probability,
    status,
    title,
    updateTime,
    createdBy,
  }: Partial<IRiskLogItem>) {
    this.createTime = createTime || dt.utc().toISO();
    this.databaseId = databaseId || generateRandomNumber();
    this.dateNeeded = dateNeeded || dt.utc().plus({ weeks: 1 }).toISO();
    this.description = description || "";
    this.engagementId = engagementId || 12345;
    this.id = id || generateMockId();
    this.impact = impact || RiskLogItemImpact.Medium;
    this.itemType = itemType || RiskLogItemType.Budget;
    this.mitigationStrategy = mitigationStrategy || "";
    this.noteList = noteList || [];
    this.owner = owner || RiskLogItemOwner.Customer;
    this.probability = probability || RiskLogItemProbability.Medium;
    this.status = status || RiskLogItemStatus.Open;
    this.title = title || "";
    this.updateTime = updateTime || dt.utc().toISO();
    this.createdBy = createdBy || {
      email: "tom@tom.com",
      name: "Tom Tom",
      firstName: "Tom",
      lastName: "Tom",
    };
  }
}

export const EXAMPLE_RISK_LOG_ITEM_OPEN = new ExampleRiskLogItem({
  id: "0123456789",
  createTime: dt.utc().minus({ days: 5 }).toISO(),
  description: "Need to know if database will perform as needed",
  impact: RiskLogItemImpact.High,
  mitigationStrategy: "Run performance tests on database",
  noteList: [
    {
      id: "123",
      content: "Need to know ASAP to start build",
      createdAt: dt.utc().toISO(),
      createdBy: {
        id: generateMockId(),
        email: "tom@tom.com",
        name: "Tom Tom",
        firstName: "Tom",
        lastName: "Tom",
      },
      updatedAt: dt.utc().toISO(),
    },
  ],
  owner: RiskLogItemOwner.Mission,
  status: RiskLogItemStatus.Open,
  title: "Database performance",
  dateNeeded: dt.utc().minus({ days: 0 }).toISO(),
});

export const EXAMPLE_RISK_LOG_ITEM_RESOLVED = new ExampleRiskLogItem({
  createTime: dt.utc().minus({ days: 2 }).toISO(),
  description: "Decision on ECS for PROD",
  itemType: RiskLogItemType.Technical,
  mitigationStrategy: "Schedule meeting to make decision",
  probability: RiskLogItemProbability.Low,
  status: RiskLogItemStatus.Resolved,
  title: "Tech Decision",
});

export const EXAMPLE_RISK_LOG_ITEM_RESOLVED_OLD = new ExampleRiskLogItem({
  createTime: dt.utc().minus({ weeks: 6 }).toISO(),
  description: "We need approval before moving forward",
  itemType: RiskLogItemType.Schedule,
  mitigationStrategy: "Create slide deck to present",
  probability: RiskLogItemProbability.High,
  status: RiskLogItemStatus.Resolved,
  title: "Get buy in from CTO",
});

export const EXAMPLE_RISK_LOG_ITEM_ARCHIVED = new ExampleRiskLogItem({
  description: "It's too expensive to implement for each DEV instance",
  mitigationStrategy: "Research less expensive options",
  status: RiskLogItemStatus.Archived,
  title: "Cost of cluster for each environment",
});

export const EXAMPLE_ENGAGEMENTS_RISK_LOG_ITEMS = [
  EXAMPLE_RISK_LOG_ITEM_OPEN,
  EXAMPLE_RISK_LOG_ITEM_RESOLVED,
  EXAMPLE_RISK_LOG_ITEM_RESOLVED_OLD,
  EXAMPLE_RISK_LOG_ITEM_ARCHIVED,
];
