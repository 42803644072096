import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { Link } from "@m/ui";

import { PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";

export const DevOpsDeskSupportBanner = ({
  className,
}: {
  className?: string;
}) => {
  const navigate = useNavigate();

  const handleSupportLinkClick = () => {
    navigate(PATHS.SUPPORT_CASES, { replace: true });
  };

  return (
    <div
      className={clsx(
        "flex flex-col gap-1 rounded-md bg-gray-50 p-3",
        className
      )}
    >
      <span className="font-semibold">Just need standard support?</span>
      <p className="text-subdued">
        Get help when you need it with around-the-clock cloud operations support
        where you can get assistance fast.
      </p>
      <Link
        className="inline-flex"
        rightIcon={ArrowRightIcon}
        onClick={handleSupportLinkClick}
      >
        Go To Support
      </Link>
    </div>
  );
};
