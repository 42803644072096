import { useMemo } from "react";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";
import { dt } from "@m/utils";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS } from "@mc/constants";
import {
  ACTIVITY_FEED_MESSAGES,
  ACTIVITY_STREAMS,
} from "@mc/features/ActivityFeed/constants";
import { useQuery } from "@mc/utils/graphql";

export const GET_DASHBOARD_ACTIVITY_STREAM = gql(`
  query DashboardActivityStream(
    $where: ActivityStreamFilter
    $first: Int
    $after: String
  ) {
    activityStream(where: $where, first: $first, after: $after) {
      edges {
        node {
          __typename
          ... on AwsAccountResourcesAvailableActivityStream {
            id
            timestamp
            operation
            awsAccount {
              id
              name
            }
          }
          ... on CompanyMembershipActivityStream {
            id
            timestamp
            operation
            changes
            actor {
              id
              name
            }
            account {
              id
              name
              email
            }
          }
          ... on CompanyTeamActivityStream {
            id
            timestamp
            operation
            account {
              id
              name
            }
            actor {
              id
              name
            }
            roleName
            roleDescription
          }
          ... on ScorecardRecommendationActivityStream {
            id
            timestamp
            operation
            changes
          }
          ... on UserInvitationActivityStream {
            id
            timestamp
            actor {
              id
              name
            }
            account {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`);

export const useDashboardActivityStream = () => {
  const onError = () =>
    toast.error(ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_FAILURE);

  const cutoff = useMemo(() => dt.utc().minus({ weeks: 2 }).toISO(), []);

  const streams: any[] = [
    ACTIVITY_STREAMS.AwsAccountResourcesAvailableActivityStream,
    ACTIVITY_STREAMS.CompanyMembershipActivityStream,
    ACTIVITY_STREAMS.CompanyTeamActivityStream,
    ACTIVITY_STREAMS.UserInvitationActivityStream,
  ];

  if (useAccessRequired({ feature: FEATURE_FLAGS.SCORECARD_RECOMMENDATIONS }))
    streams.push(ACTIVITY_STREAMS.ScorecardRecommendationActivityStream);

  const streamTypes = streams.map((stream) => stream.type);

  const { data, loading, error } = useQuery(GET_DASHBOARD_ACTIVITY_STREAM, {
    variables: {
      where: {
        timestampGt: cutoff,
        typeIn: streamTypes,
      },
    },
    onError,
  });

  const activities =
    data?.activityStream?.edges?.map((edge) => edge.node) || [];

  return {
    data: { activities },
    loading,
    error: error !== undefined,
  };
};
