import { IEngagementsChecklistItem } from "../types";

import { ENGAGEMENTS_OWNER_OPTIONS } from "./detailItem.constants";

export const TABLE_HEADERS_ENGAGEMENT_CHECKLIST = [
  { accessor: "open", width: 24, cellClasses: "px-1" },
  { accessor: "title", cellClasses: "px-1" },
  { accessor: "dueDate", width: 140, cellClasses: "text-right" },
  { accessor: "owner", width: 24 },
  { accessor: "details", width: 10 },
];

export const DATE_FORMAT_ENGAGEMENT_CHECKLIST = "LLL d, yyyy";

export const MESSAGES_ENGAGEMENTS_CHECKLIST = {
  EMPTY_CHECKLIST: "Action Items",
  GET_ITEMS_ERROR: "Unable to access engagement Action Items data",
  UPDATE_SUCCESS: "Successfully updated Action Items",
  UPDATE_ERROR: "Unable to update Action Items",
};

export const DEFAULT_SORT_ENGAGEMENTS_CHECKLIST = "DUE_DATE_ASC";

export const DEFAULT_INITIAL_ENGAGEMENTS_CHECKLIST_ITEM: Partial<IEngagementsChecklistItem> =
  {
    owner: ENGAGEMENTS_OWNER_OPTIONS.MISSION,
    open: true,
  };
