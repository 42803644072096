import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const CREATE_MARKETPLACE_ONBOARDING_REQUEST = gql(/* GraphQL */ `
  mutation CreateMarketplaceOnboardingRequest(
    $input: CreateMarketplaceOnboardingRequestInput!
  ) {
    createMarketplaceOnboardingRequest(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateMarketplaceOnboardingRequest = (
  awsAccountId: string,
  requestedIamRoles: string[],
  awsAccountName?: string
) => {
  const [createRequest, { data, error, ...result }] = useMutation(
    CREATE_MARKETPLACE_ONBOARDING_REQUEST,
    {
      variables: {
        input: {
          awsAccountId,
          awsAccountName,
          requestedIamRoles,
        },
      },
      refetchQueries: ["GetMarketplaceRegistrations"],
      onCompleted: () =>
        toast.success(`Successfully onboarded AWS account ${awsAccountId}`),
      onError: () => {
        // handle error silently
      },
    }
  );

  const requestCreated = data?.createMarketplaceOnboardingRequest?.ok || false;

  const accountExists = error?.graphQLErrors?.[0].message.includes(
    "Account already exists."
  );

  return [
    createRequest,
    { data: requestCreated, error, accountExists, ...result },
  ] as const;
};
