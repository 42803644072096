import { useMemo } from "react";

import { Badge } from "@m/ui";
import { toHyphenLowercase } from "@m/utils";

import { EngagementActivityStreamNode } from "@mc/features/EngagementsActivityFeed/types";

import {
  ENGAGEMENT_ACTIVITY_FEED_MAP,
  type EngagementActivityFeedGroupConfig,
} from "../constants";
import {
  filterActivitiesByGroup,
  sortByTimestamp,
} from "../utils/activityFeed";

interface Props {
  title: string;
  group: EngagementActivityFeedGroupConfig;
  activities?: EngagementActivityStreamNode[];
}

export const EngagementActivityFeedGroup = ({
  activities,
  title,
  group,
}: Props) => {
  const activityFeedItems = useMemo<Array<JSX.Element>>(() => {
    const filteredActivities = filterActivitiesByGroup(activities, group);
    const orderedByTimestamp = sortByTimestamp(filteredActivities);

    return orderedByTimestamp.map((item: EngagementActivityStreamNode) => {
      const ActivityStreamItem =
        ENGAGEMENT_ACTIVITY_FEED_MAP[item.__typename]?.component;

      return (
        <ActivityStreamItem
          id={item.id}
          before={item.before}
          after={item.after}
          timestamp={item.timestamp}
          key={item.id}
        />
      );
    });
  }, [activities, group]);

  const id = `activities-from-${toHyphenLowercase(title)}`;
  const groupHasActivityItems = activityFeedItems.length > 0;

  return groupHasActivityItems ? (
    <div className="mt-4 flex flex-col space-y-1">
      <div className="flex items-center space-x-2">
        <Badge
          label={title}
          status="default"
          className="font-semibold text-gray-600"
        />
      </div>
      <div aria-labelledby={id} className="flex flex-col space-y-1">
        {activityFeedItems}
      </div>
    </div>
  ) : null;
};
