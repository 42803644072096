import clsx from "clsx";
import PT from "prop-types";
import { useContext, useMemo } from "react";

import { dt, getMilliseconds } from "@m/utils";

import { HorizontalLine } from "@mc/components/HorizontalLine";
import { SeriesPropTypes } from "@mc/components/LineGraph";

import {
  SCORECARD_DETAILS,
  SCORECARD_PILLARS,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { ScorecardTrendContext } from "../contexts";
import { getDateRangeLabel } from "../utils";

const OverallSummaryContent = ({ allTrendLines, isoDate }) => {
  const pillarScores = useMemo(() => {
    return Object.values(SCORECARD_PILLARS).map((pillar) => {
      const serie = allTrendLines.find((serie) => serie.id === pillar.name);
      const { y: score } = serie.data.find(
        ({ x }) => getMilliseconds(x) === getMilliseconds(isoDate)
      );

      return (
        <div
          key={serie.id}
          className="flex justify-between text-xs font-semibold opacity-80"
        >
          <div className="pr-3">{serie.id}:</div>
          <div>{score}</div>
        </div>
      );
    });
  }, [allTrendLines, isoDate]);

  return (
    <>
      <div className="-mx-1 pb-0.5">
        <HorizontalLine className="bg-white opacity-80" />
      </div>
      <div className="flex flex-col space-y-0.5">{pillarScores}</div>
    </>
  );
};

/**
 * Custom slice tooltip to be used with @nivo/line graphs
 */
export const ScorecardTrendTooltip = ({ allTrendLines, points }) => {
  const { selectedRange } = useContext(ScorecardTrendContext);
  const timeRange = SCORECARD_TREND_TIME_RANGES[selectedRange];
  const interval = timeRange?.interval;

  const { serieId, data: point } = points[0];
  const isOverall = serieId === SCORECARD_DETAILS.all.name;

  const isoString = point.x.toISOString();
  const isoDate = dt.fromISO(isoString).toISODate();

  const label = getDateRangeLabel(interval, isoDate);
  const score = point.yFormatted;

  return (
    <div className="z-20 mx-4 flex flex-col space-y-0.5 rounded bg-[#0C1D28] p-1 font-bold text-inverse antialiased">
      <div
        className={clsx("flex justify-between text-xs", {
          "pb-0.5": isOverall,
        })}
      >
        <div className="pr-3">{label}:</div>
        <div>{score}</div>
      </div>

      {isOverall && (
        <OverallSummaryContent
          allTrendLines={allTrendLines}
          isoDate={isoDate}
        />
      )}
    </div>
  );
};

ScorecardTrendTooltip.propTypes = {
  allTrendLines: PT.arrayOf(SeriesPropTypes),
  points: PT.arrayOf(
    PT.shape({
      serieId: PT.string.isRequired,
      data: PT.shape({
        x: PT.instanceOf(Date).isRequired,
        yFormatted: PT.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
