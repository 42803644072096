import { SKUS } from "@mc/constants";

import {
  CloudEngagementsOnboardingWelcomeDetails,
  CloudGatewayOnboardingWelcomeDetails,
  CloudScoreOnboardingWelcomeDetails,
} from "../components";
import { WelcomePageArtboard } from "../icons";
import { useMarketplaceRegistrations } from "../providers";

export const OnboardingWelcomePage = () => {
  const { pendingOnboardings } = useMarketplaceRegistrations();
  const sku = pendingOnboardings?.[0].sku;

  return (
    <div
      className="flex flex-col items-center justify-center gap-4"
      data-testid="onboarding-cloud-score-welcome-page"
    >
      <WelcomePageArtboard />

      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-center text-4xl font-bold text-default">
          Say hello to Mission Control
        </div>
        <div className="max-w-sm text-center text-xl font-normal text-subdued">
          Thank you for signing up!
        </div>
      </div>
      {sku === SKUS.CLOUD_SCORE && <CloudScoreOnboardingWelcomeDetails />}
      {sku === SKUS.CLOUD_GATEWAY && <CloudGatewayOnboardingWelcomeDetails />}
      {sku === SKUS.CLOUD_ENGAGEMENTS && (
        <CloudEngagementsOnboardingWelcomeDetails />
      )}
    </div>
  );
};
