import { useAuth } from "@m/login";
import { Modal } from "@m/ui";

import { PATHS } from "@mc/constants";

import { useSession } from "../api";

export const CompanySessionDialog = () => {
  const { user } = useAuth();
  const { currentSession } = useSession();

  const reloadWindow = () => (window.location = PATHS.DASHBOARD);

  return (
    <>
      <Modal open={currentSession?.value !== user.company.id}>
        {currentSession ? (
          <>
            <p className="pb-1 font-semibold">Your session has changed.</p>
            <p className="text-left">
              You are no longer viewing <strong>{user.company.name}</strong>
            </p>
            <p className="pb-1 text-left">
              Your new session is viewing{" "}
              <strong>{currentSession?.label}</strong>
            </p>
            <button
              onClick={reloadWindow}
              className="text-sm text-default underline hover:text-blue-400"
            >
              Refresh
            </button>
          </>
        ) : (
          <>
            <p className="pb-1 font-semibold">Your session has ended.</p>
            <button
              onClick={reloadWindow}
              className="text-sm text-default underline hover:text-blue-400"
            >
              Refresh
            </button>
          </>
        )}
      </Modal>
    </>
  );
};
