import { RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { StaffOnly } from "@mc/components/StaffOnly";
import { FEATURE_FLAGS } from "@mc/constants";
import { CloudScoreIneligiblePage } from "@mc/features/CloudScore";
import { DefaultLayout } from "@mc/layouts";
import { AccessDenied, NotFound } from "@mc/pages";

import { accountsRoutes } from "./accountsRoutes";
import { cloudScoreRoutes } from "./cloudScoreRoutes";
import { dashboardRoutes } from "./dashboardRoutes";
import { engagementsRoutes } from "./engagementsRoutes";
import { CloudScoreOutlet } from "./outlets";
import { DefaultPathRedirect } from "./redirects";
import { resourcesRoutes } from "./resourcesRoutes";
import { scorecardRoutes } from "./scorecardRoutes";
import { settingsRoutes } from "./settingsRoutes";
import { staffRoutes } from "./staffRoutes";
import { supportRoutes } from "./supportRoutes";

const { CASES, DASHBOARD, RESOURCES, SCORECARDS } = FEATURE_FLAGS;

export const defaultRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <DefaultPathRedirect />,
      },
      { path: "accounts", children: accountsRoutes },
      {
        path: "cloud-score",
        element: (
          <AccessRequired
            feature={SCORECARDS}
            fallback={<CloudScoreIneligiblePage />}
          >
            <CloudScoreOutlet />
          </AccessRequired>
        ),
        children: cloudScoreRoutes,
      },
      {
        path: "dashboard",
        element: <AccessRequired feature={DASHBOARD} fallback={<NotFound />} />,
        children: dashboardRoutes,
      },
      {
        path: "engagements",
        children: engagementsRoutes,
      },
      {
        path: "resources",
        element: <AccessRequired feature={RESOURCES} fallback={<NotFound />} />,
        children: resourcesRoutes,
      },
      { path: "scorecard", children: scorecardRoutes },
      { path: "settings", children: settingsRoutes },
      {
        path: "staff",
        element: (
          <StaffOnly fallback={<NotFound />}>
            <AccessRequired
              feature={FEATURE_FLAGS.MISSION_CONTROL_STAFF_LOUNGE}
              fallback={<AccessDenied />}
            />
          </StaffOnly>
        ),
        children: staffRoutes,
      },
      {
        path: "support",
        element: <AccessRequired feature={CASES} fallback={<NotFound />} />,
        children: supportRoutes,
      },
      { path: "*", element: <NotFound /> },
    ],
  },
];
