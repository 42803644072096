import { useAuth } from "@m/login";
import { Spinner } from "@m/ui";

import { Page } from "@mc/components/Page";
import { MissionAdminPageTitle } from "@mc/features/MissionAdmin";

import {
  useCustomerRepositories,
  useGrantAccess,
  useMissionEmployees,
  useRevokeAccess,
} from "../api";
import {
  CustomerRepositoriesTable,
  GitReposTabs,
  GrantAccessForm,
} from "../components";

export function GitRepoAccessPage() {
  const { user } = useAuth();

  const {
    data: { missionEmployees },
    loading: missionEmployeesLoading,
  } = useMissionEmployees();
  const {
    data: { customerRepositories },
    loading: customerRepositoriesLoading,
  } = useCustomerRepositories(user.company.id);

  const [grantAccess, { loading: grantAccessLoading }] = useGrantAccess();
  const [revokeAccess, { loading: revokeAccessLoading }] = useRevokeAccess();

  const disabled =
    grantAccessLoading ||
    revokeAccessLoading ||
    missionEmployees.length === 0 ||
    customerRepositories.length === 0;

  return (
    <Page data-testid="git-repo-access-page" title={<MissionAdminPageTitle />}>
      <GitReposTabs />
      <div className="mb-4 mt-2">
        <GrantAccessForm
          employees={missionEmployees}
          repositories={customerRepositories}
          grantAccess={grantAccess}
          grantAccessLoading={grantAccessLoading}
          disabled={disabled}
        />
      </div>

      {(missionEmployeesLoading || customerRepositoriesLoading) && (
        <div className="flex justify-center">
          <Spinner className="m-4" />
        </div>
      )}

      {!customerRepositoriesLoading && (
        <CustomerRepositoriesTable
          repositories={customerRepositories}
          revokeAccess={revokeAccess}
          revokeAccessLoading={revokeAccessLoading}
        />
      )}
    </Page>
  );
}
