import { Outlet } from "react-router-dom";

import { usePendingCloudGatewayOnboarding } from "@mc/features/Onboarding/hooks";

import { CloudGatewayOnboardingPathRedirect } from "../redirects";

/**
 * Renders child routes, if the AWS payer acccount has not yet been
 * successfully identifed for the currently pending Cloud Gateway onboarding,
 * otherwise redirect to the current onboarding path.
 */
export const IdentifyAwsPayerOutlet = () => {
  const pendingCloudGatewayOnboarding = usePendingCloudGatewayOnboarding();

  const payerIdentifed = pendingCloudGatewayOnboarding?.payerAccountId;

  if (payerIdentifed) return <CloudGatewayOnboardingPathRedirect />;
  return <Outlet />;
};
