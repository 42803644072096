import { gql, useQuery } from "@m/api";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { ROLES } from "@mc/constants";

import { ENGAGEMENT_STATUS } from "../constants";
import { IEngagement } from "../types";

export const GET_ENGAGEMENTS_LIST = gql`
  query Engagements($where: EngagementFilter) {
    engagements(where: $where) {
      edges {
        node {
          id
          sourceId
          title
          visible
          engagementType
          updateTime
          budgetStatus {
            status
            budgetSpent
            remainingBudget
            totalBudget
          }
          workStatus {
            status
            startDate
            dueDate
            completedTime
            percentageComplete
            updateTime
          }
          source {
            status
            title
          }
        }
      }
    }
  }
`;

interface IEngagementsResponse {
  engagements: {
    edges: {
      node: IEngagement;
    }[];
  };
}

const { IN_PROGRESS, COMPLETED } = ENGAGEMENT_STATUS;

export const useEngagementsList = () => {
  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });

  const where = {} as { visible: boolean };

  if (!isEngagementManager) where.visible = true;

  const { data, loading, error } = useQuery<IEngagementsResponse>(
    GET_ENGAGEMENTS_LIST,
    {
      variables: {
        where,
      },
    }
  );

  const engagements = data?.engagements?.edges?.map((edge) => edge.node) || [];

  // todo: remove this filter when BE has support for filtering on status
  const filteredEngagements = engagements.filter(
    (engagement) =>
      engagement.source.status === COMPLETED ||
      engagement.source.status === IN_PROGRESS
  );

  return { data: { engagements: filteredEngagements }, loading, error };
};
