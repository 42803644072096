import { PropsWithChildren, createContext } from "react";

import { GQL_DELAY, MAX_TIMEOUT } from "./constants";

export type FakeQuery = {
  name: string;
  data: any;
  delay: number;
  error: boolean;
};

export const FakeQueryContext = createContext<FakeQuery[]>([]);

interface Props extends PropsWithChildren {
  queries: FakeQuery[];
}

export const FakeQueryProvider = ({ children, queries }: Props) => (
  <FakeQueryContext.Provider value={queries}>
    {children}
  </FakeQueryContext.Provider>
);

export const fakeQuerySuccess = (name: string, data: any): FakeQuery => ({
  name,
  data,
  delay: GQL_DELAY,
  error: false,
});

export const fakeQueryLoading = (name: string): FakeQuery => ({
  name,
  data: undefined,
  delay: MAX_TIMEOUT,
  error: false,
});

export const fakeQueryError = (name: string): FakeQuery => ({
  name,
  data: undefined,
  delay: GQL_DELAY,
  error: true,
});
