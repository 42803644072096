export const CHECK_STATUS = {
  ERROR: "ERROR",
  IGNORED: "IGNORED",
  OK: "OK",
  WARNING: "WARNING",
};

export const CHECK_SOURCES = {
  AWS_TRUSTED_ADVISOR: {
    key: "AWS_TRUSTED_ADVISOR",
    name: "AWS Trusted Advisor",
  },
  AWS_SECURITY_HUB: {
    key: "AWS_SECURITY_HUB",
    name: "AWS Security Hub",
  },
  AWS_COMPUTE_OPTIMIZER: {
    key: "AWS_COMPUTE_OPTIMIZER",
    name: "AWS Compute Optimizer",
  },
};

export const CHECK_IGNORE_STATES = {
  IGNORE: "Ignore",
  MONITOR: "Monitor",
};
