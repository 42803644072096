import { Cog8ToothIcon } from "@heroicons/react/24/outline";

import { useAuth } from "@m/login";
import { Avatar, Dropdown, DropdownGroup, DropdownItem } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { StaffOnly } from "@mc/components/StaffOnly";
import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";

export const UserDropdown = () => {
  const { user, logout } = useAuth();

  return (
    <Dropdown
      trigger={
        <Avatar account={user} className="cursor-pointer hover:opacity-80" />
      }
      direction="bottom-end"
      style={{ minWidth: 150 }}
    >
      <DropdownGroup>
        <DropdownItem to={PATHS.SETTINGS}>Profile</DropdownItem>
        <DropdownItem to={PATHS.SETTINGS_USERS}>Users</DropdownItem>
        <DropdownItem to={PATHS.SETTINGS_NOTIFICATIONS}>
          Notifications
        </DropdownItem>
        <AccessRequired
          feature={FEATURE_FLAGS.CREDIT_CARD_PAYMENT_SUPPORT}
          mutation={MUTATIONS.UPDATE_PAYMENT_PROFILE}
        >
          <DropdownItem to={PATHS.SETTINGS_BILLING}>Billing</DropdownItem>
        </AccessRequired>
        <DropdownItem to={PATHS.SETTINGS_SUBSCRIPTIONS}>
          Subscriptions
        </DropdownItem>
      </DropdownGroup>

      <StaffOnly>
        <DropdownGroup>
          <DropdownItem to={PATHS.STAFF} className="flex gap-0.5">
            <Cog8ToothIcon className="h-2.5 w-2.5 text-yellow-500" />
            <span>Mission Admin</span>
          </DropdownItem>
        </DropdownGroup>
      </StaffOnly>

      <DropdownGroup>
        <DropdownItem onClick={logout}>Logout</DropdownItem>
      </DropdownGroup>
    </Dropdown>
  );
};
